import "./secciones.scss";
import UncontrolledLottie from "../components/LottieAnimation";
import { useHistory } from "react-router-dom";
// import { ImgInView } from "components/ImgInView";
import CursorAnimation from "../components/cursor/CursorAnimation";

const img = (history) => {
  return (
    <CursorAnimation
      text="EXPLORAR CAPITULO 06"
      src="images/selecciondDeCapitulos/06/cortadas/CAP06_1.jpg"
      history={history}
      to="/soluciones"
      width="50%"
      transform="translate(-5vw, 0vh)"
    />
  );
};

const ImgInView = ({ ...rest }) => {
  let history = useHistory();
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CAPITULO 06"
        history={history}
        to="/soluciones"
        {...rest}
      />
    </>
  );
};

const Seccion06 = () => {
  let history = useHistory();
  return (
    <div className="one-screen position-relative section">
      <div
        className="position-absolute top-50 start-0 pinned pin-6"
        style={{ transform: "translate(10vw, -20vh)" }}
      >
        <div className="section-title title-6">
          SOLUCIONES
          <br />
          ENERGÉTICAS Y AUTOGAS
        </div>
        <div className="section-title title-6 number">06</div>
      </div>
      <div className="position-absolute top-0 start-50">{img(history)}</div>
      <div
        className="position-absolute bottom-0 start-50"
        style={{ transform: "translate(25vw, 0vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/06/cortadas/CAP06_2.jpg"
          alt=""
          width="50%"
        />
      </div>
      <div
        className="position-absolute bottom-0 start-50"
        style={{ transform: "translate(-18vw, 0vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/06/cortadas/CAP06_3.jpg"
          alt=""
          width="50%"
        />
      </div>
      <div
        className="position-absolute bottom-50 start-50"
        style={{ transform: "translate(-21vw, 18vh)" }}
      >
        <UncontrolledLottie />
      </div>
    </div>
  );
};
export default Seccion06;
