/* eslint-disable jsx-a11y/anchor-has-content */
import "./operaciones.scss";
import { OperacionSlide } from "components/operaciones";
import { TerminalMaritimo } from "components/operaciones";
import { useState, useRef } from "react";
import CursorContextProvider from "components/cursor/CursorContextProvider";
import Cursor from "components/cursor/Cursor";
import VideoHover from "components/VideoHover";
import Final from "components/Final";
import { isMobile } from "react-device-detect";
import { MobileDownload, MobileNext } from "components/MobileEnd";

const Operaciones = (props) => (
  <CursorContextProvider>
    <Cursor />
    <Operaciones2 {...props} />
  </CursorContextProvider>
);

const Operaciones2 = ({ changeChap }) => {
  const video = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  const setPlaying = () => {
    let element = video.current;
    // video2?.current?.pause();
    if (element.paused) {
      video.current.scrollIntoView({ behavior: "smooth" });
      setIsPlaying(true);
      element.play();
    } else {
      setIsPlaying(false);
      element.pause();
    }
  };
  return (
    <>
      {!isMobile ? (
        <a
          href="https://abastible.socialweb.cl/2020/pdf/Cap_04_Abastible_2020.pdf"
          target="_blank"
          class="descargar-capitulo"
          rel="noreferrer"
        ></a>
      ) : (
        ""
      )}
      <OperacionSlide />
      <TerminalMaritimo />
      <div
        className={isPlaying ? "custom-video active" : "custom-video"}
        onClick={setPlaying}
      >
        {!isMobile ? (
          <VideoHover isPlaying={isPlaying}>
            <video
              style={{ marginTop: "4rem" }}
              ref={video}
              width="100%"
              poster="images/operaciones/bg2.jpg"
              src="https://abastible.socialweb.cl/2020/videos/abastible-nisyros.mp4"
              type="video/mp4"
            />
          </VideoHover>
        ) : (
          <video
            style={{ marginTop: "4rem" }}
            ref={video}
            width="100%"
            poster="images/operaciones/bg2.jpg"
            src="https://abastible.socialweb.cl/2020/videos/abastible-nisyros.mp4"
            type="video/mp4"
          />
        )}
      </div>

      {isMobile ? (
        <section>
          <MobileDownload href="https://abastible.socialweb.cl/2020/pdf/Cap_04_Abastible_2020.pdf" />
          <MobileNext href="/secciones#cap05" changeChap={changeChap} />
        </section>
      ) : (
        <Final
          className="btn-proximo-capitulo"
          changeChap={changeChap}
          to="/secciones#cap05"
        />
      )}
    </>
  );
};

export default Operaciones;
