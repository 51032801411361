import "./secciones.scss";
import { useHistory } from "react-router-dom";
import CursorAnimation from "../components/cursor/CursorAnimation";

const img = (history) => {
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CARTA"
        src="images/selecciondDeCapitulos/00/cortadas/CAP_CARTA.png"
        history={history}
        to="/carta"
        width="83%"
        transform="translate(-5vw, 10vh)"
      />
    </>
  );
};

const Seccion00 = () => {
  let history = useHistory();
  return (
    <div className="one-screen position-relative section">
      <div
        className="position-absolute top-50 start-0"
        style={{ transform: "translate(10vw, -15vh)" }}
      >
        <div>
          <div className="section-title">
            CARTA DEL
            <br />
            PRESIDENTE
          </div>
        </div>
      </div>

      <div className="position-absolute top-0 start-50">{img(history)}</div>
    </div>
  );
};

export default Seccion00;
