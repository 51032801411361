import "./home.scss";
import React from "react";
import UncontrolledLottie from "../components/LottieAnimation";
import FinalHome from "components/FinalHome";
import { isMobile } from "react-device-detect";

const positions = [
  {
    index: 0,
    pos: isMobile ? [0, 0] : [20, 0],
    scale: 0.5,
    size: [288, 644],
  },
  {
    index: 1,
    pos: isMobile ? [73, 0] : [66.5, 0],
    scale: 1,
    size: [144, 356],
  },
  {
    index: 2,
    pos: isMobile ? [0, 50] : [5, 42],
    scale: isMobile ? 0.5 : 0.6,
    size: [288, 356],
  },
  {
    index: 3,
    pos: [35, 48],
    scale: isMobile ? 0 : 0.6,
    size: [288, 356],
  },
  {
    index: 4,
    pos: isMobile ? [40, 40] : [54, 40],
    scale: isMobile ? 0.5 : 0.6,
    size: [712, 712],
  },
  {
    index: 5,
    pos: [83, 48],
    scale: isMobile ? 0 : 0.6,
    size: [288, 356],
  },
  {
    index: 6,
    pos: isMobile ? [0, 73] : [5, 67],
    scale: isMobile ? 0.5 : 0.6,
    size: [288, 356],
  },
  {
    index: 7,
    pos: isMobile ? [73, 85] : [66.5, 85],
    scale: 1,
    size: [144, 356],
  },
];

const Home = ({ changeChap }) => {
  return (
    <>
      <div
        className="one-screen position-relative section"
        style={{
          overflow: "hidden",
        }}
      >
        {positions.map((item, key) => {
          const exp = Math.max(1.5 * Math.exp(item.index - 6), 0) + 0.5;
          const time = (item.pos[1] + 10) * (1.5 / 100) + exp;

          return (
            <div
              className="move-img-0"
              style={{
                position: "absolute",
                left: `${item.pos[0]}vw`,
                top: `${item.pos[1] - 140}vh`,
                overflow: "hidden",
                width: `${item.size[0] * item.scale * 0.675}px`,
              }}
            >
              <img
                src={`../images/home/${item.index}.jpg`}
                alt={`/home/${item.index}.png`}
                style={{
                  width: `${item.size[0] * item.scale * 0.675}px`,
                  objectFit: "cover",
                }}
              />
              <div
                className="color-overlay"
                style={{
                  animationDelay: `0s, ${time}s`,
                  animationDuration: `${time}s, 1s`,
                }}
              ></div>
            </div>
          );
        })}

        {positions.map((item, key) => (
          <div
            className="move-img-1"
            style={{
              position: "absolute",
              left: `${item.pos[0]}vw`,
              top: `${item.pos[1]}vh`,
              overflow: "hidden",
              width: `${item.size[0] * item.scale}px`,
            }}
          >
            <img
              src={`../images/home/${item.index + 8}.jpg`}
              alt={`/home/${item.index}.png`}
              style={{
                width: `${item.size[0] * item.scale * 0.675}px`,
                objectFit: "cover",
              }}
            />
          </div>
        ))}
        {positions.map((item, key) => (
          <div
            className="move-img-2"
            style={{
              position: "absolute",
              left: `${item.pos[0]}vw`,
              top: `${item.pos[1]}vh`,
              overflow: "hidden",
              width: `${item.size[0] * item.scale}px`,
            }}
          >
            <img
              src={`../images/home/${item.index}.jpg`}
              alt={`/home/${item.index}.png`}
              style={{
                width: `${item.size[0] * item.scale * 0.675}px`,
                objectFit: "cover",
              }}
            />
          </div>
        ))}

        {!isMobile ? (
          <div
            className="position-absolute top-25 start-50"
            style={{ transform: "translate(-15vw, 18vh)" }}
          >
            <h2 className="text-orange section-title">
              <div>
                MEMORIA
                <br />
                ANUAL 2020
              </div>
            </h2>
          </div>
        ) : (
          <div
            className="position-absolute top-25 start-50"
            style={{ transform: "translate(-15vw, 20vh)" }}
          >
            <div className="title-mobile text-orange">
              <div>
                MEMORIA
                <br />
                ANUAL 2020
              </div>
            </div>
          </div>
        )}

        {!isMobile ? (
          <div
            className="lotties"
            style={{ transform: "translate(-5vw, 35vh)" }}
          >
            <UncontrolledLottie width="152px" height="152px" />
          </div>
        ) : (
          <div
            className="lotties"
            style={{ transform: "translate(5vw, 30vh)" }}
          >
            <UncontrolledLottie width="120px" height="120px" />
          </div>
        )}

        <div className="btn-bajar position-absolute  justify-content-center bottom-0 start-50">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => changeChap("/secciones")}
          >
            <img src="images/all/bajar.svg" alt="" />
          </div>
        </div>
      </div>
      <FinalHome changeChap={changeChap} />
    </>
  );
};

export default Home;
