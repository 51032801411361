import React, { useContext } from "react";
import CursorMousePosition from "./CursorMousePosition";
import { CursorContext } from "./CursorContextProvider";
import "./cursor.scss";

const Cursor = () => {
  const [cursor] = useContext(CursorContext);
  const position = CursorMousePosition();

  return cursor.show ? (
    <div
      className="cursor"
      style={{
        left: position.xMain,
        top: position.yMain,
      }}
    >
      <div className="cursor-text" exit={{ color: "#FFFFFF", opacity: 0 }}>
        {cursor.text}
      </div>
    </div>
  ) : (
    <div />
  );
};
export default Cursor;
