import Typist from "react-typist";
import useElementOnScreen from "hooks/UseElementOnScreen";

function Typing(props) {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  });

  const text = (
    <div>
      <Typist
        cursor={{ show: false }}
        avgTypingDelay={props.avgTypingDelay}
        stdTypingDelay={props.stdTypingDelay}
        startDelay={props.startDelay}
      >
        {props.children}
      </Typist>
    </div>
  );

  if (!isVisible) {
    return <div ref={containerRef} />;
  } else {
    return text;
  }
}

const Slide1 = ({ title, content, logo }) => {
  return (
    <div className="container-fluid bg-light-gray">
      <div className="row vh120 align-items-center justify-content-center top-0">
        <div className="col-11" style={{ minHeight: "70vh" }}>
          <div className="fs-5 text-uppercase text-orange fw-bold mb-4">
            <img src={logo} className="logo-slide" alt="" />

            <Typing avgTypingDelay={0} stdTypingDelay={0} startDelay={0}>
              {title}
            </Typing>
          </div>
          <p
            className="fs-2"
            style={{
              color: "#53575A",
              letterSpacing: "0.02rem",
            }}
          >
            <Typing avgTypingDelay={10} stdTypingDelay={0} startDelay={500}>
              {content}
            </Typing>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Slide1;
