/* eslint-disable jsx-a11y/anchor-has-content */
import "./gobierno.scss";
import { DIRECTORIO, TEXTOS } from "text/gobierno";
import { Slide1 } from "components/Slides";
import { Administracion2 } from "components/gobierno";
import Final from "components/Final";
import { isMobile } from "react-device-detect";
import { MobileDownload, MobileNext } from "components/MobileEnd";
import ImageGallery from "react-image-gallery";

const Space = ({ h }) => <div style={{ height: h }}></div>;

const WebDownload = () => (
  <a
    href="https://abastible.socialweb.cl/2020/pdf/Cap_01_Abastible_2020.pdf"
    target="_blank"
    className="descargar-capitulo"
    rel="noreferrer"
  ></a>
);

/** Callback function for rendering each image in the gallery */
const renderImage = (item) => {
  return (
    <>
      <div style={{ paddingBottom: "50px" }}>
        <img src={item.path} alt={item.alt} width="100%" />
        <div
          className="col align-items-center align-self-center"
          style={{ paddingTop: "20px" }}
        >
          <div className="fw-bold" style={{ height: "25px" }}>
            {item.position}
          </div>
          <div className="" style={{ height: "25px" }}>
            {item.name}
          </div>
        </div>
      </div>
    </>
  );
};
const Gobierno = ({ changeChap }) => {
  return isMobile ? (
    <GobiernoMobile changeChap={changeChap} />
  ) : (
    <GobiernoWeb changeChap={changeChap} />
  );
};

const GobiernoMobile = ({ changeChap }) => {
  const images = [];

  DIRECTORIO.forEach(({ name, url, position }) => {
    images.push({
      path: url,
      alt: name,
      name: name,
      position: position,
      bulletClass: "orange-bullet",
    });
  });

  return (
    <div className="gobierno text-orange">
      <Space h="75px" />

      <div className="title-sm-sep mb-3">Directorio</div>
      <div className="d-flex w-100 justify-content-center mb-5">
        <div className="w-75">
          <ImageGallery
            items={images}
            infinite={false}
            showNav={false}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            renderItem={renderImage}
          />
        </div>
      </div>

      <div className="screen-row flex-column center-all gradient-overlay-orange">
        <div className="d-flex w-75 justify-content-center mb-5">
          <img
            className="d-block"
            src="images/gobierno/GERENTE.jpg"
            alt=""
            width="100%"
            height="100%"
          />
        </div>
        <div className="fw-bold text-center text-uppercase">Administración</div>
        <div className="text-center">
          Gerente General
          <br />
          Joaquín Cruz
        </div>
      </div>

      <Administracion2 titleSepSize="sm" />

      <Slide1 title={TEXTOS[1].h} content={TEXTOS[1].p} />

      <Section5 />

      <div className="row-light center-all">
        <div className="col">
          <MobileDownload href="https://abastible.socialweb.cl/2020/pdf/Cap_00_Carta_del_Presidente.pdf" />

          <hr className="hline-orange-sm" />

          <MobileNext href="/secciones#cap01" changeChap={changeChap} />
        </div>
      </div>
    </div>
  );
};

const GobiernoWeb = ({ changeChap }) => {
  return (
    <>
      <WebDownload />
      <Directorio />
      <Administracion1 />
      <Administracion2 />
      <Slide1 title={TEXTOS[1].h} content={TEXTOS[1].p} />
      <Section5 />
      <Space h="50px" />
      <Final
        className="btn-proximo-capitulo"
        changeChap={changeChap}
        to="/secciones#cap02"
      />
    </>
  );
};

export default Gobierno;

const Administracion1 = () => (
  <>
    <section className="one-screen d-flex justify-content-center align-items-center adm text-orange">
      <div className="row  justify-content-center align-items-center">
        <div className="col-4 d-flex justify-content-center">
          <div>
            <h2 className="text-uppercase fw-bold">Administración</h2>
            <div>Gerente General</div>
            <div>Joaquín Cruz</div>
          </div>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <img src="images/gobierno/GERENTE.jpg" alt="" width="50%" />
        </div>
      </div>
    </section>
  </>
);

const Section5 = () => {
  return (
    <section className="row justify-content-around align-items-center one-screen bg-light-gray">
      <div className="col-11 col-md-5 d-flex justify-content-center">
        <div className="col-12 col-md-8">
          <h2
            className="text-orange fw-bold text-uppercase"
            style={{ letterSpacing: "0.15em", fontSize: "24px" }}
          >
            POLÍTICA DE DERECHOS HUMANOS
          </h2>
          <Space h="24px" />
          <p
            className="text-gray"
            style={{ fontSize: "16px", letterSpacing: "0.05em" }}
          >
            Se aprueba la Política de Derechos Humanos corporativa, que busca
            potenciar potencia la promoción de principios como la no
            discriminación, el trato humano, la prohibición del trabajo
            infantil, la libertad de asociación y negociación colectiva, además
            de la seguridad y salud.
          </p>
        </div>
      </div>
      <div className="col-12 col-md-7 text-left">
        <img
          src="images/gobierno/GC_FOTO_1.jpg"
          alt=""
          width="100%"
          className="p-0 m-0"
        />
      </div>
    </section>
  );
};

const Directorio = () => {
  return (
    <div className="container-fluid gobierno">
      <section
        className="one-screen row align-items-center justify-content-center"
        style={{ background: "#53575a", color: "#FF5100" }}
      >
        <Space h="70px" />
        <div id="directorio" className="col-12 fs-6">
          <h2
            className="text-uppercase fw-bold hh2"
            style={{ fontSize: "1.5rem" }}
          >
            Directorio
          </h2>
          <div className="row  content-fluid">
            <div className="">
              <div className="row justify-content-center">
                {DIRECTORIO.map((item) => (
                  <div className=" director col-3">
                    <DirectorioItem
                      img={item.url}
                      name={item.name}
                      position={item.position}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Space h="70px" />
      </section>
    </div>
  );
};

const DirectorioItem = ({ img, name, position }) => (
  <>
    <div
      className="director-img text-center"
      style={{ height: "190px", overflow: "hidden" }}
    >
      <img
        src={img}
        alt={name}
        height="auto"
        width="100%"
        style={{ overflow: "hidden" }}
      />
    </div>
    <div className="director-title text-center fw-bold ">{position}</div>
    <div className="director-name text-center ">{name}</div>
  </>
);
