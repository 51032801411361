/* eslint-disable jsx-a11y/anchor-has-content */
import "./sosten.scss";
import { useSpring } from "react-spring";
import { Slide1 } from "components/Slides";
import React from "react";
import {
  CrecimientoSostenible,
  AportandoValor,
  CompromisoAmbiente,
  CulturaEquipo,
  SeguridadPilar,
  CarpaComedor,
} from "components/sosten";
import gsap, { ScrollTrigger } from "gsap/all";
import Final from "components/Final";
import { isMobile } from "react-device-detect";
import { MobileDownload, MobileNext } from "components/MobileEnd";

gsap.registerPlugin(ScrollTrigger);

const Sosten = ({ changeChap }) => {
  useSpring(() => ({
    number: 0,
    liderazgo: "0px",
    lider_opacity: 0,
    transform: "translateX(-500px)",
  }));

  return (
    <div className="sosten">
      {!isMobile ? (
        <a
          href="https://abastible.socialweb.cl/2020/pdf/Cap_03_Abastible_2020.pdf"
          target="_blank"
          class="descargar-capitulo"
          rel="noreferrer"
        ></a>
      ) : (
        ""
      )}
      <CrecimientoSostenible />
      <AportandoValor />
      <CarpaComedor />

      <CompromisoAmbiente />

      <Slide1
        title="Impulso al consumo eficiente de energía"
        content="Abastible lanzó el servicio de Sistema de Gestión de Energía (SGE), con el fin de asesorar y acompañar a los clientes industriales en los cambios de mirada sobre el uso de energía para enfrentar la etapa de transición energética que vive el país."
        logo="images/sosten/SGE.png"
      />
      <CulturaEquipo />

      <SeguridadPilar />
      {isMobile ? (
        <section className="bg-light-gray">
          <MobileDownload href="https://abastible.socialweb.cl/2020/pdf/Cap_03_Abastible_2020.pdf" />
          <MobileNext href="/secciones#cap04" changeChap={changeChap} />
        </section>
      ) : (
        <Final
          className="btn-proximo-capitulo"
          changeChap={changeChap}
          to="/secciones#cap04"
        />
      )}
    </div>
  );
};

export default Sosten;
