import { PatagoniaFresh } from "components/soluciones";
import TypingSlide from "components/Slides/TypingSlide";

const Slides = ({ changeChap }) => {
    return (
        <>
            <TypingSlide color="#ff5100" background="#e3e3e2">
                <div className="d-desk">
                    <u>
                        Abastible se posiciona como un actor relevante en el
                        desarrollo de soluciones integrales
                    </u>
                    de los más diversos rubros.
                </div>
                <div className="d-mobile">
                    <u>
                        Abastible se posiciona como un actor relevante en el
                        desarrollo de soluciones integrales
                    </u>
                    de los más diversos rubros para generar un desempeño
                    energético más eficiente, más limpio, que optimice el
                    desarrollo operacional y la productividad de todo tipo de
                    emprendimiento.
                </div>
            </TypingSlide>
            <PatagoniaFresh />
        </>
    );
};

export default Slides;
