import "./secciones.scss";
import UncontrolledLottie from "../components/LottieAnimation";
import { useHistory } from "react-router-dom";
import CursorAnimation from "../components/cursor/CursorAnimation";
// import { ImgInView } from "../components/ImgInView";

const img = (history) => {
  return (
    <CursorAnimation
      text="EXPLORAR CAPITULO 03"
      src="images/selecciondDeCapitulos/03/cortadas/CAP03_2.jpg"
      history={history}
      to="/sostenibilidad"
      width="50%"
      transform="translate(10vw, 10vh)"
    />
  );
};

const ImgInView = ({ ...rest }) => {
  let history = useHistory();
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CAPITULO 03"
        history={history}
        to="/sostenibilidad"
        {...rest}
      />
    </>
  );
};

const Seccion03 = () => {
  let history = useHistory();
  return (
    <div className="one-screen position-relative section">
      <div
        className="position-absolute top-50 start-0 pinned pin-3"
        style={{ transform: "translate(10vw, -15vh)" }}
      >
        <div className="section-title title-3">SOSTENIBILIDAD</div>
        <div className="section-title title-3 number">03</div>
      </div>
      <div
        className="position-absolute top-0 start-50"
        style={{ transform: "translate(-5vw, 10vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/03/cortadas/CAP03_1.jpg"
          alt=""
          width="50%"
        />
      </div>
      <div className="position-absolute top-0 start-50">{img(history)}</div>
      <div
        className="position-absolute bottom-0 start-50"
        style={{ transform: "translate(-20vw, 0vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/03/cortadas/CAP03_3.jpg"
          alt=""
          width="50%"
        />
      </div>
      <div
        className="position-absolute bottom-50 start-50"
        style={{ transform: "translate(-20vw, 15vh)" }}
      >
        <UncontrolledLottie />
      </div>
    </div>
  );
};
export default Seccion03;
