import "./final.scss";

const Final = ({ className, changeChap = (t) => {}, to = "/", children }) => {
    return (
        <>
            <div className="final-container">
                <div onClick={() => changeChap(to)}>
                    <RightArrow />
                </div>
                <div className="text-prox">PRÓXIMO CAPÍTULO</div>
            </div>
        </>
    );
};

export default Final;

const RightArrow = () => (
    <svg
        className="final-image"
        width="902.25049"
        height="364.71875"
        viewBox="0 0 902.25049 364.71875"
        id="svg2868"
    >
        <defs id="defs2874" />
        <namedview
            id="base"
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1.0"
            pageopacity="0.0"
            pageshadow="2"
            window-width="640"
            window-height="541"
            zoom="0.34493828"
            cx="372.04722"
            cy="256.66814"
            window-x="75"
            window-y="152"
            current-layer="svg2033"
        ></namedview>
        <g transform="matrix(-1,0,0,-1,902.25049,364.71875)" id="Ebene_1">
            <polygon
                points="902.25049,222.98633 233.17773,222.98633 233.17773,364.71875 0,182.35938 233.17773,0 233.17773,141.73242 902.25049,141.73242 902.25049,222.98633 "
                id="path2050"
            />
        </g>
    </svg>
);
