import { useState, useEffect} from "react";

const CursorMousePosition = () => {
  const [position, setPosition] = useState({
    xMain: 0,
    yMain: 0,
  });


  const updatePosition = event => {
    const {clientX, clientY } = event;
    setTimeout(() => {
      setPosition({
        xMain:clientX,
        yMain:clientY,
      }); 
    },100);
  };

  useEffect(() => {
    document.addEventListener("mousemove", updatePosition, false);
    document.addEventListener("mouseenter", updatePosition, false);

    return () => {
      document.removeEventListener("mousemove", updatePosition);
      document.removeEventListener("mouseenter", updatePosition);
    };
  }, []);

  return position;
};

export default CursorMousePosition;