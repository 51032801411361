import TextInDown from "components/Utils/TextInDown";
import styles from "./styles.module.scss";
import { useState, useEffect, useRef } from "react";

const ComiteCrisis = ({ src = "images/liderazgo/comite-crisis.jpg" }) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");
  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
    });
    return () => {
      window.removeEventListener("scroll", scrollListen);
    };
  });
  return (
    <div
      ref={ref}
      className="slide-left"
      style={{
        background: `url(${src})`,
        backgroundAttachment: lax,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
      }}
    >
      <div className="side-content bg-hell short">
        <TextInDown>
          <div className={styles.box}>
            <h2>Comité de Crisis y su rol en pandemia</h2>
            <p>
              Entró en funcionamiento el Comité de Crisis con la misión de
              proveer un diagnóstico claro de la situación y adoptar los
              protocolos y medidas para resguardar a los equipos de trabajo.
            </p>
            <p>
              <b>Comité de Crisis:</b> Joaquín Cruz, Sebastián Montero, Paula
              Jervis, Alvaro Gallegos, Luis Donoso, Osvaldo Acuña.
            </p>
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

const MedidasSanitarias = ({
  src = "images/liderazgo/medidas-sanitarias.jpg",
}) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");
  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
      return () => {
        window.removeEventListener("scroll", scrollListen);
      };
    });
  });
  return (
    <div
      ref={ref}
      className="slide-left"
      style={{
        background: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: lax,
        backgroundPosition: "top",
      }}
    >
      <div className="side-content bg-left">
        <TextInDown>
          <div className={styles.box2}>
            <h2>Medidas sanitarias en toda la cadena de valor</h2>
            <p>
              En todos los ámbitos, Abastible se preocupó de ir más allá de las
              recomendaciones de la autoridad.
            </p>
            <p>
              El Programa de Mitigación y Seguridad Sanitaria, consideró charlas
              diarias en plantas y oficinas sobre la importancia del autocuidado
              y desarrollo de un manual y protocolos sanitarios.
            </p>
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

const ProgramaBeneficios = ({
  src = "images/liderazgo/programa-beneficios.jpg",
}) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");
  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
      return () => {
        window.removeEventListener("scroll", scrollListen);
      };
    });
  });
  return (
    <div
      ref={ref}
      className="slide-left"
      style={{
        background: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: lax,
        backgroundPosition: "top",
      }}
    >
      <div className="side-content bg-left">
        <TextInDown>
          <div className={styles.box2}>
            <h2>Programa de beneficios para clientes</h2>
            <p>
              Abastible presentó su Programa de Beneficios para clientes. Se
              entregaron descuentos en cargas de gas para quienes perdieron su
              trabajo. A las Pymes Granel se les permitió pagar sus deudas hasta
              en seis cuotas sin interés. Mientras que para los Clientes Medidor
              se les otorgó la posibilidad de postergar el pago de la cuenta en
              12 cuotas sin reajustes ni interés y no se han efectuado cortes de
              suministro por no pago mientras ha durado la pandemia.
            </p>
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

export { ComiteCrisis, MedidasSanitarias, ProgramaBeneficios };
