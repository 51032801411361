import { InView } from "react-intersection-observer";

const ImgInView = ({ threshold = 0.4, src, width, ...rest }) => (
  <InView threshold={threshold}>
    {({ inView, ref }) => (
      <div
        ref={ref}
        className="discover-img"
        {...rest}
        data-active={inView}
        style={{ width: width, zIndex: 2 }}
      >
        <img src={src} alt="" />
      </div>
    )}
  </InView>
);

export { ImgInView };
