const carta = {
  left: {
    h: "Estimadas y estimados accionistas,",
    p:
      "Presentamos a ustedes la Memoria Anual 2020 de Abastible. En este documento tratamos de sintetizar lo que fue para la Compañía el pasado ejercicio. Sin duda, se trató de un año tremendamente complejo, desafiante y, por cierto, doloroso.",
  },
  right: [
    "Lo vivido en 2020, en las más diversas dimensiones, fue inesperado y único para todos. Nos hizo despertar y reaccionar en la marcha hacia una nueva realidad, donde valores como la solidaridad, humanidad y amor por la vida, orientaron nuestro pensar y actuar. La pandemia impuso un escenario crítico a nivel mundial, reinando la incertidumbre en múltiples áreas, desde la científica, hasta la social y económica. Todos nos vimos fuertemente impactados. ",

    "Son tiempos, además, en que las certezas institucionales se han visto fuertemente cuestionadas, y en los cuales la ciudadanía exige rápida respuesta a sus legítimas y nuevas demandas de mayor bienestar. Nuestro país, en particular, transita hoy por hitos democráticos que serán clave en la definición del rumbo que tome por las próximas décadas. ",

    "Pero a pesar de la desafiante situación que vivimos como sociedad y, por supuesto, como organización, una certeza emergió con fuerza, y nos enorgullece profundamente. Se trata del tremendo compromiso de las personas que componen nuestros equipos en toda la Región. Es así como en Ecuador, Chile, Colombia y Perú mostraron un talante extraordinario para entender el escenario que afectaba al mundo, y asumir el compromiso con la sociedad de mantener el suministro de energía en todas las comunidades de los cuatro países, que lo requerían más que nunca para hacer frente a la emergencia sanitaria. Los colaboradores han mostrado lo mejor de nuestra cultura, con altas cuotas de resiliencia, profesionalismo, innovación, solidaridad y empatía. ",

    "Asimismo, cabe destacar el eslabón determinante de nuestra cadena de valor: los distribuidores y aliados, que como parte activa de sus comunidades son vitales para cumplir la misión de llevar más que energía a millones de hogares en la Región. ",

    "En este contexto, el trabajo en equipo, como uno de nuestros principios corporativos, ha cobrado más relevancia que nunca para enfrentar esta nueva realidad a la que las compañías deben adaptarse para seguir adelante. Esto significa liderazgos que impulsen el trabajo colaborativo al interior de la organización, y también con cada uno de los stakeholders externos. El trabajo de todos y cada uno es decisivo a la hora de entregar nuestros servicios a la sociedad. ",

    "La compañía tomó una serie de medidas inmediatas una vez declarada la pandemia, con la clara convicción de resguardar la seguridad y salud de nuestros colaboradores y mantener la continuidad operativa en momentos en que las comunidades necesitaban seguridad de acceso a los servicios esenciales. Así, se rediseñó el trabajo presencial en plantas y centros de distribución, y se incorporaron lineamientos y apoyo sanitario para el funcionamiento de la red de distribuidores, priorizando el cumplimiento de los más estrictos protocolos preventivos de seguridad para proteger la vida y salud de cada persona de la cadena de valor y también de los clientes. En paralelo, cerca de la mitad de nuestro equipo humano se incorporó al teletrabajo, experimentando un proceso individual de adaptación a las nuevas condiciones, con apoyo permanente de la Compañía en ámbitos de gestión de personas e implementación digital, para que las plataformas funcionaran con altos estándares de eficacia y seguridad. Al mismo tiempo, y entendiendo la magnitud de la emergencia, la Compañía dispuso de una cantidad importante de recursos para apoyar a nuestros equipos, distribuidores, clientes y también a los grupos más vulnerables de la sociedad. De cara a la pandemia, nuestro compromiso corporativo ha sido contribuir a que nadie se quede atrás, siendo un activo agente social para apoyar esta causa. De esta forma, se otorgó ayuda sanitaria, económica y sicológica para los colaboradores, y se apoyó a los clientes impactados por la crisis económica con descuentos y condiciones de pago más favorables. En el mismo espíritu, se aportó a grupos pertenecientes a los sectores más desvalidos del país, en alianzas con el Ministerio de Desarrollo Social y Familia y con la Fundación Techo, y también a instituciones y funcionarios de la salud, entregando los elementos necesarios para su adecuada calefacción. ",

    "Durante el año 2020 nuestros valores corporativos cobraron más que nunca realidad en el desempeño cotidiano, y el principio “la persona en el centro de nuestros procesos” estuvo siempre presente. De nuestra responsabilidad por hacer las cosas bien dependía la salud de otros. Y como organización también lo vivimos así, porque cada decisión tomada por la administración fue motivada por el bienestar de las personas, en el marco de una continuidad operacional segura.",

    "A pesar de los complejos meses vividos, el compromiso y profesionalismo de nuestros equipos internos y externos permitió alcanzar resultados satisfactorios durante el período a nivel regional. En términos generales, los segmentos residenciales mantuvieron o aumentaron su consumo, lo que permitió compensar las naturales caídas en los clientes comerciales e industriales, cuya actividad se vio afectada por la pandemia. En Chile, se mantuvo el liderazgo, con ventas físicas de 505 mil toneladas, que representan 1,1% más que el año anterior, y nos ubica con una participación de mercado de 38,5%. En Colombia, las ventas físicas fueron de 231 mil toneladas, que representan un aumento de 11,1% en relación con 2019; y un incremento en la participación de mercado al pasar del 33,2% al 34,7%. En Ecuador, se registró un total de ventas cercano a 480 mil toneladas, 2,0% superior a 2019, y una participación de mercado de 39,2%. En Perú, que fue el mercado más golpeado por la situación sanitaria, y que está afectado a la vez por un importante grado de informalidad, consolidamos el liderazgo de mercado con una participación de 26%, incluyendo la venta mayorista, a pesar de haber bajado un 7,7% en el volumen de los restantes canales, que alcanzaron 370.898 toneladas.",

    "Con todo ello, los resultados financieros mostraron una importante resiliencia. Tanto el Ebitda consolidado como la utilidad fueron superiores a los del 2019, alcanzando los MM$ 134.573 y MM$ 59.638, respectivamente, en el último caso con una influencia relevante de menores impuestos, producto del efecto del movimiento a la baja del tipo de cambio sobre el valor registrado para las sociedades extranjeras. ",

    "Más allá de lo financiero, nuestra hoja de ruta señala distintos objetivos para convertirnos en una empresa regional de clase mundial. Ante los tiempos desafiantes que nos ha tocado vivir, tenemos la convicción de contar con las herramientas necesarias para avanzar de manera sostenible, confiados en nuestra cultura, que nos distingue como una compañía líder en el sector energía, identificada con profundas capacidades de adaptación, innovación y también con visión de futuro. Ello nos permitirá mantener un papel relevante en los nuevos escenarios de negocios que en este momento se están bosquejando como consecuencia de los cambios que vivimos en los ámbitos sociales, económicos, ambientales, tecnológicos y demográficos.",

    "Por esta razón, y en sintonía con los objetivos y metas que buscan desarrollar la eficiencia energética y el uso racional de los recursos en los distintos procesos productivos, potenciamos las Soluciones Energéticas a nivel regional, creando un portafolio de servicios robusto, basado en respuestas innovadoras, que ayudan a clientes industriales a comprender su desempeño energético, e iniciar un trabajo colaborativo para alinear sus procesos bajo los nuevos estándares energéticos consensuados por la sociedad.",

    "En esta misma línea, innovación para la transición energética, destacamos nuestra Consultoría Ambiental, que le otorga a las industrias una serie de servicios para adaptarse a las nacientes exigencias y escenarios normativos. En este sentido, ya podemos ofrecer, por ejemplo, alternativas de medición de emisiones con tecnología blockchain.",

    "Y también en el ámbito de la incorporación de soluciones tecnológicas, el desarrollo de distintas herramientas digitales permitió superar las restricciones impuestas naturalmente por la pandemia. Se implementó la nueva sucursal virtual, la firma electrónica y la emisión de guías de despacho electrónicas, medios de pagos electrónicos, telemetría para la gestión de suministros a distancia, y análisis de datos para servicios altamente personalizados, entre otras medidas que buscaron facilitar los trámites para nuestros colaboradores, distribuidores y clientes. En materia de prevención, y poniendo la tecnología al servicio de la salud, se desarrolló el ID Naranja para monitorear en línea la información sanitaria de nuestras operaciones, garantizando la seguridad de todas las personas y procesos. La transformación digital va así marcando nuestro rumbo.",

    "La continuidad de nuestras operaciones, con los más altos estándares de calidad, nos motiva también a seguir con la planificación y avances en abastecimiento y logística. Estamos orgullosos porque concretamos la primera importación propia de gas licuado, a través del Terminal San Vicente, ubicado en la Región del Biobío, lo que nos deja en una excelente posición para seguir expandiendo y haciendo crecer nuestra red, tanto en Chile como en la Región. En forma paralela, seguimos avanzando en el proceso de venta de las participaciones accionarias que Abastible tiene en las sociedades Gasmar y Sonacol. En ambos casos se trata de activos de infraestructura, con un claro perfil de flujos estables y de largo plazo, que los hacen muy atractivos para inversionistas especializados. Así, la concreción de estas desinversiones podría permitirnos visibilizar el valor de estos activos, manteniendo al mismo tiempo una estructura contractual, mediante robustos acuerdos de off take, que le den a nuestra Compañía amplias certezas y opciones en su abastecimiento futuro.",

    "Todas estas decisiones y capacidades responden a la prudencia y la planificación cuidadosa, y son también el resultado de nuestra cultura, anclada en valores y principios corporativos que nos guían y que entregan la base de nuestra institucionalidad. Esta, a su vez se refleja en nuestro gobierno corporativo, en constante evolución para un mejoramiento continuo.",

    "En lo que a ello respecta, nos enorgullece decir que durante este período continuamos impulsando acciones destinadas al resguardo de la integridad y la ética de los negocios, al crear el área de Compliance para la prevención de situaciones de riesgo, junto con la promoción de las buenas prácticas en los negocios a nivel organizacional. También aprobamos la Política de Derechos Humanos corporativa, reconociendo el rol que poseen las empresas en el respeto y promoción de los derechos humanos al interior de las organizaciones, como, asimismo, el valor de su efectiva protección como un elemento que afecta positivamente a todos quienes las conforman.",

    "Durante el 2020 fuimos testigos de cambios de paradigmas en múltiples áreas del quehacer humano, como los procesos productivos y su relación con el medioambiente, las comunidades locales y globales, el desarrollo tecnológico y la evolución digital. La pandemia precipitó estos cambios y a la vez mostró con nitidez las nuevas oportunidades para una sana y transparente gestión de negocios. ",

    "Los cambios seguirán suscitándose también en lo político, donde Chile enfrentará, dentro de poco, instancias cruciales. Esperamos que nuestro país sepa recoger aquellas numerosas buenas políticas que le han permitido, en las últimas décadas, ser un ejemplo mundial de desarrollo y aumento en el bienestar de su población. Pero también debe enmendar el rumbo en las dimensiones donde se ha fallado, haciendo los esfuerzos necesarios para brindar una mayor seguridad y calidad de vida a una parte importante de chilenos que han sido menos favorecidos. Como compañía, queremos cooperar en el proceso para ir dando forma a un país del cual todos nos sintamos cada vez más orgullosos.",

    "Se va configurando así un mundo nuevo. Uno del que ya somos parte en una gran compañía regional, que tiene capacidad para avanzar con mayor fuerza y rapidez, con resiliencia y flexibilidad, para superar realidades a veces adversas, y estar siempre preparados para los desafíos y oportunidades que las épocas nos ofrecen. Siempre orgullosos de nuestra cultura, forjada en el compromiso de las personas por las personas, que ahora más que nunca están en el centro de nuestros procesos, y por cuyo bienestar trabajamos hoy, y lo seguiremos haciendo en el futuro.",
  ],
};

export default carta;
