import "./secciones.scss";
import UncontrolledLottie from "../components/LottieAnimation";
import { useHistory } from "react-router-dom";
// import { ImgInView } from "components/ImgInView";
import CursorAnimation from "../components/cursor/CursorAnimation";

const img = (history) => {
  return (
    <CursorAnimation
      text="EXPLORAR CAPITULO 04"
      src="images/selecciondDeCapitulos/04/cortadas/CAP04_1.jpg"
      history={history}
      to="/operaciones"
      width="50%"
      transform="translate(-4vw, 0vh)"
    />
  );
};

const ImgInView = ({ ...rest }) => {
  let history = useHistory();
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CAPITULO 04"
        history={history}
        to="/operaciones"
        {...rest}
      />
    </>
  );
};

const Seccion04 = () => {
  let history = useHistory();
  return (
    <div className="one-screen position-relative section">
      <div
        className="position-absolute top-50 start-0"
        style={{ transform: "translate(10vw, -8vh)" }}
      >
        <div className="section-title">
          OPERACIONES
          <br />Y ABASTECIMIENTO
        </div>
        <div className="section-title title-6 number">04</div>
      </div>

      <div className="position-absolute top-0 start-50">{img(history)}</div>
      <div
        className="position-absolute bottom-50 start-50"
        style={{ transform: "translate(-18vw, 30vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/04/cortadas/CAP04_2.jpg"
          alt=""
          width="50%"
        />
      </div>
      <div
        className="position-absolute bottom-0 start-50"
        style={{ transform: "translate(20vw, 0vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/04/cortadas/CAP04_3.jpg"
          alt=""
          width="50%"
        />
        <UncontrolledLottie />
      </div>
    </div>
  );
};
export default Seccion04;
