import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.scss";
import { isMobile } from "react-device-detect";

//pages
import Home from "pages/Home";
import Gobierno from "pages/Gobierno";
import Nav from "components/nav/Nav";
import Liderazgo from "pages/Liderazgo";
import Carta from "pages/Carta";
import Sosten from "pages/Sosten";
import Operaciones from "pages/Operaciones";
import Transformacion from "pages/Transformacion";
import Soluciones from "pages/Soluciones";
import Slides from "pages/Slides";
import Secciones from "pages/Secciones";
import Desempeño from "pages/Desempeño";

const App = () => {
  const location = useLocation();
  const [chap, setChap] = useState("");
  const [animate, set] = useState(0);
  const classes = ["", "animate-start", "animate-end"];
  useHistory();

  const [yScroll, setYScroll] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      let winScroll = Math.round(
        document.body.scrollTop || document.documentElement.scrollTop
      );
      let hh =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      let percent = Math.ceil((100 * winScroll) / hh);
      // console.log(percent);
      setYScroll(location.pathname !== "" ? percent : 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const aend = () => {
    if (animate === 0) {
      set(1);
    } else if (animate === 1) {
      window.location.href = chap;
      set(2);
    } else if (animate === 2) {
      set(0);
    }
  };

  const changeChap = (newChap) => {
    setChap(newChap);
    aend();
  };

  return !isMobile ? (
    <div className="App">
      <div
        className="position-fixed left-0 bottom-0"
        style={{
          height: "8px",
          background: "#ff5100",
          zIndex: 100,
          width: `${yScroll}%`,
          borderTop: "2px solid #FF7055",
          borderRight: "2px solid #FF7055",
        }}
      ></div>
      <Nav changeChap={changeChap} />
      <div className={classes[animate]} onAnimationEnd={aend}></div>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/slides" component={Slides} />
          <Route path="/desempeño">
            <Desempeño changeChap={changeChap} />
          </Route>
          <Route path="/carta">
            <Carta changeChap={changeChap} />
          </Route>
          <Route path="/gobierno">
            <Gobierno changeChap={changeChap} />
          </Route>
          <Route path="/liderazgo">
            <Liderazgo changeChap={changeChap} />
          </Route>
          <Route path="/sostenibilidad">
            <Sosten changeChap={changeChap} />
          </Route>
          <Route path="/operaciones">
            <Operaciones changeChap={changeChap} />
          </Route>
          <Route path="/transformacion">
            <Transformacion changeChap={changeChap} />
          </Route>
          <Route path="/soluciones">
            <Soluciones changeChap={changeChap} />
          </Route>
          <Route path="/secciones">
            <Secciones changeChap={changeChap} />
          </Route>
          <Route path="/">
            <Home changeChap={changeChap} />
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  ) : (
    <>
      <div
        className="position-fixed left-0 bottom-0"
        style={{
          height: "8px",
          background: "#ff5100",
          zIndex: 100,
          width: `${yScroll}%`,
          borderTop: "2px solid #FF7055",
          borderRight: "2px solid #FF7055",
        }}
      ></div>
      <Nav changeChap={changeChap} />
      <div className={classes[animate]} onAnimationEnd={aend}></div>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/slides" component={Slides} />
          <Route path="/desempeño">
            <Desempeño changeChap={changeChap} />
          </Route>
          <Route path="/carta">
            <Carta changeChap={changeChap} />
          </Route>
          <Route path="/gobierno">
            <Gobierno changeChap={changeChap} />
          </Route>
          <Route path="/liderazgo">
            <Liderazgo changeChap={changeChap} />
          </Route>
          <Route path="/sostenibilidad">
            <Sosten changeChap={changeChap} />
          </Route>
          <Route path="/operaciones">
            <Operaciones changeChap={changeChap} />
          </Route>
          <Route path="/transformacion">
            <Transformacion changeChap={changeChap} />
          </Route>
          <Route path="/soluciones">
            <Soluciones changeChap={changeChap} />
          </Route>
          <Route path="/secciones">
            <Secciones changeChap={changeChap} />
          </Route>
          <Route path="/">
            <Home changeChap={changeChap} />
          </Route>
        </Switch>
      </AnimatePresence>
    </>
  );
};

export default App;
