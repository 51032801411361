import "./styles.scss";
import { useState } from "react";
import { ADMINS } from "./text";

const Administracion2 = (props) => {
  const [, setSelected] = useState(`OSVALDO`);
  return (
    <section className="one-screen-2">
      <h2 className={props.titleSepSize === "md" ? "title" : "title-sm-sep"}>
        Administración
      </h2>
      <div className="admin-container">
        {ADMINS.sort((a, b) => a.order > b.order).map((item) => (
          <div className="guy" onMouseEnter={() => setSelected(item.src)}>
            <div>
              <h2>{item.name}</h2>
              <div>{item.position}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

Administracion2.defaultProps = {
  titleSepSize: "md",
};

export { Administracion2 };
