import { useState } from "react";
import "./NavMobile.scss";

const NavMobile = ({ changeChap, CHAPTERS }) => {
  const [open, setOpen] = useState(false);
  const openClass = open ? "overlay-menu-mobile active" : "overlay-menu-mobile";
  const itemClass = open ? "menu-item-mobile active2" : "menu-item-mobile ";
  return (
    <>
      <div className={openClass} style={{ zIndex: 99000 }}>
        <div className="navbar2">
          <div className="secundary-text">
            MEMORIA <br />
            ANUAL 2020
          </div>
          <a href="/" className="logo">
            <img
              src="images/all/logo-blanco.svg"
              alt="ABASTIBLE2"
              width="137px"
            />
          </a>
          <a
            className="descarga"
            href="https://abastible.socialweb.cl/2020/pdf/Memoria_Abastible_2020_completa.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/all/descargar-orange.svg" alt="" />
          </a>

          <button className="burguer" onClick={() => setOpen((a) => !a)}>
            <img src="images/all/BURGUER_CLOSE 2.svg" alt="" />
          </button>
        </div>
        <div className="menu-mobile">
          {CHAPTERS.map((item, i) => (
            <div
              key={"chap" + i}
              className={itemClass}
              onClick={() => {
                changeChap(item.location);
                setOpen(false);
              }}
            >
              <div className="row">
                <div className="col-1 nav_item_number text-uppercase">
                  {item.l}
                </div>
                <div className="col nav_item_title text-uppercase">
                  {item.name}
                </div>
              </div>
            </div>
          ))}
          <div
            href="https://abastible.socialweb.cl/2020/pdf/Cap_08_Abastible_2020.pdf"
            download="lorem-ipsum.pdf"
            className={itemClass}
            onClick={() => {
              window.open(
                "https://abastible.socialweb.cl/2020/pdf/Cap_08_Abastible_2020.pdf",
                "_blank",
                "fullscreen=yes"
              );
              return false;
            }}
          >
            <div className="row">
              <div className="col-1 nav_item_number text-uppercase">08</div>
              <div className="col nav_item_title text-uppercase">
                estados financieros
              </div>
            </div>
          </div>
        </div>
      </div>
      {!open ? (
        <div className="navbar" style={{ background: "#53575a" }}>
          <div className="primary-text text-orange">
            MEMORIA <br />
            ANUAL 2020
          </div>

          <a href="/" className="logo">
            <img src="images/all/LOGO.svg" alt="ABASTIBLE1" />
          </a>
          <a
            className="descarga"
            href="https://abastible.socialweb.cl/2020/pdf/Memoria_Abastible_2020_completa.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/all/descargar-white.svg" alt="" />
          </a>

          <button className="burguer" onClick={() => setOpen((a) => !a)}>
            <img src="images/all/BURGUER.svg" alt="" />
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default NavMobile;
