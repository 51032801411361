/* eslint-disable jsx-a11y/anchor-has-content */
import "./liderazgo.scss";
import React, { Component } from "react";
import Typist from "react-typist";
import useElementOnScreen from "hooks/UseElementOnScreen";
import {
  ComiteCrisis,
  MedidasSanitarias,
  ProgramaBeneficios,
} from "components/liderazgo";
import Final from "components/Final";
import { MobileDownload, MobileNext } from "components/MobileEnd";
import { isMobile } from "react-device-detect";

export class AnimatedNumber extends Component {
  constructor(props) {
    super(props);

    this.animating = false;
    this.finished = false;
    this.startTime = 0;
    this.lastTime = 0;
    this.elapsedTime = 0;
    this.deltaTime = 0;

    this.state = { currValue: 0 };
  }

  componentDidMount() {
    this.interval = setInterval(this.animate, 10);
  }

  render() {
    return <div className={this.props.className}>{this.state.currValue}%</div>;
  }

  static easeOutCirc(x) {
    return Math.sqrt(1 - Math.pow(x - 1, 2));
  }

  animate = () => {
    if (this.finished) {
      clearInterval(this.interval);
      return;
    } else {
      if (!this.animating) {
        var now = Date.now();

        this.animating = true;
        this.startTime = now;
        this.lastTime = now;
        this.elapsedTime = 0;
        this.deltaTime = 0;

        this.setState({
          currValue: this.props.initValue,
        });
      } else {
        this.deltaTime = Date.now() - this.lastTime;
        this.lastTime += this.deltaTime;
        this.elapsedTime += this.deltaTime;

        if (
          this.elapsedTime < this.props.duration &&
          (this.props.value > this.props.initValue
            ? this.state.currValue < this.props.value
            : this.state.currValue > this.props.value)
        ) {
          var step = AnimatedNumber.easeOutCirc(
            this.elapsedTime / this.props.duration
          );

          this.setState({
            currValue: (
              this.props.initValue +
              step * (this.props.value - this.props.initValue)
            ).toFixed(0),
          });
        } else {
          this.animating = false;
          this.finished = true;

          clearInterval(this.interval);

          this.setState({
            currValue: this.props.value,
          });
        }
      }
    }
  };
}

AnimatedNumber.defaultProps = {
  initValue: 0,
  duration: 2000,
  className: "fs-giant text-center",
};

function Typing(props) {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });

  const text = (
    <Typist
      cursor={{ show: false }}
      avgTypingDelay={10}
      stdTypingDelay={0}
      startDelay={500}
    >
      {props.children}
    </Typist>
  );

  if (!isVisible) {
    return <div ref={containerRef}></div>;
  } else {
    return text;
  }
}

Typing.shown = false;

const Liderazgo = ({ changeChap }) => {
  return (
    <chapter>
      {!isMobile ? (
        <a
          href="https://abastible.socialweb.cl/2020/pdf/Cap_02_Abastible_2020.pdf"
          target="_blank"
          class="descargar-capitulo"
          rel="noreferrer"
        ></a>
      ) : (
        ""
      )}
      <div className="container-fluid w-100 vh120 bg-light-gray">
        {/* <div className="row vh120 justify-content-center align-items-center bg-light-gray text-orange fw-bold"> */}
        <div
          className="row justify-content-center align-items-center bg-light-gray text-orange fw-bold"
          style={{ padding: "10vh 0px 0px 0px" }}
        >
          <div className="col-12 col-md-4">
            <AnimatedNumber value={49} initValue={20} duration={2000} />
            <div className="text-gray fs-4 fw-bold text-center fs-sm-inf">
              De los colaboradores adoptó la modalidad de teletrabajo
            </div>
          </div>
          {isMobile ? (
            <div
              style={{
                background: "#ff5100",
                height: "2px",
                margin: "5vh 0 5vh 0",
              }}
            ></div>
          ) : (
            <div className="col-auto vl mt-5"></div>
          )}
          <div className="col-12 col-md-4">
            <AnimatedNumber value={51} initValue={0} duration={3000} />
            <div className="text-gray fs-4 fw-bold text-center">
              Se mantuvo cumpliendo labores presenciales esenciales para seguir
              llegando con la energía limpia del gas licuado a todo el país
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <ComiteCrisis />

      <MedidasSanitarias />
      <ProgramaBeneficios />
      {isMobile ? (
        <section className="bg-light-gray">
          <MobileDownload href="https://abastible.socialweb.cl/2020/pdf/Cap_02_Abastible_2020.pdf" />
          <MobileNext href="/secciones#cap03" changeChap={changeChap} />
        </section>
      ) : (
        <Final
          className="btn-proximo-capitulo bg-light-gray"
          changeChap={changeChap}
          to="/secciones#cap03"
        />
      )}
    </chapter>
  );
};

export default Liderazgo;
