import React, { useContext, useCallback } from "react";
import { CursorContext } from "./cursor/CursorContextProvider";

const VideoHover = ({ isPlaying, children }) => {
  const [, setCursor] = useContext(CursorContext);
  const toggleEnter = useCallback(() => {
    setCursor(({ show }) => ({
      show: isPlaying ? false : true,
      text: "VER VIDEO",
    }));
  }, [setCursor, isPlaying]);
  const toggleLeave = useCallback(() => {
    setCursor(({ show }) => ({
      show: false,
      text: "VER VIDEO",
    }));
  }, [setCursor]);
  const toggleText = useCallback(() => {
    setCursor(({ show }) => ({
      show: !show,
      text: "VER VIDEO",
    }));
  }, [setCursor]);

  return (
    <div
      onMouseEnter={toggleEnter}
      onMouseLeave={toggleLeave}
      onClick={toggleText}
    >
      {children}
    </div>
  );
};
export default VideoHover;
