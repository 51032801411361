import { useState } from "react";
import "./NavDesk.scss";

const NavDesk = ({ changeChap, CHAPTERS }) => {
  const [open, setOpen] = useState(false);
  const openClass = open ? "overlay-menu active" : "overlay-menu";
  const itemClass = open
    ? "menu-item py-2 border-y cursor-pointer col-auto active2"
    : "menu-item py-2 border-y cursor-pointer col-auto";
  return (
    <>
      <div className={openClass}>
        <div
          className="w-100 px-4 py-2 d-flex justify-content-around align-items-center"
          style={{ zIndex: 100, color: "white" }}
        >
          <a href="/" className="logo-abastible" style={{ top: "2vh" }}>
            <img
              src="images/all/logo-blanco.svg"
              alt="ABASTIBLE"
              width="137px"
            />
          </a>

          <span
            className=" text-uppercase text-spacing5pt position-absolute"
            style={{ transform: "translate(4vw, 3vh)" }}
          >
            MEMORIA ANUAL 2020
          </span>

          <a
            className="btn-descargar"
            href="https://abastible.socialweb.cl/2020/pdf/Memoria_Abastible_2020_completa.pdf"
            target="_blank"
            rel="noreferrer"
            style={{ top: "2vh" }}
          >
            <img src="images/all/descargar-orange.svg" alt="" />
          </a>

          <button
            className="btn btn-menu"
            onClick={() => setOpen((a) => !a)}
            style={{ top: "2vh" }}
          >
            <img src="images/all/BURGUER_CLOSE.svg" alt="" />
          </button>
        </div>

        <div className="container-fluid" style={{ marginTop: "5vh" }}>
          <div className="nav mt-2 row justify-content-end ">
            <div className=" menu col-12 col-md-8 fs-3 text-white text-uppercase ">
              {CHAPTERS.map((item, i) => (
                <div
                  key={"chap" + i}
                  className={itemClass}
                  style={{ letterSpacing: "0.2rem" }}
                  onClick={() => {
                    changeChap(item.location);
                    setOpen(false);
                  }}
                >
                  <span className="nav_item_content text-orange fw-bold">
                    {item.l}
                  </span>{" "}
                  {item.name}
                </div>
              ))}
              <div
                href="https://abastible.socialweb.cl/2020/pdf/Cap_08_Abastible_2020.pdf"
                download="lorem-ipsum.pdf"
                className={itemClass}
                style={{ letterSpacing: "0.2rem" }}
                onClick={() => {
                  window.open(
                    "https://abastible.socialweb.cl/2020/pdf/Cap_08_Abastible_2020.pdf",
                    "_blank",
                    "fullscreen=yes"
                  );
                  return false;
                }}
              >
                <span className=" nav-item text-orange fw-bold">08 </span>
                Estados Financieros
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="nav-bar"
        className="position-fixed w-100 px-4 py-2 d-flex justify-content-around align-items-center"
        style={{ zIndex: 100, color: "white" }}
      >
        <a href="/" className="logo-abastible">
          <img src="images/all/LOGO.svg" alt="ABASTIBLE" />
        </a>
        <span
          className="text-orange text-uppercase text-spacing5pt position-absolute"
          // style={{ transform: "translate(4vw, 3vh)" }}
        >
          MEMORIA ANUAL 2020
        </span>
        <a
          className="btn-descargar"
          href="https://abastible.socialweb.cl/2020/pdf/Memoria_Abastible_2020_completa.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <img src="images/all/descargar-white.svg" alt="" />
        </a>
        <button className="btn btn-menu" onClick={() => setOpen((a) => !a)}>
          <img src="images/all/BURGUER.svg" alt="" />
        </button>
      </div>
    </>
  );
};
export default NavDesk;
