import Typist from 'react-typist';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import './TypingSlide.scss';

const TypingSlide = ({ children, background = 'white', color = 'orange' }) => {
    const { ref, inView } = useInView({
        threshold: 0.2,
    });

    const [typing, setTyping] = useState(false);

    useEffect(() => {
        setTyping(inView);
    }, [inView]);
    return (
        <div ref={ref} className='slide-4' style={{ background, color }}>
            {typing ? (
                <Typist
                    cursor={{ show: false }}
                    avgTypingDelay={0}
                    stdTypingDelay={0}
                    startDelay={0}
                >
                    {children}
                </Typist>
            ) : (
                ''
            )}
        </div>
    );
};

export default TypingSlide;
