import React from "react";
import CursorHover from "./CursorHover";
import gsap, { ScrollTrigger } from "gsap/all";
import { ImgInView } from "../ImgInView";

gsap.registerPlugin(ScrollTrigger);

const radius = 30;
const diameter = radius * 2;
const animDuration = 1; // (sec) self explanatory

// (sec) offset between end of animation and page push
//                        [ scroll, chapter change]
//const pagePushTimeOffset = 0.5;

class CursorAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.cursor = null;
    this.box = null;
    this.animation = null;
    this.state = { x: 0, y: 0 };
  }

  _onMouseMove(e) {
    setTimeout(() => {
      this.setState({ x: e.pageX, y: e.pageY });
    }, 100);
  }

  render() {
    const { x, y } = this.state;
    const xPos =
      this.box === null
        ? 0
        : x -
          (window.pageXOffset + this.box.getBoundingClientRect().x) +
          radius;
    const yPos =
      this.box === null
        ? 0
        : y -
          (window.pageYOffset + this.box.getBoundingClientRect().y) +
          radius;

    return (
      <>
        <div
          className="box"
          style={{
            width: this.props.width,
            transform: this.props.transform,
          }}
          onMouseMove={this._onMouseMove.bind(this)}
          ref={(div) => (this.box = div)}
        >
          <CursorHover text={this.props.text}>
            <div
              style={{
                zIndex: 10000,
                position: "fixed",
                top: yPos,
                left: xPos,
                height: `${diameter}px`,
                width: `${diameter}px`,
                borderRadius: "50%",
                transform: `translate(-${diameter}px, -${diameter}px)`,
                pointerEvents: "none",
                backgroundColor: "white",
                opacity: 0,
              }}
              ref={(div) => (this.cursor = div)}
            ></div>
            <div
              onClick={() => {
                gsap.set(this.cursor, { autoAlpha: 0 });

                this.animation = gsap
                  .fromTo(
                    this.cursor,
                    { autoAlpha: 1 },
                    {
                      zIndex: 10000,
                      paused: true,
                      position: "fixed",
                      top: yPos,
                      left: xPos,
                      scale: 250,
                      duration: animDuration,
                      ease: "quad.out",
                      opacity: 1,
                    }
                  )
                  .play();
                window.scrollTo(0, 0);
                setTimeout(() => {
                  this.props.history.push(this.props.to);
                }, animDuration * 500);
              }}
              style={{ overflow: "hidden" }}
            >
              <ImgInView src={this.props.src} alt="" />
            </div>
          </CursorHover>
        </div>
      </>
    );
  }
}

CursorAnimation.defaultProps = {
  width: "100%",
  transform: "none",
};

export default CursorAnimation;
