import TextInDown from "components/Utils/TextInDown";
import "./Slide3.scss";
import { useRef, useEffect, useState } from "react";

const PatagoniaFresh = ({ src = "images/soluciones/bg1.jpg" }) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");

  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
      return () => {
        window.removeEventListener("scroll", scrollListen);
      };
    });
  });

  return (
    <div
      ref={ref}
      className="slide-left"
      style={{
        background: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: lax,
      }}
    >
      <div className="side-content bg-left">
        <TextInDown>
          <div className="box">
            <h2>PATAGONIA FRESH</h2>
            <p>
              Patagonia Fresh -filial de Empresas Iansa- es una empresa con
              presencia en Chile y Perú cuya producción de jugo concentrado,
              pulpa de frutas, pulpa de vegetales, pasta de tomate y productos
              derivados de la manzana les otorga un prestigio importante en el
              mercado agroalimentario.
            </p>

            <img src="images/soluciones/group.png" alt="" />
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

export { PatagoniaFresh };
