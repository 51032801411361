const ADMINS = [
    {
        name: 'Osvaldo Acuña Uribe',
        position: 'Gerente de Personas',
        order: 1,
        src: 'OSVALDO',
    },
    {
        name: 'Luis Donoso Roblero',
        position: 'Gerente de Riesgo Operacional',
        order: 3,
        src: 'LUIS',
    },
    {
        name: 'Paula Frigerio Visus',
        position: 'Gerente de Desarrollo, Innovación y Proyectos',
        order: 2,
        src: 'PAULA',
    },
    {
        name: 'Álvaro Gallegos Bresler',
        position: 'Gerente de Control Interno',
        order: 7,
        src: 'ALVARO',
    },
    {
        name: 'Juan Pablo Guzmán Rencoret',
        position: 'Gerente de Administración y Finanzas Corporativas',
        order: 9,
        src: 'JUAN_PABLO',
    },

    {
        name: 'Paula Jervis Ortiz',
        position: 'Gerente Legal y Asuntos Corporativos',
        order: 5,
        src: 'PAULA_HERVIS',
    },
    {
        name: 'Ignacio Mackenna Ronco',
        position: 'Gerente Comercial Granel, Soluciones Energéticas y Autogas',
        order: 4,
        src: 'IGNACIO',
    },
    {
        name: 'Janislav Marinovic Castellanos',
        position: 'Gerente de Administración y Finanzas',
        order: 6,
        src: 'JANISLAV',
    },
    {
        name: 'Sebastián Montero Morán',
        position: 'Gerente de Operaciones',
        order: 8,
        src: 'SEBASTIAN',
    },
    {
        name: 'Julio Vidal Pradenas',
        position: 'Gerente Corporativo de Marketing y Clientes',
        order: 10,
        src: 'JULIO',
    },
];

export { ADMINS };
