import { isMobile } from "react-device-detect";
import NavMobile from "./NavMobile";
import NavDesk from "./NavDesk";

const CHAPTERS = [
  { location: "/carta", l: "", name: "Carta del presidente" },
  { location: "/gobierno", l: "01", name: "Gobierno corporativo" },
  {
    location: "/liderazgo",
    l: "02",
    name: "Liderazgo y Apoyo en tiempos de Crisis",
  },
  { location: "/sostenibilidad", l: "03", name: "Sostenibilidad" },
  { location: "/operaciones", l: "04", name: "Operaciones y Abastecimiento" },
  { location: "/transformacion", l: "05", name: "Transformación Digital" },
  { location: "/soluciones", l: "06", name: "Soluciones Energéticas" },
  {
    location: "/desempeño",
    l: "07",
    name: "Desempeño de Abastible y Filiales",
  },
  // { location: '/estados', l: '08', name: 'Estados Financieros' },
];

const Nav = ({ changeChap }) =>
  isMobile ? (
    <NavMobile changeChap={changeChap} CHAPTERS={CHAPTERS} />
  ) : (
    <NavDesk changeChap={changeChap} CHAPTERS={CHAPTERS} />
  );

export default Nav;
