import React from "react";
import CursorContextProvider from "../components/cursor/CursorContextProvider";
import Cursor from "../components/cursor/Cursor";
import Seccion00 from "../sections/seccion00";
import Seccion01 from "../sections/seccion01";
import Seccion02 from "../sections/seccion02";
import Seccion03 from "../sections/seccion03";
import Seccion04 from "../sections/seccion04";
import Seccion05 from "../sections/seccion05";
import Seccion06 from "../sections/seccion06";
import Seccion07 from "../sections/seccion07";
import gsap, { ScrollTrigger } from "gsap/all";
import { isMobile } from "react-device-detect";
import "./seccionesMobile.scss";

gsap.registerPlugin(ScrollTrigger);

const Secciones = ({ changeChap }) =>
  isMobile ? (
    <SeccionesMobile changeChap={changeChap} />
  ) : (
    <SeccionesDesk changeChap={changeChap} />
  );

const SeccionesMobile = ({ changeChap }) => {
  return (
    <div className="secciones-mobile">
      <div className="row" id="cap00">
        <div className="column">
          <div className="section-title-mobile">
            CARTA DEL
            <br />
            PRESIDENTE
          </div>
        </div>
        <div className="column" onClick={() => changeChap("/carta")}>
          <img src="images/mobile/secciones/CAP_00.jpg" alt="" />
        </div>
      </div>
      <div className="row" id="cap01">
        <div className="column">
          <div className="section-title-mobile">
            GOBIERNO
            <br />
            CORPORATIVO
          </div>
          <div className="section-number-mobile">01</div>
        </div>
        <div className="column" onClick={() => changeChap("/gobierno")}>
          <img src="images/mobile/secciones/CAP_01.jpg" alt="" />
        </div>
      </div>
      <div className="row" id="cap02">
        <div className="column">
          <div className="section-title-mobile">
            LIDERAZGO Y
            <br />
            APOYO EN TIEMPOS
            <br />
            DE CRISIS
          </div>
          <div className="section-number-mobile">02</div>
        </div>
        <div className="column" onClick={() => changeChap("/liderazgo")}>
          <img src="images/mobile/secciones/CAP_02.jpg" alt="" />
        </div>
      </div>
      <div className="row" id="cap03">
        <div className="column">
          <div className="section-title-mobile">SOSTENIBILIDAD</div>
          <div className="section-number-mobile">03</div>
        </div>
        <div className="column" onClick={() => changeChap("/sostenibilidad")}>
          <img src="images/mobile/secciones/CAP_03.jpg" alt="" />
        </div>
      </div>
      <div className="row" id="cap04">
        <div className="column">
          <div className="section-title-mobile">
            OPERACIONES
            <br />Y ABASTECIMIENTO
          </div>
          <div className="section-number-mobile">04</div>
        </div>
        <div className="column" onClick={() => changeChap("/operaciones")}>
          <img src="images/mobile/secciones/CAP_04.jpg" alt="" />
        </div>
      </div>
      <div className="row" id="cap05" style={{ marginTop: "4vh" }}>
        <div className="column">
          <div className="section-title-mobile">
            TRANSFORMACIÓN
            <br />
            DIGITAL
          </div>
          <div className="section-number-mobile">05</div>
        </div>
        <div className="column" onClick={() => changeChap("/transformacion")}>
          <img src="images/mobile/secciones/CAP_05.jpg" alt="" />
        </div>
      </div>
      <div className="row" id="cap06" style={{ marginTop: "4vh" }}>
        <div className="column">
          <div className="section-title-mobile">
            SOLUCIONES
            <br />
            ENERGÉTICAS Y AUTOGAS
          </div>
          <div className="section-number-mobile">06</div>
        </div>
        <div className="column" onClick={() => changeChap("/soluciones")}>
          <img src="images/mobile/secciones/CAP_06.jpg" alt="" />
        </div>
      </div>
      <div
        className="row"
        id="cap07"
        style={{ marginTop: "4vh", borderBottom: "none" }}
      >
        <div className="column">
          <div className="section-title-mobile">
            DESEMPEÑO DE ABASTIBLE
            <br />Y FILIALES
          </div>
          <div className="section-number-mobile">07</div>
        </div>
        <div className="column" onClick={() => changeChap("/desempeño")}>
          <img src="images/mobile/secciones/CAP_07.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

const SeccionesDesk = ({ changeChap }) => {
  return (
    <>
      <CursorContextProvider>
        <Cursor />
        <div className="Secciones">
          <div id="cap00">
            <Seccion00 />
          </div>
          <div id="cap01">
            <Seccion01 />
          </div>
          <div id="cap02">
            <Seccion02 />
          </div>
          <div id="cap03">
            <Seccion03 />
          </div>
          <div id="cap04">
            <Seccion04 />
          </div>
          <div id="cap05">
            <Seccion05 />
          </div>
          <div id="cap06">
            <Seccion06 />
          </div>
          <div id="cap07">
            <Seccion07 />
          </div>
        </div>
      </CursorContextProvider>
    </>
  );
};

export default Secciones;
