import Lottie from "react-lottie";
import animationData from "../images/ESFERA.json";
import React, { Component } from "react";

class UncontrolledLottie extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <Lottie
        options={defaultOptions}
        height={this.props.height}
        width={this.props.width}
      />
    );
  }
}
UncontrolledLottie.defaultProps = {
  height: "152px",
  width: "152px",
};
export default UncontrolledLottie;
