const MobileDownload = ({ href }) => (
  <section
    className="d-flex justify-content-center align-items-center"
    style={{ height: "25vh" }}
  >
    <a href={href} target="_blank" rel="noreferrer">
      <img src="images/all/mobile-download.svg" alt="" />
    </a>
  </section>
);

const MobileNext = ({ href = "/", changeChap = () => {} }) => (
  <section
    className="d-flex justify-content-center align-items-center"
    style={{ height: "25vh" }}
  >
    <div onClick={() => changeChap(href)}>
      <img src="images/all/mobile-next-chapter.svg" alt="" />
    </div>
  </section>
);

export { MobileDownload, MobileNext };
