import TextInDown from "components/Utils/TextInDown";
import styles from "./styles.module.scss";
import "./styles.scss";
import Space from "components/Space";
import CursorContextProvider from "components/cursor/CursorContextProvider";
import Cursor from "components/cursor/Cursor";
import VideoHover from "components/VideoHover";
import { useState, useEffect, useRef } from "react";
import React from "react";
import gsap, { ScrollTrigger } from "gsap/all";
import { isMobile } from "react-device-detect";
gsap.registerPlugin(ScrollTrigger);

const CrecimientoSostenible = ({ src = "images/sosten/header.jpg" }) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");
  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
      return () => {
        window.removeEventListener("scroll", scrollListen);
      };
    });
  });
  return (
    <div
      ref={ref}
      className="slide-left"
      style={{
        background: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: lax,
        backgroundPosition: "top",
      }}
    >
      <div className="side-content">
        <TextInDown>
          <div className={styles.box}>
            <h2>Por un crecimiento sostenible</h2>
            <p>
              Abastible trabaja permanentemente por crecer y ser un claro aporte
              al bienestar social en todas las comunidades donde está presente,
              impulsando iniciativas que aportan valor a su entorno.
            </p>
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

const AportandoValor = ({ src = "images/sosten/bg2.jpg" }) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");
  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
      return () => {
        window.removeEventListener("scroll", scrollListen);
      };
    });
  });
  return (
    <div
      ref={ref}
      className="slide-left "
      style={{
        background: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: lax,
        backgroundPosition: "top",
      }}
    >
      <div className="side-content">
        <TextInDown>
          <div className={styles.box}>
            <h2>Aportando valor a la comunidad</h2>
            <p>
              En conjunto con su valiosa red de distribuidores emplazados en
              todo Chile, Abastible trabajó colaborativamente por impulsar
              acciones solidarias que permitieron llevar energía a quienes más
              lo necesitaban.
            </p>
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

const CompromisoAmbiente = ({
  src = "images/sosten/compromiso-ambiente.jpg",
}) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");
  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
      return () => {
        window.removeEventListener("scroll", scrollListen);
      };
    });
  });
  return (
    <div
      ref={ref}
      className="slide-left"
      style={{
        background: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: lax,
        backgroundPosition: "top",
      }}
    >
      <div className="side-content  bg-left">
        <TextInDown>
          <div className={styles.box2}>
            <h2>Compromiso con el medio ambiente</h2>
            <p>
              El rol que el gas licuado cumple en esta transición energética es
              esencial, al ser esta energía mucho más limpia y eficiente gracias
              a su alto poder calorífico y bajo nivel de emisiones de GEI.
              Cuenta, con ventajas comparativas respecto de varios combustibles
              masivamente utilizados y muy intensos en emisiones, como por
              ejemplo, la leña, la bencina, el diésel, el petróleo.
            </p>
            <p>
              Son más de 50 los proyectos implementados en rubros tan diversos
              como la minería, agroindustria, hotelería y salud. A través de un
              equipo altamente especializado, Abastible entrega asesorías
              energéticas que se adaptan a la realidad y necesidades de cada
              proceso productivo para incrementar su desempeño sostenible.
            </p>
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

const CulturaEquipo = ({ src = "images/sosten/FOTOS_COMPROMISO.jpg" }) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");
  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
      return () => {
        window.removeEventListener("scroll", scrollListen);
      };
    });
  });
  return (
    <div
      ref={ref}
      className="slide-left "
      style={{
        background: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: lax,
        backgroundPosition: "top",
      }}
    >
      <div className="side-content">
        <TextInDown>
          <div className={styles.box2}>
            <h2 class="cultura-title">Nuestra cultura de equipo</h2>
            <p>
              El liderazgo de Abastible es resultado directo de la interacción
              profesional y comprometida de sus colaboradores que sitúan el
              bienestar de la persona en el centro de todas sus decisiones y
              procesos
            </p>
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

const SeguridadPilar = ({ src = "images/sosten/bg5.jpg" }) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");
  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
      return () => {
        window.removeEventListener("scroll", scrollListen);
      };
    });
  });
  return (
    <div
      ref={ref}
      className="slide-right"
      style={{
        background: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: lax,
        backgroundPosition: "top",
      }}
    >
      <div className="side-content bg-right">
        <TextInDown className>
          <div className={styles.box3}>
            <h2>Seguridad como pilar fundamental</h2>
            <p>
              La seguridad es un principio fundamental para la organización y
              garantizar un desempeño operacional con los más estrictos
              resguardos, es una tarea constante para las distintas áreas.
            </p>
            <p>
              Se implementa a nivel regional el Modelo de Gestión de Seguridad
              Vial, basado en la norma ISO 39001 y benchmarking de industrias de
              transporte mundial, que define los estándares de funcionamiento a
              partir de tres ejes: personas, activos y operación.
            </p>
            <p>
              Se logró disminuir el número de incidentes en nuestros
              colaboradores, en un 15% considerando el personal de Abastible y
              contratistas; y una reducción de un 54% del personal propio de
              Abastible.
            </p>
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

class AppearingImage extends React.Component {
  constructor(props) {
    super(props);
    this.cover = null;
  }

  componentDidMount() {
    gsap.to(this.cover, {
      paused: true,
      height: "25%",
      duration: 1.5,
      ease: "quad.inOut",
      scrollTrigger: {
        trigger: this.cover,
        start: "top 30%",
        end: "top top",
        once: true,
      },
    });
  }

  render() {
    return (
      <>
        <div className={this.props.className}>
          <div
            style={{
              overflow: "hidden",
              position: "relative",
              height: this.props.height,
              display: "inline-block",
            }}
          >
            <img
              src={this.props.src}
              alt={this.props.alt}
              style={{
                objectFit: "cover",
              }}
            />
            <div
              ref={(cover) => (this.cover = cover)}
              style={{
                position: "absolute",
                top: "-25%",
                backgroundColor: "#ff5100",
                width: "100%",
                height: "125%",
                zIndex: 2,
              }}
            ></div>
          </div>
        </div>
      </>
    );
  }
}

AppearingImage.defaultProps = {
  height: "inherit",
  className: "",
};

const CarpaComedor = () => (
  <CursorContextProvider>
    <Cursor />
    <CarpaComedor2 />
  </CursorContextProvider>
);

const CarpaComedor2 = () => {
  const video = useRef();
  const video2 = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaying2, setIsPlaying2] = useState(false);

  const setPlaying = () => {
    let element = video.current;
    setIsPlaying2(false);
    video2?.current?.pause();
    if (element.paused) {
      video.current.scrollIntoView({ behavior: "smooth" });
      setIsPlaying(true);
      element.play();
    } else {
      setIsPlaying(false);
      element.pause();
    }
  };

  const setPlaying2 = () => {
    let element = video2.current;
    setIsPlaying(false);
    video?.current?.pause();
    if (element.paused) {
      video2.current.scrollIntoView({ behavior: "smooth" });
      setIsPlaying2(true);
      element.play();
    } else {
      setIsPlaying2(false);
      element.pause();
    }
  };

  return (
    <section className="bg-light-gray">
      <div className="carpa-comedor">
        <h2>
          CARPA COMEDOR <br /> POSTAL CENTRAL
        </h2>
        <p className="p1">
          Se implementó una carpa comedor modular de 375 metros cuadrados en la
          Posta Central que permitió que los funcionarios aplicaran el
          distanciamiento físico necesario para lugares de reunión, disminuyendo
          las probabilidades de contagio por COVID-19.
        </p>
        <div className="img-container justify-content-around gallery">
          <div className="col-12 col-md-5 gallery">
            <AppearingImage src="images/sosten/POSTA_01.jpg" alt="" />
          </div>
          <div className="col-12 col-md-6 gallery">
            <AppearingImage src="images/sosten/POSTA_02.jpg" alt="" />
          </div>

          <div style={{ marginTop: "5.3125rem" }}>
            <div className="col-12">
              <AppearingImage src="images/sosten/POSTA-03.jpg" alt="" />
            </div>
          </div>
        </div>
        <h2>
          ALIANZA TECHO <br /> CHILE COMPARTE CALOR
        </h2>
        <div className="d-flex flex-wrap justify-content-around mt-4 ">
          {isMobile ? (
            <>
              <div className="col-12">
                <p>
                  En alianza con Techo se creó la campaña “Chile Comparte Calor”
                  para llevar gas licuado a familias vulnerables que viven en
                  campamentos y que contó también con el apoyo de los
                  colaboradores.
                </p>
              </div>
              <div className="col-6 d-flex align-items-center ">
                <img
                  src="images/sosten/LOGO TECHO.png"
                  alt=""
                  className="sostenLogo"
                  style={{ objectFit: "contain", width:'110px' }}
                />
              </div>
              <div className="col-3">
                <img
                  src="images/sosten/CHILE COMPARTE.png"
                  alt=""
                  style={{ objectFit: "contain", width:'110px' }}
                />
              </div>
            </>
          ) : (
            <>
              <div style={{ width: "30%" }}>
                <p className="p2">
                  En alianza con Techo se creó la campaña “Chile Comparte Calor”
                  para llevar gas licuado a familias vulnerables que viven en
                  campamentos y que contó también con el apoyo de los
                  colaboradores.
                </p>
                <div className="d-flex justify-content-around mt-4">
                  <img
                    src="images/sosten/LOGO TECHO.png"
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                  <img
                    src="images/sosten/CHILE COMPARTE.png"
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </div>
              <div style={{ width: "70%" }}>
                <AppearingImage
                  src="images/sosten/TECHO-01.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <p className="p3-footer">
                  {/* Lanzamiento campaña: Sebastián Bowen-Director ejecutivo Techo,
              Pablo Cárdenas - Presidente junta de vecinos “Renacer de la
              Maule”, Julio Vidal - Gerente Corporativo Marketing y Clientes. */}
                </p>
              </div>
            </>
          )}
        </div>
        <div className=" pb-4 mb-4"></div>
        <div className="position-relative one-screen">
          <AppearingImage
            src="images/sosten/TECHO-03.jpg"
            alt=""
            className="position-absolute top-0 start-0 col-12 col-md-7 gallery"
          />
          <AppearingImage
            src="images/sosten/TECHO_02.jpg"
            alt=""
            className="position-absolute bottom-0 end-0 col-12 col-md-4 gallery"
          />
        </div>
      </div>
      <div
        className={isPlaying ? "custom-video active" : "custom-video"}
        onClick={setPlaying}
      >
        <VideoHover isPlaying={isPlaying}>
          <video
            style={{ marginTop: "4rem" }}
            ref={video}
            width="100%"
            poster="images/sosten/VIDEO 1.jpg"
            // controls
            src="https://abastible.socialweb.cl/2020/videos/abastible-techo.mp4"
            type="video/mp4"
            // style={{ objectFit: 'cover' }}
          />
          {/* <div className='pelota'></div> */}
        </VideoHover>
      </div>
      <div className="carpa-comedor">
        <h2>
          DONACIONES JUNTO A <br /> SENAMA Y CESFAM
        </h2>
        <p className="p4">
          Las donaciones de gas licuado y estufas se realizaron en conjunto con
          el Ministerio de Desarrollo Social y Familia, con la red de hogares
          que administra el Servicio Nacional del Adulto Mayor y con la atención
          primaria de los Centros de Salud Familiar y postas rurales.
        </p>
      </div>
      <div className="d-flex justify-content-around flex-wrap">
        <AppearingImage
          src="images/sosten/DONACION_1.jpg"
          alt="no hay imagen"
          className="col-11 col-md-4 gallery"
          style={{ objectFit: "contain" }}
        />
        <AppearingImage
          src="images/sosten/DONACION_2.jpg"
          alt=""
          className="col-11 col-md-5 gallery"
          width="100%"
          height="auto"
        />
        {!isMobile ? (
          <div style={{ height: "14.0625rem" }} className="col-12"></div>
        ) : (
          ""
        )}
        <AppearingImage
          src="images/sosten/DONACION_3.jpg gallery"
          alt=""
          className="col-11 col-md-5"
        />
        <AppearingImage
          src="images/sosten/DONACION_4.jpg gallery"
          alt=""
          className="col-11 col-md-4"
          style={{ objectFit: "contain" }}
        />
      </div>
      <div
        className={isPlaying2 ? "custom-video active" : "custom-video"}
        onClick={setPlaying2}
      >
        <VideoHover isPlaying={isPlaying2}>
          <video
            style={{ marginTop: "4rem" }}
            ref={video2}
            width="100%"
            poster="images/sosten/VIDEO 2.jpg"
            src="https://abastible.socialweb.cl/2020/videos/abastible-albergues.mp4"
            type="video/mp4"
          />
        </VideoHover>
      </div>
      <Space h="8rem" />
    </section>
  );
};

export {
  CrecimientoSostenible,
  AportandoValor,
  CompromisoAmbiente,
  CulturaEquipo,
  SeguridadPilar,
  CarpaComedor,
};
