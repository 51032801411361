import "./secciones.scss";
import UncontrolledLottie from "../components/LottieAnimation";
import { useHistory } from "react-router-dom";
import CursorAnimation from "../components/cursor/CursorAnimation";

const img = (history) => {
  return (
    <CursorAnimation
      text="EXPLORAR CAPITULO 07"
      src="images/selecciondDeCapitulos/07/cortadas/CAP07_2.jpg"
      history={history}
      to="/desempeño"
      width="50%"
      transform="translate(15vw, 20vh)"
    />
  );
};

const ImgInView = ({ ...rest }) => {
  let history = useHistory();
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CAPITULO 07"
        history={history}
        to="/desempeño"
        {...rest}
      />
    </>
  );
};

const Seccion07 = () => {
  let history = useHistory();
  return (
    <div className="one-screen position-relative section">
      <div
        className="position-absolute top-50 start-0"
        style={{ transform: "translate(10vw, -15vh)" }}
      >
        <div className="section-title">
          DESEMPEÑO DE ABASTIBLE
          <br />Y FILIALES
        </div>
        <div className="section-title title-6 number">07</div>
      </div>
      <div
        className="position-absolute top-0 start-50"
        style={{ transform: "translate(0vw, 20vh)", zIndex: 1 }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/07/cortadas/CAP07_1.jpg"
          alt=""
          width="50%"
        />
      </div>
      <div className="position-absolute top-0 start-50">{img(history)}</div>
      <div
        className="position-absolute bottom-0 start-50"
        style={{ transform: "translate(-15vw, 0vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/07/cortadas/CAP07_3.jpg"
          alt=""
          width="50%"
        />
      </div>
      <div
        className="position-absolute bottom-50 start-50"
        style={{ transform: "translate(-15vw, 20vh)" }}
      >
        <UncontrolledLottie />
      </div>
    </div>
  );
};
export default Seccion07;
