/* eslint-disable jsx-a11y/anchor-has-content */
import TypingSlide from "components/Slides/TypingSlide";
import "./transformacion.scss";
import Final from "components/Final";
import { isMobile } from "react-device-detect";
import { MobileDownload, MobileNext } from "components/MobileEnd";

const Transformacion = ({ changeChap }) => {
  return (
    <div className="">
      {!isMobile ? (
        <a
          href="https://abastible.socialweb.cl/2020/pdf/Cap_05_Abastible_2020.pdf"
          target="_blank"
          class="descargar-capitulo"
          rel="noreferrer"
        ></a>
      ) : (
        ""
      )}
      <TypingSlide color="white" background="#565a5d">
        <div style={{ minHeight: "70vh" }}>
          <u> Se creó la nueva Gerencia de Transformación Digital</u>, que ante
          la necesidad de operar remotamente impulsó la implementación de varias
          iniciativas, además de la búsqueda de soluciones tecnológicas
          modernas, basadas en tecnologías web y en el uso de la nube.
        </div>
      </TypingSlide>

      <section className="w-100 mb-5 justify-content-center ">
        <div id="transformacion-digital"></div>
      </section>
      {isMobile ? (
        <section>
          <MobileDownload href="https://abastible.socialweb.cl/2020/pdf/Cap_05_Abastible_2020.pdf" />
          <MobileNext href="/secciones#cap06" changeChap={changeChap} />
        </section>
      ) : (
        <Final
          className="btn-proximo-capitulo"
          changeChap={changeChap}
          to="/secciones#cap06"
        />
      )}
    </div>
  );
};

export default Transformacion;
