import React, { useContext, useCallback } from "react";
import { CursorContext } from "./CursorContextProvider";

const CursorHover = ({ text, children }) => {
  const [, setCursor] = useContext(CursorContext);

  const toggleCursor = useCallback(() => {
    setCursor(({ show, active }) => ({
      show: !show && !active,
      text: text,
      active: active,
    }));
  }, [setCursor, text]);

  const cursorOff = useCallback(() => {
    setCursor(() => ({ show: false, text: "", active: true }));
  }, [setCursor]);

  return (
    <div
      onMouseEnter={toggleCursor}
      onMouseLeave={toggleCursor}
      onClick={cursorOff}
    >
      {children}
    </div>
  );
};
export default CursorHover;
