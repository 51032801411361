import TextInDown from "components/Utils/TextInDown";
import "./styles.scss";
import { useState, useEffect, useRef } from "react";

const OperacionSlide = ({
  src = "images/operaciones/osorno-plantabaja.jpg",
}) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");
  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
      return () => {
        window.removeEventListener("scroll", scrollListen);
      };
    });
  });
  return (
    <div
      ref={ref}
      className="slide-right"
      style={{
        background: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: lax,
        backgroundPosition: "top",
      }}
    >
      <div className="side-content bg-right">
        <TextInDown>
          <div className="box-2">
            <p>
              Se garantizó la entrega de productos y servicios a todos los
              clientes aplicando medidas eficaces a nivel operacional como la
              Metodología Go: sistema de gestión operacional que propicia una
              mejora continua.
            </p>
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

const TerminalMaritimo = ({
  src = "images/operaciones/terminal-maritimo.jpg",
}) => {
  const ref = useRef();
  const [lax, setLax] = useState("fixed");
  useEffect(() => {
    const scrollListen = window.addEventListener("scroll", () => {
      let pos = ref?.current?.getBoundingClientRect();
      let bga = pos?.y > 0 ? "fixed" : "scroll";
      if (lax !== bga) setLax(bga);
      return () => {
        window.removeEventListener("scroll", scrollListen);
      };
    });
  });
  return (
    <div
      ref={ref}
      className="slide-left"
      style={{
        background: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: lax,
        backgroundPosition: "top",
      }}
    >
      <div className="side-content bg-hell short">
        <TextInDown>
          <div className="box-3">
            <h2>TERMINAL MARÍTIMO SAN VICENTE</h2>
            <p>
              Abastible comenzó con la importación propia vía marítima de gas
              licuado a través de la toma de control del Terminal Marítimo San
              Vicente, ubicado en la Región de Biobío. Punto clave para la
              expansión y crecimiento de nuestra empresa.
            </p>
          </div>
        </TextInDown>
      </div>
    </div>
  );
};

export { OperacionSlide, TerminalMaritimo };
