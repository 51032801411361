import "./secciones.scss";
import UncontrolledLottie from "../components/LottieAnimation";
import { useHistory } from "react-router-dom";
// import { ImgInView } from "components/ImgInView";
import CursorAnimation from "../components/cursor/CursorAnimation";

const img = (history) => {
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CAPITULO 02"
        src="images/selecciondDeCapitulos/02/cortadas/CAP02_1.jpg"
        history={history}
        to="/liderazgo"
        width="45%"
        transform="translate(-5vw, 10vh)"
      />
    </>
  );
};

const ImgInView = ({ ...rest }) => {
  let history = useHistory();
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CAPITULO 02"
        history={history}
        to="/liderazgo"
        {...rest}
      />
    </>
  );
};

const Seccion02 = () => {
  let history = useHistory();
  return (
    <div className="one-screen position-relative section">
      <div
        className="position-absolute top-50 start-0"
        style={{ transform: "translate(10vw, -15vh)" }}
      >
        <div className="section-title">
          LIDERAZGO Y APOYO
          <br />
          EN TIEMPOS DE CRISIS
        </div>
        <div className="section-title title-6 number">02</div>
      </div>
      <div
        className="position-absolute top-0 start-50"
        style={{ transform: "translate(-13vw, 0vh)" }}
      >
        {img(history)}
      </div>
      <div
        className="position-absolute bottom-0 start-50"
        style={{ transform: "translate(-23vw, 0vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/02/cortadas/CAP02_2.jpg"
          alt=""
          width="50%"
        />
      </div>
      <div
        className="position-absolute bottom-0 start-50"
        style={{ transform: "translate(18vw, 0vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/02/cortadas/CAP02_3.jpg"
          alt=""
          width="50%"
        />
        <UncontrolledLottie />
      </div>
    </div>
  );
};
export default Seccion02;
