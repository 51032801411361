import React, { useEffect } from "react";
import ReactDOM from "react-dom";
// import App from './CleanApp';
import App from "./App";
import { BrowserRouter, useLocation, withRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("pathname", pathname);
    if (pathname !== "/secciones") {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}

withRouter(ScrollToTop);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
