import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const FinalHome = ({ className, changeChap = (t) => {} }) => {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) changeChap("/secciones");
  }, [inView]);

  return (
    <div className={className}>
      <div style={{ height: "4px" }} ref={ref}></div>
    </div>
  );
};

export default FinalHome;
