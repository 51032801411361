/* eslint-disable jsx-a11y/anchor-has-content */
import "./soluciones.scss";
import { AnimatedNumber } from "./Liderazgo";
import useElementOnScreen from "hooks/UseElementOnScreen";
import { PatagoniaFresh } from "components/soluciones";
import TypingSlide from "components/Slides/TypingSlide";
import Final from "components/Final";
import { isMobile } from "react-device-detect";
import { MobileDownload, MobileNext } from "components/MobileEnd";

function WaitForVisibleAnimatedNumber(props) {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });

  const animatedNumber = (
    <AnimatedNumber
      initValue={props.initValue}
      value={props.value}
      duration={props.duration}
      className={props.className}
    >
      {props.children}
    </AnimatedNumber>
  );

  if (!isVisible) {
    return <div ref={containerRef}></div>;
  } else {
    return animatedNumber;
  }
}

WaitForVisibleAnimatedNumber.defaultProps = {
  className: "fs-giant text-center",
};

const Soluciones = ({ changeChap }) => {
  return isMobile ? (
    <SolucionesMobile changeChap={changeChap} />
  ) : (
    <SolucionesWeb changeChap={changeChap} />
  );
};

const SolucionesMobile = ({ changeChap }) => {
  return (
    <div className="soluciones-mobile">
      <div className="screen-row-light center-all">
        <div className="col-11">
          <div className="h0 text-orange">
            <span className="underline-gray">
              Abastible se posiciona como un actor relevante en el desarrollo de
              soluciones integrales
            </span>{" "}
            de los más diversos rubros.
          </div>
        </div>
      </div>

      <PatagoniaFresh />

      <div className="row-light center-all">
        <div className="col-10 h0 text-orange justify-content-center">
          <div style={{ height: "25vh" }}></div>
          Abastible realizó una asesoría energética que concretó una solución
          integral para potenciar una producción más limpia a través del
          recambio de fuel oil por gas licuado.
        </div>
      </div>

      <div className="row-light center-all">
        <div className="col align-self-center position-relative">
          <div className="row d-flex justify-content-around align-items-center border-0">
            <div className="arrow-down-sm" style={{ marginTop: 15 }} />

            <div className="col-auto">
              <WaitForVisibleAnimatedNumber
                initValue={85}
                value={29}
                duration={4000}
                className="font-titan text-orange fw-bold"
              />
            </div>
          </div>
          <div className="percent-text text-end text-light-gray fs-4 fw-bold">
            Reducción de huella de carbono
          </div>
        </div>
      </div>

      <div className="row-light" style={{ paddingTop: 200 }}>
        <hr
          className="text-orange"
          style={{
            margin: "0px 5vw 0px 5vw",
            borderTop: "3px solid",
            opacity: 1,
          }}
        />
      </div>

      <div className="row-light center-all" style={{ paddingBottom: 200 }}>
        <div className="col align-self-center position-relative">
          <div className="row d-flex justify-content-around align-items-center border-0">
            <div className="arrow-down-sm" style={{ marginTop: 15 }} />

            <div className="col-auto">
              <WaitForVisibleAnimatedNumber
                initValue={100}
                value={11}
                duration={5000}
                className="font-titan text-orange fw-bold"
              />
            </div>
          </div>
          <div className="percent-text  text-end text-light-gray fs-4 fw-bold">
            Disminución en consumo de combustible
          </div>
        </div>
      </div>

      <div className="row-light center-all">
        <MobileDownload href="https://abastible.socialweb.cl/2020/pdf/Cap_00_Carta_del_Presidente.pdf" />
      </div>

      <div className="row-light">
        <hr
          className="text-orange"
          style={{
            margin: "0px 5vw 0px 5vw",
            borderTop: "3px solid",
            opacity: 1,
          }}
        />
      </div>
      <div className="row-light center-all" style={{ paddingTop: 25 }}>
        <MobileNext href="/secciones#cap07" changeChap={changeChap} />
      </div>
    </div>
  );
};

const SolucionesWeb = ({ changeChap }) => {
  return (
    <>
      <a
        href="https://abastible.socialweb.cl/2020/pdf/Cap_06_Abastible_2020.pdf"
        target="_blank"
        className="descargar-capitulo"
        rel="noreferrer"
      ></a>
      <TypingSlide color="#ff5100" background="#e3e3e2">
        <div className="d-desk">
          <u>
            Abastible se posiciona como un actor relevante en el desarrollo de
            soluciones integrales
          </u>{" "}
          de los más diversos rubros.
        </div>
        <div className="d-mobile">
          <u>
            Abastible se posiciona como un actor relevante en el desarrollo de
            soluciones integrales
          </u>{" "}
          de los más diversos rubros para generar un desempeño energético más
          eficiente, más limpio, que optimice el desarrollo operacional y la
          productividad de todo tipo de emprendimiento.
        </div>
      </TypingSlide>
      <PatagoniaFresh />

      <div className="row one-screen justify-content-center align-items-center bg-light-gray text-orange">
        <div className="col col-12">
          <div
            className="row justify-content-center fs-0 mt-5"
            style={{ marginLeft: "8%", marginRight: "8%" }}
          >
            Abastible realizó una asesoría energética que concretó una solución
            integral para potenciar una producción más limpia a través del
            recambio de fuel oil por gas licuado.
          </div>
          <div className="row justify-content-center fw-bold">
            <div className="col-4">
              <WaitForVisibleAnimatedNumber
                initValue={85}
                value={29}
                duration={4000}
              />
              <div className="text-gray fs-4 fw-bold text-center">
                Reducción de huella de carbono
              </div>
            </div>
            <div className="col-auto arrow-down mt-5"></div>
            <div className="col-4">
              <WaitForVisibleAnimatedNumber
                initValue={100}
                value={11}
                duration={5000}
              />
              <div className="text-gray fs-4 fw-bold text-center">
                Disminución en consumo de combustible
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="row mb-5 justify-content-center">
        <div className="col-12">
          <div style={{ height: "50px" }} />
          <a href="/secciones#cap07" className="btn-proximo-capitulo"></a>
        </div>
      </section> */}
      <Final
        className="btn-proximo-capitulo"
        changeChap={changeChap}
        to="/secciones#cap07"
      />

      {/* <Final className="bg-light-gray" changeChap={changeChap} /> */}
    </>
  );
};

export default Soluciones;
