import "./secciones.scss";
import UncontrolledLottie from "../components/LottieAnimation";
import { useHistory } from "react-router-dom";
// import { ImgInView } from "components/ImgInView";
import CursorAnimation from "../components/cursor/CursorAnimation";

const img = (history) => {
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CAPITULO 01"
        src="images/selecciondDeCapitulos/01/cortadas/CAP01_1.jpg"
        history={history}
        to="/gobierno"
        width="40%"
        transform="translate(-10vw, 0vh)"
      />
    </>
  );
};

const ImgInView = ({ ...rest }) => {
  let history = useHistory();
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CAPITULO 01"
        history={history}
        to="/gobierno"
        {...rest}
      />
    </>
  );
};

const Seccion01 = () => {
  let history = useHistory();
  return (
    <div className="one-screen position-relative section">
      <div
        className="position-absolute top-50 start-0 "
        style={{ transform: "translate(10vw, -10vh)" }}
      >
        <div className="section-title">
          GOBIERNO
          <br />
          CORPORATIVO
        </div>
        <div className="section-title title-6 number">01</div>
      </div>

      <div className="position-absolute top-0 start-50">{img(history)}</div>
      <div
        className="position-absolute top-50 start-50"
        style={{ transform: "translate(-20vw, 0vh)" }}
      >
        <ImgInView
          history={history}
          src="images/selecciondDeCapitulos/01/cortadas/CAP01_2.jpg"
          width="70%"
        />
      </div>
      <div
        className="position-absolute bottom-0 start-50"
        style={{ transform: "translate(21.5vw, 0vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/01/cortadas/CAP01_3.jpg"
          alt=""
          width="40%"
        />
      </div>
      <div
        className="position-absolute bottom-50 start-50"
        style={{ transform: "translate(15vw, 25vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/01/cortadas/CAP01_4.jpg"
          alt=""
          width="40%"
        />
      </div>
      <div
        style={{
          position: "absolute",
          left: "45%",
          top: "50%",
          overflow: "hidden",
        }}
      >
        <UncontrolledLottie />
      </div>
    </div>
  );
};
export default Seccion01;
