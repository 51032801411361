/* eslint-disable jsx-a11y/anchor-has-content */
import "./desempeño.scss";
// import styles from './desempeño.module.scss';
import { useState } from "react";
//import Final from 'components/Final';
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import UncontrolledLottie from "../components/LottieAnimation";
import React from "react";
import Final from "components/Final";
import { isMobile } from "react-device-detect";
import Space from "components/Space";
import { MobileDownload, MobileNext } from "components/MobileEnd";

const PAISES = {
  colombia: {
    img: "images/desempeno/FOTO_COLOMBIA.jpg",
    datos: ["231.134", "11,1%", "34,7%"],
    gas: "images/desempeno/asogas_colgas.png",
  },
  ecuador: {
    img: "images/desempeno/ECUADOR.jpg",
    datos: ["480.000", "2,0%", "39,2%"],
    gas: "images/desempeno/asogas-duragas.png",
  },
  peru: {
    img: "images/desempeno/FOTO_PERU.jpg",
    datos: ["480.000", "2,0%", "39,2%"],
    gas: "images/desempeno/asogas-solgas.png",
  },
  chile: {
    img: "images/desempeno/FOTO_CHILE.jpg",
    datos: ["504.888", "1,1%", "38,5%"],
    gas: "images/desempeno/asogas-abastible.png",
  },
};

const Desempeño = ({ changeChap }) =>
  isMobile ? (
    <DesempeñoMobile changeChap={changeChap} />
  ) : (
    <DesempeñoDesk changeChap={changeChap} />
  );

const PAISES2 = [
  { nombre: "Chile", toSet: "chile" },
  { nombre: "Peru", toSet: "peru" },
  { nombre: "Ecuador", toSet: "ecuador" },
  { nombre: "Colombia", toSet: "colombia" },
];

const DesempeñoMobile = ({ changeChap }) => {
  const [pais, setPais] = useState("chile");
  const mapSrc = `images/desempeno/mobile/map-${pais}.svg`;
  const paisSrc = `images/desempeno/mobile/${pais}.svg`;
  const gasSrc = `images/desempeno/mobile/gas-${pais}.png`;
  const { datos } = PAISES[pais];
  return (
    <chapter className="is-mobile desempeno bg-gray">
      <Space h="5vh" />
      <section>
        <div>
          <div className="m-2 my-4 py-2  row text-uppercase text-white fw-bold justify-content-between paises-bottom">
            {PAISES2.map((item) => {
              return (
                <div className="col-auto" onClick={() => setPais(item.toSet)}>
                  <div className={pais === item.toSet ? "text-orange" : ""}>
                    {item.nombre}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-4  d-flex align-items-center">
            <img className=" " width="100%" src={mapSrc} alt="" />
          </div>

          <div className="col-4  d-flex justify-content-center">
            <img className="" src={paisSrc} alt="" />
          </div>

          <div className="col-3  d-flex align-items-center ">
            <img className=" " src={gasSrc} alt="" />
          </div>
        </div>
      </section>
      <section>
        {pais === "peru" ? (
          <div className="text-center w-75 m-auto text-white peru">
            <Space h="10vh" />
            <div>
              El crecimiento de la compañía se vio reflejado en la recuperación
              del liderazgo en el mercado, alcanzando el
            </div>
            <div className="fw-bold masive">26%</div>
            <div>de participación, incluyendo la venta mayorista.</div>
          </div>
        ) : (
          <MobileCountryInfo a={datos[0]} b={datos[1]} c={datos[2]} />
        )}
      </section>
      {pais === "chile" ? (
        <section className="only-chile">
          <Space h="10vh" />
          <div className="container">
            <h2 className="text-white text-uppercase fw-bold fs-5 mb-4 title mb-4 pb-4">
              Reconocimientos
            </h2>

            {RECOS.map((props) => (
              <MobileReconocimiento {...props} />
            ))}
          </div>
        </section>
      ) : (
        ""
      )}

      <MobileDownload href="https://abastible.socialweb.cl/2020/pdf/Memoria_Abastible_2020_completa.pdf" />
      <div style={{ height: "2px", background: "#ff5100" }}></div>
      <MobileNext href="/secciones#chap00" changeChap={changeChap} />
    </chapter>
  );
};

const MobileReconocimiento = ({ h, p }) => (
  <div className="mt-4 pt-4">
    <h3 className="text-orange text-uppercase fw-bold fs-5">{h}</h3>
    <p className="text-white">{p}</p>
    <div
      style={{ background: "white", height: "2px", marginBottom: "10px" }}
    ></div>
  </div>
);

const MobileCountryInfo = ({ a = "777", b = "777", c = "777" }) => (
  <>
    <div className="row text-white info-pais mx-2">
      <div className="col-6 d-flex flex-column align-items-center justify-content-end">
        <div className="col-12 display-1 fw-bold">{a}</div>
        <div className="col-12 text-center">mil toneladas</div>
      </div>
      <div className="col-6 d-flex align-items-center">Vendidas el 2020</div>
    </div>
    <div className="row text-white info-pais">
      <div className="col-6 d-flex align-items-center  justify-content-end">
        <div className="display-1 fw-bold">{b}</div>
      </div>
      <div className="col-6 d-flex align-items-center">
        Aumento de ventas en relación con 2019
      </div>
    </div>
    <div className="row text-white info-pais">
      <div className="col-6 d-flex align-items-center  justify-content-end">
        <div className="display-1 fw-bold ">{c}</div>
      </div>
      <div className="col-6 d-flex align-items-center">
        Participación de mercado
      </div>
    </div>
  </>
);

const DesempeñoDesk = ({ changeChap }) => {
  const [pais, setPais] = useState({
    nombre: "",
    display: true,
  });

  if (pais.display === false) {
    setTimeout(() => {
      setPais({
        nombre: pais.nombre,
        display: true,
      });
    }, 500);
  }

  console.log(pais.nombre);
  return (
    <>
      <a
        href="https://abastible.socialweb.cl/2020/pdf/Cap_07_Abastible_2020.pdf"
        target="_blank"
        class="descargar-capitulo"
        rel="noreferrer"
      ></a>
      <div id="desempeno" className="des-container bg-gray ">
        <div className="map">
          {pais.display && pais?.nombre ? (
            <h2 key={Math.random()} className="title-pais position-absolute">
              {pais?.nombre != "peru" ? pais?.nombre : "Perú"}
            </h2>
          ) : (
            ""
          )}

          <motion.div
            initial="out"
            animate="in"
            variants={sideVariant}
            transition={sideTransition}
            // style={{ background: 'red', maxHeight: '80vh' }}
          >
            <Mapa setPais={setPais} />
          </motion.div>
          {!pais.nombre ? (
            <div id="selecciona-pais" className="fade-in-title"></div>
          ) : (
            ""
          )}
        </div>

        <div className="section-desempeno-title fade-in-title">
          <div className="" style={{ transform: "translate(-8vw, 0vh)" }}>
            <UncontrolledLottie />
          </div>
          DESEMPEÑO DE ABASTIBLE
          <br />Y FILIALES
        </div>
        <AnimatePresence exitBeforeEnter>
          {pais.display && pais.nombre !== "" && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transitionDuration="1s"
              >
                <img
                  className="fondo-imagen"
                  src={PAISES[pais.nombre]?.img}
                  alt=""
                />
              </motion.div>
              <div className="info-container">
                {pais.nombre === "peru" ? (
                  <Peru />
                ) : (
                  <Datos
                    a={PAISES[pais.nombre]?.datos?.[0]}
                    b={PAISES[pais.nombre]?.datos?.[1]}
                    c={PAISES[pais.nombre]?.datos?.[2]}
                  />
                )}
              </div>
              <div className="gas-container">
                <div className="gas">
                  <img src={PAISES[pais.nombre]?.gas} alt="" width="100%" />
                </div>
              </div>
            </>
          )}
        </AnimatePresence>
      </div>
      {pais.nombre === "chile" ? <Chile /> : ""}
      <Final
        className="btn-proximo-capitulo"
        changeChap={changeChap}
        to="/secciones#cap00"
      />
    </>
  );
};

const sideVariant = {
  in: {
    opacity: 1,
    x: "0",
  },
  out: {
    opacity: 0,
    x: "0vw",
  },
};
const sideTransition = {
  opacity: 0,

  duration: 2,
};

//<Final className='bg-gray' changeChap={changeChap} />
const RECOS = [
  {
    h: "Premio Nacional de Satisfacción de Clientes",
    p:
      "El compromiso de Abastible con la calidad de servicio fue reconocido por los clientes al otorgarle a la compañía doble reconocimiento en el marco del Premio Nacional de Satisfacción de Clientes: Gas Cilindro y Gas Medidor.",
  },
  {
    h: "Lealtad del Consumidor",
    p:
      "El premio Lealtad del Consumidor se otorga a las compañías que han obtenido los mejores índices en lealtad y buenas experiencias de clientes, y en 2020 Abastible fue la empresa que tuvo el mejor resultado en el sector gas licuado.",
  },
  {
    h: "Reputación Corporativa Ipsos-INC",
    p:
      "A nivel reputacional, Abastible tuvo un crecimiento importante en el Estudio Reputación Corporativa 2020 Ipsos-INC, en el cual obtuvo el primer lugar en la categoría Empresas de Gas, ocupando el lugar 26 de las 100 empresas medidas, siendo la cuarta con mayor crecimiento en reputación corporativa.",
  },
  {
    h: "Merco Talento",
    p:
      "En el segundo año consecutivo en que Abastible participa en la medición Merco Talento, se alzó en el lugar 28 a nivel nacional, encabezando el sector de energía.",
  },
];

const Chile = () => (
  <div className="bg-gray chile-container row">
    {RECOS.map((item) => (
      <Special h={item.h} p={item.p} />
    ))}
  </div>
);

const Special = ({ h, p }) => (
  <div className="boxCountry col-12 col-md-4">
    <div className="text-orange text-uppercase fw-bold">{h}</div>
    <p className="text-white text-justify">{p}</p>
  </div>
);

const Datos = ({ a = 0, b = 0, c = 0 }) => (
  <div className="datos">
    <div className="row">
      <div className="col text-center">
        <div className="fs-1">{a}</div>
        <div>mil toneladas</div>
      </div>
      <div className="col d-flex align-items-center text-custom">
        Vendidas el 2020
      </div>
    </div>
    <div className="row">
      <div className="col text-center fs-1">{b}</div>
      <div className="col d-flex align-items-center text-custom">
        Aumento de ventas en relación con 2019
      </div>
    </div>
    <div className="row">
      <div className="col text-center fs-1">{c}</div>
      <div className="col d-flex align-items-center text-custom">
        Participación de mercado
      </div>
    </div>
  </div>
);

const Peru = ({ a = 0, b = 0, c = 0 }) => (
  <div className="datos">
    <div className="row text-right peru">
      <p className="normal">
        El crecimiento de la compañía se vio reflejado en la recuperación del
        liderazgo en el mercado, alcanzando el
      </p>
      <p className="big">26%</p>
      <p className="normal">de participación, incluyendo la venta mayorista.</p>
    </div>
    {/* <div className='col d-flex align-items-center text-custom'>
        Ventas el 2020
      </div>
    </div>
    <div className='row'>
      <div className='col text-center fs-1'>{b}</div>
      <div className='col d-flex align-items-center text-custom'>
        Aumento de ventas en relación con 2020
      </div>
    </div>
    <div className='row'>
      <div className='col text-center fs-1'>{c}</div>
      <div className='col d-flex align-items-center text-custom'>
        Participación de mercado
      </div>
    </div> */}
  </div>
);

const Mapa = ({ setPais }) => {
  return (
    <svg viewBox="0 0 637 900" id="Capa_1">
      <circle id="pointCO" class="st0" cx="86.4" cy="761.9" r="3.7" />
      <circle id="pontEC" class="st0" cx="115.6" cy="761.9" r="3.7" />
      <circle id="pointPE" class="st0" cx="140.9" cy="761.9" r="3.7" />
      <circle id="pointCL" class="st0" cx="170.2" cy="761.9" r="3.7" />
      <g id="otros">
        <polygon
          class="st1"
          points="517.5,264 517,263 516,264.1 516,265 516.8,266.1 518.1,266.3 519.1,266.7 520.2,266.5 520.3,265.5 
				519.5,264.8 518.5,264.6 	"
        />
        <polygon
          class="st1"
          points="522.5,268 523.3,267.6 523.5,267.2 523.5,266.6 523,266.4 522.5,266.4 522.1,266.5 521.3,267.3 
				521.1,267.3 521,267.5 521,267.9 521.1,268 	"
        />
        <polygon
          class="st1"
          points="518,268.2 516.5,268.7 516.2,269.1 514.8,269.7 514.5,270.3 514.5,273.7 514.6,274.9 514.6,275.8 
				515,277.2 515,279.1 515.5,279.8 516.6,280.3 519.5,280.3 520.2,280.5 524.1,279.5 526,278.6 528,277.2 530.6,273.4 530.8,272.8 
				530.8,269.4 530.5,269.3 530.5,269.1 530,268.6 529.5,268.2 527.8,268 525.1,268.3 523.2,268.9 522,268.9 520.5,268.3 519.5,268.2 
					"
        />
        <polygon
          class="st1"
          points="511.8,272.2 513.2,270.7 513.5,270.3 514.5,269.4 514.5,269.1 514.2,269 514.1,268.4 512.8,268.7 
				512.8,268.2 513.2,267.8 514.5,267.2 514.3,266.9 513.5,267 513.2,267.2 512.1,267.5 511.6,267.8 511.5,268.2 511.4,269 510.3,270 
				510.1,270.7 510.1,271.1 510.8,271.6 510.8,272.3 	"
        />
        <polygon
          class="st1"
          points="519,263.9 519,263.6 519,262.8 518.3,263 518.1,263.2 518.2,263.9 518.5,264 	"
        />
        <polygon
          class="st1"
          points="516.5,266.8 516.2,266.4 515.5,266.6 515.8,267.3 516.5,267.3 	"
        />
        <polygon
          class="st1"
          points="231.1,164.7 231.3,164.4 231.8,163.4 232.7,162.5 232.9,162.5 232.8,160.7 233.4,159.2 234.4,159.3 
				236.4,159.2 236.9,158.2 238.1,156.5 239.4,157 240.4,157.9 241.2,157.8 241.2,157.5 242.3,157.1 243.1,156.4 243.3,156.2 
				243.6,155.9 244.4,155 245.8,154.8 246.1,154.6 246.4,152.7 247.1,151.7 247.8,151.5 247.9,151.4 248.3,150.5 249.8,151 
				250.2,151.7 250.4,151.9 252.4,152 253.6,151.1 254.9,151.1 255.9,150.6 256.6,150 258.6,148.9 260.4,149 259.7,147.9 258.9,147.3 
				255.6,145.7 253.9,144.2 252.7,143.4 250.8,143.1 248.9,143 247.3,142.2 246.7,142.1 245.3,142.1 244.2,142.5 242.3,142.5 
				241.8,142.2 240.8,141.9 239.3,142.1 238.7,142.5 237.8,142.7 236.2,142.8 233.4,143.4 230.3,143.4 228.1,143 225.8,142.9 
				224.6,143.1 223.2,143.8 221.3,143.9 221.8,144.1 221.4,144.7 220.9,145.7 218.3,147.5 216.6,149 215.9,149.2 215.9,149.3 
				214.7,150.2 214.6,150.5 214.6,150.8 214.7,151 214.9,151.8 214.9,152.7 213.8,153.5 213.7,153.9 213.8,154 215.6,154.1 
				216.4,155.2 216.8,155.7 217.6,156.2 218.3,156.4 218.7,156.9 218.8,157.1 220.1,157.2 220.1,157.8 220.3,157.9 220.8,158.1 
				221.3,158.1 221.8,157.5 223.2,157.4 223.7,157.7 224.8,158 225.8,158.8 225.9,159.4 225.8,161.8 226.1,162 225.8,162.4 
				225.8,162.4 226.7,162.3 227.6,162.7 227.8,163.3 227.8,163.9 228.8,164.9 228.8,164.8 	"
        />
        <path class="st1" d="M302.2,210.1L302.2,210.1L302.2,210.1z" />
        <polygon
          class="st1"
          points="303,208.3 303.1,207 304.2,206.8 304.9,207.2 305,207.1 306.4,205.2 307,204.6 306.9,204.5 
				306.3,204.3 306.3,203.1 306,202.4 305.2,202 305.1,201.5 305.3,199.9 305.6,199.5 306,199.7 304.7,198.8 303.3,197.2 302.7,196.7 
				300.4,194.7 298.9,194 297.2,193.6 295.2,193.5 293.5,193 292.1,192.3 289.4,192.2 288.6,192.4 288.3,192.6 287.4,193.6 
				287.2,195.9 286.2,196.4 286,196.3 285.8,195.1 285.2,194.9 284.7,194.9 282.1,196.3 279.9,196.7 279.2,197 278.2,198.1 
				277.6,198.3 276.8,198.3 275.7,198.2 273.2,197 272.7,197 269.3,197 269,196.1 269,195.3 268.2,194.8 267.9,193.3 267.6,193.1 
				266.4,192.7 266.2,192.5 266,192.8 265.3,192.8 265,192.6 264.7,192.6 264.1,193.5 263.9,195.1 264.7,195.6 265.5,196.4 
				265.2,197.4 264.6,198.1 264.5,198.4 264.6,200 264.2,201.1 263.1,202.5 262.9,202.5 263.4,203.7 263.6,203.6 264,202.7 
				264.4,202.2 265.7,202.2 268.4,202.5 269.3,202.7 270.8,203.4 272.1,203.5 272.5,203.7 273.2,204.3 273.8,205.6 274.6,206.6 
				275.7,207.9 276.5,207.9 277.1,206.9 278.1,206.6 278.5,207.9 278.6,209.2 279.1,210.6 279.2,211 281,211.1 281.7,211 284.1,209.5 
				285.1,208.6 284.9,207.7 284.1,207.1 283.5,206.4 282.3,205.4 282.1,204.9 282.1,204.3 282.4,203.5 283.6,202.7 284.7,202.2 
				285.2,202.2 286.4,201.5 287.6,200.2 289,198.1 289.3,197.7 289.6,197.5 291.1,197 291.7,197 292.1,197 294.1,198.4 296.7,199.6 
				298.2,201.4 299.4,200.9 300.4,200.9 301.1,201.6 301.2,202 300.6,202.1 299.2,203.3 299,204 298.9,205.2 299.1,206.8 299.7,207.8 
				301.1,209.4 302,210 302,209.2 	"
        />
        <polygon
          class="st1"
          points="442.5,204.5 441.7,204.5 440.9,205 440.5,205.4 438.2,206.4 437.7,206.8 437.4,207.3 435.7,207.6 
				435.5,207.8 435.2,208.5 435.2,209.6 435.5,210.9 435.8,211 436.7,210.8 437.2,210.9 438.3,211.9 437.4,213.2 435.6,214.4 
				433.4,215.2 433.4,217.4 432.8,218.5 431.9,219.7 431.8,219.7 431.7,219.9 431.8,220.1 433.5,222.2 435.1,223.9 435.6,224.8 
				436.3,225.7 436.9,226.1 436.9,226.3 437.8,226.4 439.5,225.8 441.1,225.9 441.9,227.5 442.1,228.6 441.9,229.6 441.6,231 
				442.5,231.2 442.8,231.4 444.7,231.9 444.7,234.2 445.4,235.1 445.5,236.3 445.3,237 443.9,238.7 443.8,240.1 443.6,241.2 
				442.8,243 442.7,247.2 442.8,247.5 444.4,249 444.6,250.4 444.5,252.1 444.6,252.3 445.1,252.8 445.5,253.1 446.1,254 446.9,254.6 
				449,255.9 450.2,256.3 451.9,256.3 452.4,256 453.4,254.7 454.7,254.2 456.2,254.2 456.9,253.7 458.1,253 460,253 461.1,251.6 
				463,250.8 463.8,250.7 464.6,251 464.8,251.2 465.3,251.4 467.1,251.3 467.6,251.2 466.6,250.3 464.6,247.9 463.8,246.4 
				463.6,245.6 462.8,244.6 462.6,243.3 462.1,242.7 461.9,241.5 461.6,241.4 460.2,241.3 459.7,241 459.1,240.3 459,239.7 
				458.1,238.8 457.6,238.6 456.3,236.5 455.3,235.4 455.3,234.4 455.9,232.7 456.5,231.5 456.6,229.5 456.8,228.9 457.2,228.5 
				457.9,228.1 459.1,227.7 460.3,227.7 460.8,227.6 460.9,227.1 461.8,226.3 461.8,225.9 461.1,225.4 461.5,224.5 462.6,222.8 
				463.5,222 463.6,221.6 464.1,221.9 463.6,221.4 462.6,219.1 461.1,218.1 459.9,216.8 459.2,215.9 458.8,215.5 457.2,214.5 
				456.6,214.3 455.6,214.1 454.1,214 453.3,214.1 452.7,213.9 452.6,210.6 452.4,209.7 451.3,208 446.6,203.7 444.6,202.3 
				443.9,202.2 443.5,202.5 443.2,202.5 443.2,204 	"
        />
        <polygon class="st1" points="265.9,192.1 265.7,192 265.7,192 	" />
        <polygon
          class="st1"
          points="208.6,157.3 209.8,157 210.3,156.1 211.3,155.8 211.6,155.4 211.8,154.4 212.3,153.7 212.7,153.9 
				212.7,153.7 212.7,153.7 212.6,153.5 212.8,153.4 212.8,152.9 213.9,152.1 213.9,152 213.8,151.5 213.6,151.2 213.6,150.3 
				213.8,149.5 215.2,148.6 215.3,148.3 216.1,148.1 217.7,146.7 220.2,145 220.6,144.2 220.8,143.7 221.3,143.9 221.3,143.6 
				221.1,143.6 220.9,143.6 220.8,143 219.9,142.9 218.8,143.6 218.6,143.6 217.6,142.9 216.9,142.1 216.9,142.6 215.3,142.6 
				213.6,142 213.8,141.3 214.1,136.7 214.2,126.9 201.8,127 201.7,130.9 197.9,130.9 200.4,133.3 202.8,134.9 203.4,136.2 
				204.6,137.4 205.1,138.2 204.9,140.6 204.4,141.3 203.9,141 200.9,141 197.1,141.2 193.9,146.2 193.9,146.6 192.9,148.2 192.4,149 
				192.8,149.8 192.6,150.5 192.2,150.8 191.9,153.2 191.4,153.3 191.6,153.5 192.2,154 192.4,154.2 193.4,154.9 193.7,155.2 
				195.6,156.6 196.4,157.1 197.8,157.9 198.9,158.3 200.6,158.5 203.2,158.5 204.8,158.7 206.9,159.1 207.6,159.5 207.7,159 	"
        />
        <polygon
          class="st1"
          points="224.9,159.5 224.9,159.4 224.3,158.9 223.3,158.7 222.9,158.4 222.3,158.5 221.7,159.1 220.6,159.1 
				219.7,158.8 219.1,158.2 219.1,158.2 218.4,158.1 217.8,157.6 217.7,157.3 217.3,157.2 215.9,156.4 215.6,155.7 215.1,155.1 
				213.6,155 212.8,154.6 212.8,154.8 212.6,155.8 211.9,156.7 211.1,156.9 210.4,157.9 209.3,158.2 208.6,159.4 208.4,159.9 
				207.9,159.7 209.2,160.7 210.9,161.5 213.1,161.7 214.3,162 216.3,163.2 218.8,163.9 222.7,164 225.1,163.1 225.3,162.7 
				224.8,162.3 	"
        />
        <polygon
          class="st1"
          points="190.9,152.7 191.2,150.3 191.7,149.9 191.7,149.8 191.2,148.5 191.7,148.3 191.9,147.6 192.9,146.2 
				193.1,145.8 196.4,140.2 200.9,140 203.9,140 204.1,138.5 203.8,138 202.4,136.8 201.9,135.6 199.7,134.1 196.2,130.6 196.8,129.8 
				196.9,129.9 200.7,129.9 200.8,126 214.6,125.9 216.6,125.5 216.9,125.5 218.9,121.6 219.8,121.2 220.8,121.3 220.7,122.3 
				220.1,122.2 219.8,122.4 217.6,126.5 216.6,126.5 215.2,126.8 215.1,136.7 214.8,141.3 215.4,141.6 216.9,141.6 216.9,140.7 
				217.2,140.3 218.1,139.4 219.6,138.3 220.6,137.2 220.9,136.2 221.1,136.2 221.4,134.2 221.6,132.4 221.4,129.5 221.7,127.4 
				222.3,125.5 222.9,124.2 222.9,123.5 222.4,122.7 220.9,122.1 220.8,121.8 220.8,120.9 222.4,118.5 223.6,118.9 223.1,121.1 
				223.8,121.3 224.3,121.8 224.1,123.9 224.4,125.2 224.9,124.5 225.8,121.5 225.8,119.9 227.1,116.5 227.4,115.1 227.1,114.5 
				226.3,114 227.6,113.1 227.6,112.2 226.3,111.7 227.3,110.4 227.9,109.1 227.9,107.9 229.3,105 230.6,104 232.3,102.2 232.7,100.8 
				232.8,99.3 232.4,97.7 231.6,96.5 227.3,96 225.6,97.6 225.6,95.9 223.1,95.4 221.3,95.5 218.4,96.4 216.8,97.3 213.4,97.6 
				211.3,97.6 209.9,97.8 208.3,98.5 207.1,98.7 205.6,100 204.9,101.7 204.7,105.9 204.1,108.3 203.6,110.1 202.8,111.3 202.4,113.1 
				201.7,114.8 200.2,116.3 198.1,117.2 198.4,119.9 195.9,121.3 194.6,121.5 193.8,121.3 193.2,120.6 193.2,119.7 191.9,119.4 
				189.6,119.4 188.9,119.8 187.4,120.1 185.6,121.2 183.9,121.5 182.8,121.3 178.8,122 175.6,123.9 173.8,123.9 171.9,122.5 
				170.4,120.4 169.8,120 169.8,120.2 167.4,120.1 165.9,119.4 164.8,119 163.9,118.5 163.2,117.5 162.7,117.2 161.9,116.1 
				161.4,115.8 161.1,115.8 160.9,115.6 160.7,114.3 158.4,109.2 157.8,108.1 156.9,107.4 155.2,105.6 154.4,104.7 152.6,101.2 
				151.9,99.7 152.2,99.2 152.9,98.2 152.9,97.3 152.7,96.8 150.1,93.8 148.9,91.1 148.9,89.6 149.6,86.2 149.6,81.1 149.4,79.3 
				149.1,77.9 148.6,77.2 148.4,74.7 148.4,71.3 148.1,70.8 148.1,70.3 148.9,68.3 149.7,67.6 149.9,66 149.9,64.8 150.4,64.3 
				152.2,64.7 152.7,64.5 153.6,63.4 153.9,62.7 153.9,61.2 153.9,61.1 153.6,62 153.2,62.5 152.9,62.7 152.7,62.5 152.8,61.8 
				153.6,60.1 151.1,59.9 148.4,58.6 144.7,58.3 142.7,57.1 140.6,56.9 138.2,55.3 135.9,51.8 133.7,44.9 131.2,43.3 130.1,40.7 
				127.8,38.2 124.9,31.7 123.4,30.6 122.6,29.1 120.2,26.9 119.1,25.5 112.8,25.8 111.2,25.4 109.7,25.9 109.2,26.5 105.9,33 
				102.7,32.5 99.2,30.2 96.7,27.9 94.6,25.3 92.4,18.2 88.4,15.4 85.7,13 84.4,11.2 82.7,10.4 81.6,8.6 80.9,8.3 79.6,7.4 66.8,7.6 
				66.8,11.8 60.6,11.8 45.8,11.8 16.3,0.9 16.1,0 -1.3,1.4 -0.3,2.3 -0.2,3.5 0.4,4.3 0.9,5.6 1.7,6.6 1.8,7.2 2.4,7.3 2.8,7.9 
				3,10.2 5.4,13.9 5.8,14.8 5.8,15.5 6.1,15.7 6.3,16.4 6.8,16.8 7.8,19.2 7.8,20.6 7.9,20.9 8.4,20.5 8.9,20.7 9.2,21.8 9.7,24.8 
				10.2,26.3 10.7,26.8 12,27.3 12.6,27.7 13.4,28 14.5,29.4 15.8,29.5 16.5,30.4 17.3,32.1 17.9,32.3 19.4,34.1 19.9,34.3 22.8,37.8 
				23,38.9 22.9,40 23.2,40.4 23,40.9 23.1,41.7 22.4,41.8 22,42.3 22,43 22.2,43.2 22.1,43.9 21.2,43.2 18.8,44 15.7,43.6 15.7,44.7 
				16,44.9 16.5,44.5 18.1,46 19.4,46.5 19.8,47 20.2,48.7 20.8,49 22.1,49.1 22.8,49.5 23.2,50.4 23.9,50.6 25.1,51.1 25.8,52.1 
				26.8,52.6 28.7,52.9 30.1,51.1 30.4,51.8 30.2,52.8 30.8,53.7 32.5,56 34.2,57.1 35.2,57.5 35.9,57.5 36.5,58.4 37.8,61.5 
				38.1,62.5 38.2,65.8 37.6,68.2 37.2,69.2 37.2,70.1 37.5,69.4 37.8,69.2 38.2,70 39.1,69.9 39.9,70.3 40,71.2 40.4,71.6 41.8,71.9 
				41.8,72.6 43.2,73.9 45.9,75.4 46.7,76.4 49.7,79 50.7,79.3 52.3,81.2 53.6,84.6 54.3,85.6 55,85.7 55.8,84.9 57.1,84.3 57.8,83.3 
				58.4,82.4 58.6,81.3 58.3,80.3 57.4,79.6 56.9,78.5 55.8,77.1 55.7,76.4 53.9,75.1 52.6,74 51.3,75 50.6,74.6 49.8,73.9 48.8,72.2 
				48.6,71.5 48.7,69 47.6,66.9 47,66.2 46.9,66.2 46.9,63.7 45.7,62.8 45.4,62.2 44.6,61.2 44.2,59.9 43.9,57.1 43.4,56.8 43.3,55.7 
				42.3,53.5 41,52.3 40.3,52.3 40.5,54 41,54.6 40.9,54.6 40,53.7 39.9,52.8 39.3,51.8 38.9,50.8 38.9,50.4 38.5,49.9 37.3,49.2 
				37.2,48 36.4,46.5 33.6,43.5 33.2,41.9 33.1,40.7 32.7,39.7 32.5,38.8 32.3,38.7 32.1,38.2 31.7,38 31.3,38.2 31.1,37.9 30.4,36.8 
				29.7,34.6 29.4,34.9 29,34.7 28.2,34 27.5,34.2 26.5,32.2 26.2,30.6 23.4,28.4 22.7,27.6 22.4,26.9 22,26.7 20.9,26.6 20.7,25.8 
				20.2,24.8 19.6,23.7 19,23.1 18.4,20.1 18.3,17.4 18.1,16.1 17.3,15.1 17,14 16.7,11.3 16.7,10 16.9,9 16.6,7.7 17.9,8.3 18.4,8.4 
				19.7,9.6 20,9.4 20.2,10 21.5,10.7 22.4,11 24.5,10.6 25.7,11.4 25.7,12.3 26.4,12.6 29,13.3 29.7,13.9 30.2,14.6 30.3,17.5 
				30.5,18.3 31.7,20.3 31.8,21.3 32.2,21.7 32.7,22.6 33.1,24.9 33.7,25.9 34.2,25.9 34.7,26.5 35.2,29.2 35.5,29.9 35.9,30.3 
				36.7,30.8 37.2,31.6 37.9,33.6 39,34.6 39.7,36.2 41,37.7 42.5,38.4 44.4,41 45.9,42.2 48.3,42.6 49.1,42.9 49.6,43.2 49.6,45.1 
				49.4,45.9 49.6,46.5 49.7,47.2 50.5,48.2 51.6,49.1 53.8,50.2 54.8,51.4 55.4,52.7 56,53.1 56.2,53.5 58.2,53.4 59.2,54.1 
				59.9,55.7 60.7,56.1 60.7,56.7 59.9,58.1 59.4,58.4 59.2,59.4 59,60.1 58.9,61.1 59.1,61.8 59.8,62.8 61.5,62.8 62.6,62 62.9,62.5 
				62.4,63.8 62.7,64.3 66.1,66.1 66.9,66.2 67.6,65.8 69.4,67.8 70.2,68.3 70.4,69.4 70.4,70.9 70.7,71.5 75.4,75.5 76.9,76.9 
				77.9,77.4 78.4,78.1 78.8,78.8 78.8,79.7 79.2,80.1 80.2,80.2 80.9,81.5 81.8,83.2 82.4,83.9 83.9,85.2 85.7,87.2 87.8,88.5 
				87.9,89.5 87.9,89.7 87.9,90.4 88.9,93.8 89.8,94.9 89.9,96.2 90.8,97 91.4,98 91.7,99.2 91.7,100.5 91.4,101.2 89.9,102.7 
				89.9,103 89.9,103.4 91.1,104.3 91.2,104.9 89.1,105.7 88.4,106.2 88.2,106.7 88.7,107.1 89.4,108.9 89.7,110 90.1,111.3 91.4,112 
				93.4,114.5 94.2,115.1 95.2,116 95.9,116.4 97.4,116.8 98.4,116.8 99.1,117.2 100.8,118.7 101.9,119.2 103.1,120.2 103.2,120.8 
				103.2,121.2 103.9,121.3 104.2,121.7 104.4,122.2 104.7,122.9 105.4,123.4 107.4,123.7 108.2,124.2 108.9,124.2 109.2,124.7 
				113.9,126.1 114.7,126.3 115.8,126.4 116.4,126.1 116.9,126.3 118.9,127.4 119.4,128.4 119.6,128.8 120.2,129 120.7,129.4 
				121.6,129.9 122.4,130.6 123.6,131 123.7,131.6 124.6,132.3 125.1,132.1 125.9,132.7 126.7,132.9 128.2,133.4 129.4,133.6 
				131.9,134.5 133.4,135.4 134.4,135.9 137.9,136.9 140.1,137.7 141.2,137.5 142.8,138.9 146.1,140.9 146.9,141.1 147.4,141.1 
				148.2,141.7 148.9,142 150.6,142.5 154.4,142.9 155.9,143.7 156.9,144 157.4,144 158.4,144.6 160.4,144.7 161.4,144.6 162.2,144.2 
				162.9,143.7 164.4,143 165.7,142.7 168.6,140.7 169.4,140.4 170.4,140.1 172.9,140.1 174.6,140.6 177.7,141.8 181.1,143.5 
				183.7,146 184.8,147.2 187.6,149.9 188.7,151.2 191.2,152.9 191.2,152.9 191.1,152.8 	"
        />
        <polygon
          class="st1"
          points="262.4,201.7 263.3,200.5 263.6,199.9 263.4,198.2 263.8,197.5 264.2,196.9 264.3,196.7 264.1,196.4 
				262.8,195.7 263.1,193.1 264.2,191.6 265.2,191.6 265.5,191.7 265.1,191.3 263.1,190.2 260.4,187.5 259.3,185.9 258.3,183.7 
				258,183.3 257.7,182.2 257.5,182.2 257.5,181.9 257.3,181.1 256.2,179.4 256.1,177.7 256.9,176.4 257.1,175.1 257.4,171.5 
				258.2,166 258.2,161.1 260.1,155.5 260.4,152.8 260.4,149.6 260.4,149.6 260.4,150.1 258.9,150 257.2,150.8 256.6,151.5 
				255.2,152.2 253.9,152.2 252.8,153 250.1,152.9 249.4,152.4 249.1,151.9 248.8,151.8 248.8,151.9 248.3,152.4 247.8,152.6 
				247.3,153.1 246.9,155.2 246.3,155.8 244.9,156 244.3,156.5 244.2,157 243.7,157.2 242.9,158 242.2,158.3 242.2,158.4 241.3,158.8 
				240.1,158.9 238.9,157.9 238.4,157.7 237.8,158.7 237.2,160.2 234.3,160.4 234.1,160.3 233.8,160.9 234.1,163.3 233.3,163.4 
				232.7,164.1 232.3,165 231.4,165.7 228.9,165.8 228.8,165.3 228.8,165.3 227.4,165.3 227.3,166.1 227.4,166.5 229.6,168.7 
				230.2,169.5 233.3,172.4 235.3,175.1 236.6,176.3 237.3,176.9 238.1,177.3 240.6,179.4 240.9,180.2 240.9,180.2 241.9,179.4 
				242.8,179.4 244.3,180.2 247.4,181 248.6,180.8 249.8,180.5 250.7,180.5 252.1,181.3 253.3,182.2 253.8,182.4 255.1,182.3 
				256.8,181.9 257.3,181.7 257.7,182.7 257.1,182.9 255.2,183.3 253.7,183.4 252.8,183 251.6,182.1 250.4,181.5 249.8,181.5 
				248.8,181.9 247.3,182 243.9,181.1 242.6,180.4 242.3,180.4 241.6,181.1 241.1,180.6 241.1,180.7 241.2,180.7 240.9,180.8 
				240.6,181.7 240.6,182 241.2,182.8 241.1,184.5 240.7,185 240.3,185.7 240.8,187.4 241.3,188.4 241.9,189.3 242.8,190 243.7,190 
				245.1,190.7 245.9,191.5 246.6,191.8 246.8,191.8 247.4,190.3 247.3,189.7 246.2,189.1 245.8,188.6 246.8,188.2 247.6,188.4 
				248.7,189.4 249.6,190.8 250.1,191.7 251.2,192.9 251.6,192.9 255.3,194.7 257.1,196.1 257.5,200 257.7,200.3 258.7,200.9 
				260,201.2 260.3,201 259.6,199.5 260.1,199.1 260.4,199.2 260.9,199.5 261.7,200.4 262.2,201.9 262.6,202 262.6,202 262.6,201.9 	
				"
        />
        <polygon
          class="st1"
          points="349.2,176.4 348.5,177 347.8,178.5 347.2,179.1 343.8,182.5 341.9,185.5 340.2,192 339,194 
				338.5,195.1 341.3,195.1 341.7,195.6 342.2,196.7 342.7,199.3 342.9,199.8 344,201 344.1,201.3 344.8,201.8 345.3,202.9 
				345.6,204.2 345.6,208.8 347.4,209.6 347.9,210.1 348.5,211 350.6,211.8 352.6,212 353.9,211.8 356.5,211.1 359.1,211 361,211.7 
				361.7,212.1 363.3,212.4 363.9,212.8 364.1,213.3 368,218.8 368.1,218.8 368.5,219.1 369.6,219.2 370.2,219.1 370.4,218.9 
				371.9,218.3 373,218.7 373.5,219 374.2,219.2 375.4,219.4 376.3,219.4 377.7,219.1 380.6,217.9 382.2,217.8 382.8,218 383.4,218.5 
				383.9,220.1 383.9,220.9 382.9,221.9 382.6,222.3 382.6,224.3 381.4,225.8 381.3,227.2 380.9,228.8 380.4,229.5 380.4,231 
				381.1,231.9 381.1,233.2 381.4,234.2 381.5,235.5 381.6,235.6 382.7,236.4 383.8,237.6 384.4,238.9 384.9,239.5 385.1,240.8 
				384.9,241.5 384.3,241.8 383.8,242.4 383.5,242.5 382.8,243.5 381.2,244.9 383.6,246.8 384.8,248.5 387,253.6 387.4,255.2 
				388.1,256.3 387.7,256.6 387.7,256.6 388.1,256.3 388.9,257.5 389.2,258.1 389.6,258.6 390.1,259.1 391.7,260.3 392,260.3 
				392.3,260.4 394.5,260.4 397.1,259.9 397.3,259.8 398.3,258.8 399.1,258.2 400.3,257.8 401.4,257.1 401.8,257.1 403.6,256.5 
				404.5,256.5 404.9,255.9 405.3,255.6 406.3,255.2 407,255.2 407.6,254.9 408,254.6 408.7,252.5 409.7,251.3 410.9,251.2 
				411.5,250.9 411.7,250.6 412.8,250 414.3,249.7 414.5,249.4 414.5,248.8 411.3,249 410.9,248.8 408.6,248.5 408.1,247.3 408,246 
				407.3,243.6 407.1,242.7 407.2,241.7 407.7,239.9 407.7,238.4 407.6,238.2 406,237.3 404.4,236.6 403.6,235.1 403.4,233.4 
				403.9,233 404.8,233 405.8,233.3 409,233.8 412.1,235.4 414.6,236.2 417.7,236.3 417.8,236.3 418.2,235.7 421,234.7 423.2,234.3 
				427.3,233.9 427.7,233.6 428.7,232.4 430,231.8 430.9,231.3 433.3,231.1 433.6,230.9 434.6,230 435.9,228.4 435.9,226.7 
				435.9,226.7 435.7,226.5 434.7,225.4 434.2,224.5 432.7,222.9 430.9,220.6 430.7,219.9 430.9,219 431.2,218.9 431.9,217.9 
				432.4,217.1 432.4,215 432.6,214.4 435.2,213.4 436.7,212.5 437,212.1 436.6,211.8 435.8,212 434.6,211.7 434.2,209.6 434.2,208.3 
				434.6,207.3 435.1,206.7 436.7,206.4 436.9,206.1 437.7,205.4 439.9,204.5 440.3,204.1 441.4,203.5 442.1,203.5 442.2,203.5 
				442.2,202.5 442.7,202.5 442.7,202.5 442.5,202 441.9,201.4 440.5,200.5 439.6,200.2 438.3,200.4 437.2,201.4 436.7,201.6 
				435.9,201.6 435.1,201.3 433.7,201.1 433.4,200.9 433.4,200.5 434.7,200 435.1,199.6 435.1,199.1 435,196.9 434.7,196.2 
				433.6,194.2 432.6,193.3 431.4,193 427.9,191 425.4,190.5 423.1,190.8 422.5,190.2 422.4,189.1 421.4,188.4 421.1,187.6 420.2,187 
				419.6,186.9 418.3,186.4 418.2,185.8 418.8,185.3 420.7,185 422.5,185.2 423.2,184.9 423.4,184.6 425.1,184.3 425.4,184 425.3,184 
				423.6,183.5 411.3,183.5 409.2,183.7 408.3,184.2 408.3,184.3 409.1,184.6 412,184.7 412.2,185.1 411.6,185.4 410.1,185.7 
				407.7,185.7 406.1,186.9 405.1,187.4 401.4,188 398.8,188 397.4,187.7 395.7,186.9 395,186.2 394.9,185.7 394.5,184.8 394,184.4 
				393.5,184.3 389.5,184.3 385.7,184.7 384.2,184.6 380.4,185.2 379,185.3 378.1,184.6 377.8,184.3 377.2,182.7 376.7,180.4 
				376.1,179.6 374.4,178.9 372.5,178.4 372,178.4 369.2,177.7 367,177.5 366.1,176 366,176 364.9,172.4 364.7,172.1 364,171.8 
				363.4,171.8 362.5,172.5 361.6,174.3 361.4,175.5 361.6,175.7 362,175.7 362.3,175.9 364.6,176.1 365.2,176.4 365.4,176.9 
				365.4,177.7 362.1,178 357.3,179.3 354.7,181.1 353.5,181.5 353.2,181.8 353,182.3 352.3,182.7 352.3,186.2 353.1,187.4 354,188.4 
				355.3,190.8 355.5,192.7 355.5,194.2 355.4,194.7 354.6,195.7 353.6,196.4 352.6,196.7 351.5,196.7 351.1,196.3 350.9,195.2 
				350,193.3 348.7,192.5 348.2,191.7 348,190.6 348,188.6 348.3,187.6 348.7,187.1 350.9,185.3 351.3,183.5 351.3,182.6 350.9,182.2 
				350.9,181.9 351.1,181.7 351.1,181 350,179.6 349.6,178.6 349.3,177.3 349.3,176.8 350.2,176.3 351.7,176 352.3,175.4 351.1,175.6 
					"
        />
        <polygon class="st1" points="376.2,741.8 376.2,741.8 376.2,742 	" />
        <polygon
          class="st1"
          points="487,237.1 487,237.3 487.1,237.4 487.9,238.7 487.6,240.8 487,244 486.6,246.6 486.5,247.1 
				484.6,248.9 484.6,248.9 506.8,235.3 506.6,235.3 506.6,233.8 505.1,232.3 503.6,231.4 503,230.6 501,228.8 500.5,228.6 
				498.5,227.2 496.5,226.1 495.1,225.9 493.3,225 491.5,224.4 489.5,223.1 489,223.4 488.6,223.8 487.6,224.1 486.3,235.8 	"
        />
        <polygon
          class="st1"
          points="490.6,542.3 491,540.2 491,537.9 491.5,536.7 492.1,535.6 492.4,535.3 492.9,534.3 493.5,534.1 
				493.1,533.3 490,531.2 489.6,530.4 489.1,528 488.6,527.2 486.8,526.3 486.1,526.1 484.6,525.4 483.8,524.7 482.9,523 482.6,522.7 
				480.9,522.4 479.8,521.3 478.8,521.3 478.1,521.1 477.5,520.6 476.5,519.3 475.8,517.9 475.6,517.4 475.3,517.2 473.6,518.7 
				473,518.8 471.3,518.7 471,517.1 470.3,515.1 469.3,514 467.6,512.7 467.1,511.9 465.3,510.6 464.6,510.3 464.1,510.3 463.6,510.6 
				463.1,511.5 461.9,512.1 459.6,511.8 459.4,512.4 458.1,513.6 458.1,513.7 458.2,517.1 457.7,520.1 457.7,522.3 457.4,523.8 
				456.1,527.2 456.1,534.2 455.9,535.7 455.6,536.8 454.4,538.5 454,539.8 453.4,543.2 453.3,545.5 453.9,545.7 456.4,548.5 
				456.9,549.6 460.7,550 462.1,550 465.6,551.8 467.6,552.6 469,553 470.1,553.6 471.1,553.7 473.8,553.2 475.6,553.2 478.6,553.8 
				480.6,554.4 482.9,553.5 484.6,552.7 486.1,551.5 487.5,550 488.1,549.6 488.5,548.5 489.6,547.3 490.6,545.6 491.1,543.9 
				491.6,543.5 490.9,542.8 	"
        />
        <polygon
          class="st1"
          points="464.1,222.7 463.5,223.5 462.4,224.9 462.4,225 462.8,225 462.8,226.8 461.9,227.6 461.8,228.4 
				460.3,228.7 459.1,228.7 458.2,229 457.9,229.2 457.7,229.4 457.6,229.7 457.5,231.8 456.9,233.1 456.3,234.6 456.3,235 
				457.1,235.9 458.3,237.8 458.7,238 460,239.2 460.1,239.8 460.3,240.2 460.5,240.3 462.1,240.4 462.8,241 463,242.2 463.6,242.8 
				463.8,244.2 464.5,245.1 464.8,246 465.6,247.4 467.4,249.6 468.6,250.7 469.1,250.7 469.1,251.1 470.6,251.7 472.8,251.7 
				472.9,251.5 472.8,248 474,247.4 475.4,247.4 476.6,247.8 477,247.8 480.3,246.8 483.4,248 483.9,248.2 484.6,248.4 486.3,235.8 
				485.9,235.2 485.3,233.8 485,231.1 485,228 485.6,226.5 486.6,225.7 487.3,224.8 487.3,224.5 487.6,224.1 487.6,222.6 487.6,222.4 
				484.1,221.4 480.6,221 479.6,221 479.1,221.3 477.6,221.4 475.5,221.2 472.1,221.9 469.1,221.9 466,221 465.3,221 464.5,221.7 
				464.1,221.9 464.6,222.1 	"
        />
        <polygon
          class="st1"
          points="634.6,315.3 633.6,310.8 632.5,308.7 631,307 629.8,306.4 623.2,306.3 622.5,306.4 621.5,306.1 
				621,305.7 619.8,305.3 618.8,305.3 618,304.3 616.8,303.1 615.8,302.5 614.6,302 612.8,300.8 608.5,296.6 606.8,295 604.6,293.5 
				602,291.9 600.2,290.7 598.5,289.9 596.8,289.2 589.5,289.1 588.2,289.4 587,289.4 582.8,289.1 581.5,288.9 578.6,288.3 
				571.1,285.8 569.8,285.9 569.3,285.8 568.6,284.7 567.5,285.3 566.3,286.1 565.1,286.7 563,287.6 561.8,287.7 561.5,287 562,286 
				562.8,285.1 562.8,284.4 562.5,283.7 561.8,283.4 561.3,283.3 560.8,283.6 560.5,283.6 560.1,283.4 560.1,282.9 560.8,282.6 
				561,281.1 559.8,279.5 558.3,278.2 557.5,278.3 556.8,278.6 556,279.5 555,279.9 555,278.6 554.8,277.8 553.8,276.6 552,276.2 
				550.6,275.4 549.2,275.3 548.3,274.5 546.2,274.5 545.6,274.3 541.1,271.9 539.5,271.5 535.3,271.6 534.1,272.2 533.1,274.3 
				532.3,274.7 531.8,275.9 531.3,277.1 530.8,278.1 529.5,278.2 528.2,278.6 527.8,278.9 527,279.6 526,282.4 525.5,283.4 
				525.1,283.6 524.8,284 524.5,285.5 524,285.7 523.8,285.7 523.6,284.7 523.6,283.1 524,281 523.8,280.9 522.5,280.9 521.5,281.3 
				520.5,281.1 517,281.3 516,281.1 515.8,281.3 514.8,281.3 514.5,281.1 513.6,281.1 511.6,281.8 510.8,281.8 509,281.1 508.4,281.1 
				508.1,281.3 507.8,282.6 507.6,282.8 507.3,282.6 507.1,281.5 507.3,281.1 508.1,280.3 509.1,280.3 510.3,280.8 512,281 
				513.2,280.5 513.8,280.1 513.8,279.8 513.8,276.6 513.8,276.3 512.8,276.3 512.5,275.8 511.8,275.3 511.6,275 511.6,274.6 
				512.3,274.3 513,273.7 513.1,273 512.2,273 511.9,273.2 511.4,273.2 510.6,274.5 510.1,275.5 508.8,275.6 508.6,275.2 508.4,274.9 
				508.6,272.6 508.1,271.2 508.1,270 508.1,269.6 509.1,268.7 509.6,267.7 511.1,266.2 513,264.2 515.5,262.3 516.5,260.5 
				517.2,260.1 518.2,259.2 518.5,257.9 518.5,257.2 519.5,254.5 519.5,254 519,253.5 517.8,253.5 515.6,252.8 514.8,251.1 
				513.8,247.9 513.6,247.6 513.2,245.9 512.6,244.7 512.1,242.7 511.6,241.2 511.1,239.2 511.1,237.8 510.1,236.1 508.9,234.4 
				508.1,233.9 507.8,234 507.4,235.1 506.8,235.3 504.9,237.1 504.5,238.2 502.6,240.8 501.1,242.6 500.6,243.4 499.6,246.6 
				495.6,250 495.3,250.4 494.6,250.7 493.1,250.7 492.1,250.3 491.3,250.3 490.8,250.4 488.6,251.4 487.6,251.4 487.3,251.3 
				485.9,250.5 484.6,248.9 484.5,249.4 483.4,249.1 482.9,248.9 480.1,247.9 477.1,248.8 476.4,248.8 475.1,248.4 474.3,248.4 
				473.9,248.6 473.9,251.9 473.1,252.7 470.5,252.7 468.6,252.1 467.3,252.3 465.1,252.5 464.1,252 464,251.8 463.6,251.7 
				463.1,251.8 461.8,252.5 460.5,254 458.4,254 457.4,254.5 456.6,255.2 454.9,255.2 454,255.6 453.1,256.8 452.1,257.3 449.9,257.3 
				448.5,256.8 446.2,255.4 445.3,254.6 444.8,253.9 444.5,253.7 443.7,252.9 443.4,252.3 443.5,250.4 443.5,249.5 441.9,248.1 
				441.7,247.3 441.8,242.8 442.6,240.9 442.8,239.9 442.8,238.3 444.4,236.5 444.5,236.3 444.4,235.5 443.6,234.6 443.6,232.7 
				442.4,232.3 442.1,232.1 440.3,231.8 440.9,229.4 441,228.6 440.9,227.8 440.5,226.9 439.6,226.9 437.9,227.5 436.9,227.3 
				436.9,228.7 435.3,230.8 434.2,231.8 433.5,232.1 431.3,232.3 430.4,232.7 429.3,233.2 428.4,234.4 427.6,234.9 423.4,235.3 
				421.3,235.7 418.9,236.5 418.4,237.2 417.9,237.3 414.3,237.2 411.6,236.4 408.7,234.8 405.5,234.3 404.6,234 404.5,234 
				404.6,234.8 405.1,235.8 406.4,236.4 408.3,237.5 408.8,238.1 408.7,240.1 408.2,241.9 408.2,242.6 408.3,243.4 409,245.9 
				409.1,247.1 409.3,247.5 411.2,247.8 411.5,248 415.5,247.8 415.5,249.6 415.3,250 414.9,250.6 413.2,251 412.4,251.4 412.2,251.7 
				411.1,252.2 410.2,252.3 409.6,253.1 408.9,255.1 408.2,255.8 407.1,256.2 406.6,256.2 405.6,256.7 405.1,257.4 403.8,257.6 
				402,258.2 401.7,258.2 400.7,258.8 399.5,259.2 398.9,259.6 397.9,260.7 397.3,260.9 394.6,261.5 392.1,261.5 391.8,261.3 
				391.4,261.3 389.5,259.9 388.8,259.3 388.3,258.6 388,257.9 387.7,257.5 385.6,258.4 384.7,253.8 383.6,252.3 383.1,251.8 
				381.9,251.8 379.3,253.2 378.5,253.2 377.9,252.2 377.6,251.9 377.4,252.2 377.2,253.4 376.4,254 365.2,254 365.2,257.4 
				365.3,257.4 365.3,257.9 365.6,258 366,258.5 366.7,258.5 367,258.3 368.8,258.1 369.8,259.3 370,260.6 370.6,262.1 370.2,262.6 
				368.9,262.9 368.7,262.5 368.4,262.3 367.1,262.1 366.2,262.5 363.9,262.9 363.5,263.1 363.5,267.8 364,268.4 365.8,269.9 
				366.7,270.9 367.5,272.4 368.5,276.5 368.4,277.4 368.2,278.7 366.7,287.8 366.5,288 366,290.5 365.6,291.5 365.4,293.3 
				365.2,293.8 365,294.8 364.8,296.9 364.3,297.8 364.2,298.8 363.7,298.7 364.1,298.9 364,299.3 362.7,300.1 359.8,299.8 358,300.5 
				350.8,302.6 348.3,303.7 346.1,305.1 344.7,306.3 342.7,308.5 341.6,310.2 340.4,312.4 340.1,313.1 340.1,317.7 338.4,317.9 
				337.2,318.2 336.9,318.7 336.2,321.2 334.4,324.7 334.4,325 336.1,328.2 336.3,330 336.5,330.3 337.7,331.9 338.2,332.9 
				339.9,334.7 341.5,335.7 341.6,337.3 340.8,338.7 340.8,338.8 342.2,339.1 345.1,339.3 346,339.9 346.6,340.9 347,342.3 
				347.2,342.7 348.9,343.2 350.9,343.3 352,343.1 354.3,341.9 357.3,339.5 358.8,338.1 359.8,337.6 360.1,338.7 360.1,346.6 360,347 
				360,350.9 360.2,351.3 360.9,351.6 361.3,351.6 362,351.5 363.5,350.8 365.7,350.6 366,350.3 367.5,350.3 370,351.2 375.6,351.4 
				376.6,350.9 377.5,350.3 381.1,349.1 382,348.6 385.4,345.5 387.9,342.7 388.8,341.9 391.2,341.2 392.1,341.2 395.9,340.7 
				398.8,340.8 399.6,341.5 400.3,342.6 400.7,345.2 400.6,349.8 400.4,351.4 399.7,352.3 399.6,352.8 399.5,353.7 400.3,356.9 
				401.3,358.5 402.3,359.4 406.1,362.1 407.8,362.6 410.1,362.9 413,362.9 415.3,363.5 416.3,364.5 416.6,365.1 417.6,366.1 
				418.5,366.6 424,367.9 426.2,370.1 426.9,370.5 428,370.5 429.5,370.2 430.2,370.2 432.1,370.5 435.1,372.3 436.1,373.1 
				437.1,374.7 437.9,376.9 438,378.9 438.4,380 439,380.9 439,382.3 438.6,385.1 437.6,385.2 437.6,385.3 437.6,386 438.6,388.2 
				439.5,391.3 440.3,392.7 442.2,392.8 443.6,393 445.5,393.1 446.3,393.3 452.5,393.4 453.2,393.6 453.8,394.1 454.1,395 
				453.8,396.5 453.4,397.7 453.4,398.4 454,400.9 456.3,401.7 456.8,402.2 457.5,404.4 459,407.1 459.4,408.5 459.4,411.8 
				459.1,416.2 459,416.9 458.2,418.4 457.5,419.9 457.4,420.6 457.4,421.5 457.7,422.3 457.8,424.1 457.5,424.6 456.4,425 
				456.1,425.5 456.1,425.7 457.3,427.5 457.4,428.1 457.5,434.7 457.4,439.4 457.9,440.3 458.4,440.6 460.6,441.1 461.6,441.6 
				463.9,442 465.5,442 467.3,441.9 467.8,441.4 469.3,440.9 470.4,441.6 473.6,442.7 474.6,444.3 475,445.2 475.4,448.4 475.8,450.2 
				475.6,453.7 475.9,454.3 476.5,455.2 477.4,455.7 478.1,456 479.5,456.1 480.1,455.7 482,455.6 483.6,455.3 484.6,456.1 
				485.4,457.7 485.4,461.3 485.1,461.9 484.6,464.2 484.1,467.8 484.1,469.9 484.6,469.9 485.1,469.7 487.6,469.7 488.6,471 
				490.5,476.9 490.5,481.4 490.1,483.1 489.8,483.9 489.4,484.3 486,486.7 484.8,487.1 483.1,487.3 482.1,487.7 481,489.4 
				479.6,490.8 473.5,496 473.3,496.6 472.6,497.7 469.9,501.1 469.6,502 466.9,505 464.8,506.7 463.6,508.1 462.6,508.8 462.1,509.6 
				460.8,510.9 460.8,510.9 461.6,511 462.5,510.7 463,509.9 463.9,509.3 464.8,509.3 465.9,509.8 467.9,511.2 468.4,512 470,513.3 
				471.1,514.6 472,516.8 472.1,517.7 472.9,517.8 473.1,517.7 475.3,515.9 476.5,516.8 476.6,517.5 477.4,518.8 478.3,519.9 
				478.6,520.1 479,520.3 480.1,520.3 481.4,521.5 483.1,521.8 483.6,522.3 484.6,524 485.3,524.6 486.5,525.2 487.3,525.3 
				489.4,526.4 490.1,527.6 490.6,530.1 490.8,530.6 493.9,532.6 494.6,534 494.4,534.9 493.6,535.2 493.3,536 492.9,536.3 
				492.4,537.2 492,538.1 492,540.3 491.6,542.2 491.6,542.2 492,542.6 492.5,541.9 493.6,541.3 495.8,539.6 497.3,536.9 498.9,535.1 
				499.8,531.3 500.5,530.2 502.6,527.9 503.4,527.5 506.6,525.1 507.9,524.4 509.1,523.6 509.9,522.7 510.1,521.6 511.1,520.3 
				513.1,517.4 514.1,515.6 515.8,513.6 516.5,510.5 518.1,506.6 519,505.3 519.6,503.9 520.2,503 521.1,502.2 523.2,499.2 
				524.1,498.3 525.2,497.7 526.8,497.2 527.5,495.3 528.5,494.3 529,491.2 529.3,485.7 529.8,485.1 530.5,485 530.6,484.5 
				530.3,483.9 529.3,482.7 529,482.3 529.2,480.2 528.8,479.8 528.8,477.7 528.3,476.4 528.5,475.4 528.8,475 529,474 529.2,472.7 
				530.5,470.4 531.2,469.4 533.1,467.6 536,464.5 536.2,464.5 537,464 538.2,462.7 540.2,461.3 541,460.5 542.3,459.6 544.1,457.8 
				546.1,456.5 547.5,456.5 548.2,456.2 549,455.1 550.8,454.7 553.1,454.6 554.6,453.4 555.1,453.2 556.3,451.7 557,451.4 558.8,451 
				560,451 560.2,450.5 560.1,450 559.8,449.6 559.8,449.2 560.8,448.2 561.2,448.2 561.2,448.1 561.3,448.5 562.3,448.8 565.5,447.8 
				566.2,447.8 566.8,448.2 568.1,448.3 570.5,447.5 571.5,447.5 572.3,447.8 574,448.1 575.5,448.1 578.8,447.5 579.3,447.3 
				580,446.1 580.3,444.4 581.5,443.3 582.6,442.6 585.5,441.4 586.6,440.2 587.1,439.1 587.3,438.1 587.5,436.1 587.8,434.1 
				589.5,431.7 592,428.8 593.3,426.6 594.8,422.7 596.8,420.6 597.5,419.4 597.8,415.2 597.8,412.8 598.1,410.8 598.6,408.9 
				599.3,407.7 601.2,405.6 601.6,404.6 601.6,402.3 602.3,396.9 602.6,396 603,392.3 603.6,389.9 603.5,388.1 603.1,386.7 603,383.5 
				602.8,382.1 602.8,379 603,375.1 603.5,372.6 603.6,370.9 604,369 604.2,368.6 605.6,367.2 606.2,367.1 607.5,367.5 607.8,367.4 
				610,365.6 611.5,363.5 613.1,360.3 614,357.7 615,355.6 616.2,353.9 618.5,352.1 619.2,351 620.6,349.6 623.6,348 624.5,347 
				625.6,345.2 627.3,343 630,340.1 632.5,336.5 633,336.1 633.6,334.8 633.8,334.8 635.1,328.7 635.5,326.7 635.8,321.5 635.8,318.3 
					"
        />
        <polygon
          class="st1"
          points="452.4,543.1 453,539.5 453.5,538 454.7,536.3 454.9,535.5 455.1,534.1 455.1,527 456.4,523.5 
				456.6,522.2 456.7,519.9 457.2,517 457,513.7 457.2,513 458.5,511.9 458.7,511.2 459.2,511.3 458.9,510.9 460.2,510.1 461.4,508.9 
				461.8,508.1 463,507.3 464.1,505.9 466.1,504.3 468.6,501.5 469,500.6 471.8,497.1 472.4,496.1 472.6,495.4 479,490 480.1,488.7 
				481.6,486.9 482.9,486.3 484.6,486.1 485.6,485.8 488.8,483.5 489,483.3 489.1,482.7 489.5,481.3 489.5,476.9 487.8,471.5 
				487.1,470.7 485.3,470.7 484.8,470.9 484,471 483.5,474.8 483.1,477.1 482.5,478.6 481.6,479.8 480.4,481.4 478.5,482.8 
				478.1,483.3 477.4,483.6 475.6,485.1 475.3,485.7 474.6,486 473.4,486.3 471.5,486.1 470.6,486.5 470,487.2 468.8,487.6 
				467.6,487.5 466.9,487.3 465.6,487.5 463.9,487 461.6,485.4 460.8,485.2 456.3,484.4 453,484.4 452.5,483.7 452.5,482.4 
				452.8,481.2 453.2,479.9 454.8,476.4 456.8,473.3 457.9,471.5 458,470.2 457.8,469.1 457.6,468.7 457.2,468.6 456.3,467.2 
				455.4,466.6 453.4,466 452.1,464.3 450,463.2 447.7,462.2 444.1,459.6 443.3,459.2 441.7,458.7 437.8,458.6 436.4,458.3 
				434.8,457.6 434,457.1 433.7,456.7 432.2,454.3 431.7,453.9 430,452.9 426.6,450.3 426.1,449.5 424.2,447.6 422.6,444.7 422,444.1 
				419.8,443.5 420,443 419.8,443.4 418.6,443 417.7,442.1 416.9,441.6 415.5,441.3 412.9,441.3 410.4,441 409.5,441 409.4,441 
				408.9,443.8 408.3,445.1 408.1,446.5 407.1,446.5 405.3,444.7 405.2,444.2 404.9,444.1 404.2,443.1 403.9,442.8 401.2,442.1 
				400.5,442.1 399.2,441.8 395.4,441.8 394.1,439.9 393.4,439.1 393.2,439.1 393,439.3 391.1,441.6 389.5,442.9 388.4,444 387.6,445 
				387.4,445.7 386.5,447.2 385.8,447.7 385.8,448 386.4,448.7 386.4,450.7 385.1,457.7 382.2,458.8 375.8,461.8 375.6,462.4 
				375.6,463.4 375.9,464.8 375.9,466.4 375.1,467.5 375,467.7 375,469.8 375.6,472 375.6,475.6 375.9,477.1 376.6,479.2 376.8,481.4 
				376.6,482.1 375.6,482.8 374.4,483.2 372.3,485.1 372.1,485.4 371,489 367.4,492.9 366.6,496.4 366,499.4 365.7,500.6 364.9,502.3 
				364.6,503.5 364.4,506.8 364.6,508.3 364.5,509.8 364,511.8 363.4,512.3 363.4,512.8 363.1,513.8 362.3,514.7 361.8,516.8 
				360.6,518.9 360.4,519.1 360,520.6 359.7,522.7 359.7,524.3 361,525.7 361,529.9 362.9,534.5 363,536.1 362.9,537.6 363.9,538.6 
				364.3,539.6 364.7,542.2 364.9,544.8 364.9,547.2 363,550.1 362.2,551.7 362,552.7 361.4,556.1 361,556.7 359.8,557.8 359.9,561.8 
				360.3,564.2 360.3,565.5 359.6,566.1 358.7,566.6 358.6,566.8 358.3,567.9 357.8,568.4 356.9,568.6 356.6,568.8 355.7,569.8 
				355.2,571.6 354.6,577.1 354.6,582.2 355.2,586.1 355.8,587.9 355.8,589.8 355.3,591.3 354,592.5 353.4,593.2 353.1,593.8 
				352.7,595.9 350.3,604.5 350.2,605.2 350.3,607.4 349.1,613.3 348.8,617.3 349.3,619.2 350.4,620.3 350.7,621.1 350.6,623.1 
				350.5,623.8 349.9,625 348.6,626.9 347.8,629.7 347.8,634.5 348.2,635.2 348.8,635.8 348.9,636.5 348.8,640.4 348.9,640.9 
				349.7,642.3 350.1,644.6 349.4,648.8 349.4,649 350.1,649 352.3,649.8 353.5,651.1 353.7,652.6 353.3,654.3 351,654.5 348.9,654.1 
				348.9,654.1 349.2,655.1 351,655.8 352.6,657.7 352.6,659.6 351.8,660.3 351.4,660.9 351.2,662.1 351.1,666 351,666.7 351,668.3 
				351.2,669.3 350.9,674.7 350.2,677 349.3,679 349.1,680.4 347.6,683.2 345.7,685.6 345.3,686.5 345.1,687.2 345.1,688.7 
				345.1,688.7 345.1,690.4 345.5,691.2 345.9,692.8 345.7,694.4 345.3,694.7 345.1,695.2 344.7,698.1 344.1,700 343.8,700.8 
				342.2,702.7 340.3,706.6 339.7,707.1 339,708.6 338,712.9 338,714.7 337.8,716.1 337.8,720.2 338,720.5 338.3,722.6 338.6,723.3 
				338.9,723.1 339.3,722.1 340.7,721.2 343.3,721.7 343.9,720.8 344.5,720.8 345.4,721.1 345.7,721.8 346.1,724.4 346.3,726.6 
				346.1,734.8 346.3,735.4 347.1,737 347.6,737.6 349.4,738.3 357.3,738.3 357.9,738.7 358.5,738.7 361.2,738.6 363.7,738.1 
				365.5,738.3 369,739.4 373.5,740.2 375.6,741.4 375.9,741.5 373.3,738.5 371.2,734.2 370.3,733.9 369.5,734.1 368.2,733 
				369.1,732.7 370.8,732.8 371.2,731.9 370.8,729.6 369.8,726.1 369,726.6 368.2,726.6 369.2,725.1 371,719 372.3,717.8 375.5,715.6 
				375.5,715 375.2,714.2 374.1,713.6 373.7,710.8 375.3,713.8 376.3,714.8 377.8,714.8 379.1,714.2 380.3,712.8 381,710.7 
				382.2,705.4 381,706.4 381.3,704.7 382.6,701.9 384.7,700.1 386.7,697.8 387.8,697.2 390.5,694.9 392.9,692.6 394,691.8 
				395.1,691.8 395.3,690.3 396.4,689.8 396.2,688.4 395.6,686.8 396.2,686.3 397.2,684.7 397.2,682.3 396.8,680.8 395.8,679.7 
				394.1,680 392.3,679.9 388.8,678.6 385.7,675.1 384.4,674 383.4,672.4 382.5,670.4 382.3,669.4 382.7,667.1 384.3,664.5 
				384.7,663.3 385.6,662.1 387.9,659.4 389.4,658.7 390.8,658.7 391.7,657.4 393.2,656.6 398,657 398.3,656.1 398.2,655.4 
				397.5,653.4 398.5,652 399.5,651.6 400.1,650.9 400.8,649.3 400.8,647 400.9,645.1 400.4,642.9 400.8,641.3 401.9,639.7 
				402.5,638.5 403.1,637.6 407.9,635 407.9,634.7 407.2,634.3 406,634 404.5,633.8 402.1,632.6 402.1,631.8 402.8,631.1 403.6,631 
				404.5,630.1 405.7,629.6 407.2,630.1 408,630.9 408.1,632.6 409.2,633.1 410.5,633.2 412,632.6 413,631.9 413.3,630.3 412.9,626.7 
				411.8,624.9 410.1,626.1 407.8,626.3 408.7,626.9 409.1,628.1 406.3,628.9 405.4,628.2 406,627.1 404.2,626.5 402.2,624.8 
				402,622.8 401.9,618.2 401.1,615.6 401.1,612.4 402.6,610.6 403.9,612.2 406,612.8 408.9,614.1 410.3,615.1 411.6,615.4 417,615.6 
				419.3,614.1 421.7,613.5 422.6,612.4 423.1,611.3 423.2,610.2 422.5,606.1 422.7,604 423.4,602 423.7,602.3 424.2,601.9 
				424.4,600.9 424.8,598.4 424.3,597.2 423.2,596.1 422.8,592.6 423.2,591.2 425,592.9 429.1,593.5 432.9,593.5 437.8,592.8 
				441,591.9 443.6,591.7 445.5,591.3 449,590.2 451.1,589.2 452.3,589.2 456,587.9 457.9,587 459.5,585.6 460.3,582.1 461.3,580.6 
				462.9,578.8 465.6,574.9 466.5,572.9 466.5,568.9 466.3,567.6 464.9,567.7 463.1,566.8 461.4,564.8 460.9,563 462.4,559.5 
				462.4,558.1 460.1,555.7 458.3,554.2 456.9,553.2 453.4,551.2 452.3,548.4 452.7,548.1 453,546.9 452.8,545.5 452.3,545.5 	"
        />
        <polygon
          class="st1"
          points="484.6,248.9 485.9,250.5 487.3,251.3 487.6,251.4 488.6,251.4 490.8,250.4 491.3,250.3 492.1,250.3 
				493.1,250.7 494.6,250.7 495.3,250.4 495.6,250 499.6,246.6 500.6,243.4 501.1,242.6 502.6,240.8 504.5,238.2 504.9,237.1 
				506.8,235.3 	"
        />
        <polygon
          class="st1"
          points="484.6,248.9 486.5,247.1 486.6,246.6 487,244 487.6,240.8 487.9,238.7 487.1,237.4 487,237.3 
				487,237.1 486.3,235.8 484.6,248.4 484.6,248.4 	"
        />
        <polygon
          class="st1"
          points="487.3,224.8 486.6,225.7 485.6,226.5 485,228 485,231.1 485.3,233.8 485.9,235.2 486.3,235.8 
				487.6,224.1 487.3,224.5 	"
        />
        <polygon
          class="st1"
          points="385.5,776.7 382.9,776.4 380.7,775.9 376.9,776.4 377.1,777.2 378.2,777.5 378.4,777.2 378.6,778.4 
				379,779.8 381.5,780.2 381.1,780.5 381.5,780.7 382.4,780.6 382.4,780.1 382.9,780.1 383.8,780.3 384,781.3 384.3,781.6 
				385.1,781.5 385.8,781.1 386.4,780.4 386.5,780 386.5,779.5 386,777.4 	"
        />
        <polygon
          class="st1"
          points="389.9,780.1 389.8,780.5 390.1,780.9 390.6,781.1 391.1,780.8 391.2,780.4 390.8,779.9 	"
        />
        <polygon
          class="st1"
          points="388,777.4 386.5,777.2 386.5,777.4 387.1,778 388.2,778.8 388.7,778.8 388.6,778.2 	"
        />
        <polygon
          class="st1"
          points="387.1,780.4 386.7,780.8 386.7,781.6 387.1,782 388.2,781.9 388.2,781.1 388,780.6 	"
        />
        <polygon
          class="st1"
          points="410.4,774 408.7,773.8 406.9,774.3 406.1,774 406,774.4 405.4,775.6 406.9,776 408,775.2 409.4,775.5 
				409.6,774.7 410.5,774.5 411.9,774.8 412.4,774.6 412.5,773.8 412.3,773.5 	"
        />
        <polygon class="st1" points="374,746.3 374,746.3 374,746.4 	" />
        <polygon
          class="st1"
          points="399.6,773 396,772.7 393.8,771.4 390.8,769 389.2,767.1 387.7,766.6 387.1,765.7 386.1,765.4 
				385.5,764.8 384.3,764.5 382.3,761.9 379.8,760.2 379.1,759.4 378.2,756.7 377.6,755.6 376.8,754.7 375.8,754.3 375.3,753.2 
				375.5,752.2 376.4,751.8 377.3,752.1 377.9,753 377.8,752.3 375.5,748.1 374.8,747 374,746.3 374.5,775.6 375.2,775.5 377.4,774.4 
				378.6,774.5 379.6,774.7 380.8,775.3 384.4,775.3 388.3,776 390.6,777.5 391.9,777.8 392.2,777.3 395.8,776 398.6,776.7 
				400.4,775.9 400.9,775.2 401.2,773.6 401.1,772.6 	"
        />
        <path class="st1" d="M374,746.3L374,746.3L374,746.3z" />
        <polygon
          class="st1"
          points="374.2,773.2 374.5,773.9 374.5,775.6 374,746.4 	"
        />
        <polygon class="st1" points="374,746.3 374,746.4 374.5,775.6 	" />
        <polygon
          class="st1"
          points="422.2,434.4 422,433.6 422,430 422.2,429.4 423.6,426.6 425.2,424.5 425.6,422.5 426,421.4 
				428.9,420.7 436.6,418.3 437.1,418.3 441.3,417.2 442,417.1 442.1,417.1 448.1,417.2 450.2,417.7 451.9,418.8 453.1,420.1 
				455.3,423.1 455.5,423.5 456,424 456.7,423.8 456.7,422.5 456.4,421.7 456.4,420.5 456.6,419.6 457.3,417.9 458.1,416.5 
				458.1,416.1 458.3,411.8 458.3,408.6 458.1,407.4 456.5,404.8 455.9,402.8 455.8,402.6 453.1,401.6 452.4,398.5 452.4,397.5 
				452.8,396.3 453,395.1 452.9,394.7 452.7,394.5 452.2,394.4 446.1,394.3 445.3,394.1 443.4,394.1 442,393.8 439.7,393.8 
				438.5,391.7 437.6,388.6 436.6,386.2 436.5,385.4 436.5,384.3 437.7,384.1 438,382.2 438,381.2 437.5,380.5 437,379.1 436.8,377 
				436.2,375.1 435.3,373.8 434.4,373.1 431.7,371.5 430.1,371.2 429.5,371.2 428.1,371.5 426.7,371.5 425.6,370.9 423.5,368.9 
				418.1,367.5 416.9,367 415.7,365.6 415.5,365.1 414.8,364.4 412.8,363.9 410,363.9 407.5,363.6 405.6,363.1 401.6,360.2 
				400.5,359.1 399.3,357.3 398.4,353.8 398.6,352.6 398.9,351.8 399.4,351 399.6,349.7 399.6,345.3 399.3,342.9 398.8,342.2 
				398.4,341.8 396,341.7 392.2,342.2 391.3,342.2 389.3,342.8 388.6,343.5 386.1,346.2 382.6,349.4 381.5,350.1 377.9,351.3 
				377.1,351.8 375.8,352.4 369.7,352.2 367.3,351.3 366.7,351.3 371.2,359.2 373.4,362.6 373.4,363.3 373,363.7 371.9,365 
				371.4,366.3 371.4,369.7 370.9,372.3 370.9,373.7 371.5,376.4 371.5,379.1 371.1,379.7 369.2,380.7 368.9,381.3 368.9,381.4 
				370.2,383.1 370.4,384.1 370.4,384.9 370,386.1 369.3,386.6 368.7,387.8 368.5,389.4 368.7,390.6 368.9,391.3 369.1,391.4 
				369.6,392.1 370.2,392.3 371.2,393.3 371.5,394 371.4,395.6 369.5,398.2 368,399.7 366.4,402.3 366.4,402.7 366.4,402.7 
				366.9,403.4 367.1,403.4 368.7,405.5 369.4,406.1 370.4,407.5 370.4,409.1 371,413.5 371.4,414.5 372,415.2 373.7,416.5 375,417.7 
				375.3,419.5 374.7,420.3 374.5,420.9 374.9,421.6 374.9,422.4 374.5,423 373.3,423.4 373,425 373,425.5 373.9,425.9 374.6,426.7 
				374.7,428.2 374.6,429.1 374.8,429.3 375.4,429.7 376.6,430.7 376.9,431.4 377.2,433.4 377.2,434.5 378,435.9 378.5,439.1 
				378.5,441.6 379.1,444.8 379.1,445.3 379.6,446.9 380.4,447.3 381.1,447.4 382.7,447.3 383.5,447 385,447 385.1,447 385.7,446.5 
				386.5,445.2 386.7,444.6 387.6,443.3 388.8,442.1 390.5,440.8 392.3,438.5 393,438 393.9,438.2 394.9,439.2 395.9,440.8 
				399.4,440.8 400.6,441.1 401.4,441.1 404.3,441.8 405.1,442.5 405.6,443.3 406,443.5 406.2,444.1 407.2,445.2 407.3,444.8 
				407.9,443.4 408.5,440.6 408.8,440 410.4,440 413,440.2 415.6,440.2 417.3,440.6 418.4,441.3 419.1,442.1 419.7,442.3 420.6,440.7 
				422.2,435.2 	"
        />
        <polygon
          class="st1"
          points="484.4,461.1 484.4,457.9 483.8,456.8 483.3,456.4 482.1,456.7 480.4,456.8 479.6,457.1 477.9,457 
				476.9,456.6 475.8,455.9 474.9,454.6 474.6,453.8 474.8,450.3 474.4,448.6 474,445.3 473.8,444.7 473.1,443.6 469.9,442.5 
				469.1,442.1 468.3,442.3 467.6,442.9 465.6,443.1 463.8,443.1 461.3,442.6 460.3,442.1 458,441.5 457.2,441 456.4,439.7 
				456.5,434.7 456.4,428.1 456.3,427.9 455.1,426 455.1,425.3 455.3,424.8 454.6,424.1 454.4,423.6 452.3,420.7 451.2,419.6 
				449.8,418.7 447.9,418.2 442.1,418.1 441.5,418.2 437.1,419.3 436.7,419.3 429.2,421.7 426.7,422.3 426.5,422.8 426.1,424.9 
				424.5,427.2 423.1,429.8 423,430.1 423,433.5 423.2,434.3 423.2,435.4 421.6,441.1 420.7,442.7 422.6,443.2 423.5,444.2 425,447 
				427,449 427.4,449.6 430.6,452.1 432.3,453.1 433,453.6 434.5,456.2 434.7,456.4 435.3,456.7 436.8,457.3 437.9,457.5 441.9,457.7 
				443.7,458.3 444.7,458.8 448.2,461.3 450.5,462.3 452.7,463.6 454,465.1 455.9,465.7 457.1,466.5 457.9,467.8 458.3,468 
				458.7,468.8 459.1,470.1 459,471.8 457.7,473.9 455.7,476.9 454.2,480.2 453.8,481.4 453.5,482.6 453.5,483.4 456.5,483.4 
				461.1,484.2 462.1,484.6 464.4,486.1 465.6,486.4 467,486.3 467.8,486.5 468.6,486.5 469.4,486.3 469.9,485.7 471.3,485.1 
				473.3,485.3 474.4,485 474.5,484.9 474.8,484.4 476.9,482.7 477.5,482.5 477.8,482.1 479.6,480.6 480.8,479.2 481.6,478.1 
				482.1,476.8 482.5,474.7 483.1,470.5 483.1,467.7 483.6,464 484.1,461.5 	"
        />
      </g>
      <g
        id="colombia"
        onClick={() =>
          setPais({
            nombre: "colombia",
            display: false,
          })
        }
        onmouseover='onPais("colombia"); console.log("on colombia");'
      >
        <polygon
          id="col"
          class="st2"
          points="
				297.4,256.4 297.9,256.8 302.3,258.5 306.6,260.7 308.4,262.1 308.9,263 309.2,264.4 309.4,264.6 310.7,264.7 312.1,264.4 
				312.8,264.4 315.6,264.7 316.9,264.7 316.9,264.7 317.5,263.7 318.6,263.6 320,264.1 320.7,264.8 321.7,265.2 323.7,266.7 
				324.3,266.8 324.9,266.8 326.4,267.3 327.7,267.5 328.6,268 329.4,269.2 329.9,269.7 331.4,270.4 332.1,272.4 332.8,273.5 
				333.6,274 334,274.6 334.6,274.8 335.2,274.8 336.2,275.1 337,275.7 337.8,276.8 337.9,278.6 338.3,279.7 338.5,280 338.8,280.2 
				339.9,280.2 341,280.7 341,283.9 341.5,284.5 342,285 343,285.1 343.7,285 345.9,285.1 347,285.6 347.4,285.6 348.3,285.5 
				348.6,285.3 348.9,284.7 349.6,284.5 350.1,284 350.8,283.7 352.2,283.7 353.4,284 354.1,284.6 355,284.7 356,284.6 357.2,283.7 
				357.8,283.7 358.9,284 361.3,284.8 363.7,286.4 365.3,288.2 365.3,289.3 364.7,290.5 363.8,291.7 362.1,293.3 361.2,294.7 
				361.2,295.8 361.6,295.9 362.2,295.8 363,295.9 363.8,296.6 365,298.1 365.2,298.1 365.4,297.4 365.8,296.6 366,294.7 366.2,293.4 
				366.4,293 366.6,291.2 367,290.1 367.6,287.5 367.8,287.3 369.4,277.3 369.4,276.6 368.5,272.7 367.8,271.4 367.1,270.6 
				365.3,269.1 364.5,268.2 364.5,262.4 365.7,261.9 367.9,261.5 369,261.1 370.7,261.3 371.3,261.6 371,260.8 370.8,259.7 
				370.3,259.1 369.3,259.3 368.9,259.5 367.5,259.5 367,258.9 366.3,258.6 366.3,258.5 366.1,258.5 366.1,252.6 367,253 378,253 
				378.2,252.8 378.4,251.8 379.1,250.9 379.9,250.9 380.7,251.5 381.1,252.1 383.7,250.8 385.5,250.8 386.4,251.6 387.7,253.4 
				388.4,256.9 389.1,256.6 388.5,255.6 388,253.9 385.9,249 384.8,247.4 382.2,245.4 382.2,244.4 384.1,242.7 384.9,241.6 
				385.2,241.5 385.7,241 386,240.8 386,240.7 386,240 385.5,239.3 384.9,238.1 384,237.1 382.8,236.4 382.5,235.9 382.3,234.4 
				382.1,233.3 382.1,232.2 381.4,231.3 381.4,229.2 382,228.3 382.3,227 382.3,225.4 383.6,223.9 383.6,222.1 384,221.3 384.9,220.5 
				384.9,220.1 384.5,219.1 384.3,218.9 384.1,218.9 382.8,219 379.9,220.1 378.4,220.4 377.3,220.4 376,220.2 375.1,219.9 
				374.5,219.6 373.9,219.4 373,219.7 372.7,220.1 371.6,220.2 370.1,220.1 369.7,219.8 369.4,219.8 365.1,213.7 365.1,213.5 
				364.9,213.4 363.4,213.1 362.5,212.6 361,212 358.6,212.1 356.1,212.8 354.7,213 352.3,212.8 349.8,211.8 349.1,210.7 348.8,210.5 
				346.8,209.6 346.6,209 346.6,204.3 346.3,203.2 345.9,202.5 345.3,201.9 345.1,201.5 344,200.3 343.7,199.5 343.2,196.9 
				342.8,196.1 338.9,196.2 340.1,193.5 341.3,191.5 343,185 345.1,181.9 348.5,178.4 349,177.8 349.7,176.4 350.6,175.5 352.9,174.5 
				355,174.3 355.1,174.8 355.1,174.8 355.8,174.2 356.5,173.2 356.3,171.9 355,171 353,170.2 351.8,170.9 351.4,170.8 351.2,170.9 
				351.2,172 350.3,172.1 349.6,171.9 349.3,172.4 349.2,173.4 348.5,174.4 347.6,175.1 344.2,176.2 340.3,178.4 339.6,178.7 
				336.7,179.3 335.6,179.3 334.3,179 333,179.1 332.7,179.3 332.2,180.1 331.7,181.7 331.1,182.8 330.7,183 330.5,183 330.3,181.8 
				330.1,181.5 328.5,181.5 327,181.3 326.5,181.5 323.4,183.7 322.3,184.6 322,185.2 321.8,188.2 321.4,190.4 321.3,192.7 
				320.4,193.6 318.4,194 317.7,194.7 316.5,196.7 316.1,197.2 314.9,198.1 312.4,199.7 312.2,200.2 312.3,200.7 312.6,201 313,203.9 
				312.7,204.7 312.5,204.8 311.9,204.4 310.5,202.5 308.4,200.1 308.3,200.3 308.1,201.3 308.8,201.7 309.3,202.9 309.3,203.5 
				309.4,203.6 310.1,204.2 310.1,205 309.1,205.9 307.7,207.8 306.9,208.5 306.1,207.9 306,208.8 305,209.7 305,210.6 304.5,210.6 
				304.6,210.8 305.3,213.3 305.7,214.1 307.4,215.2 308,216.3 308.1,216.8 307.9,219.9 308.4,222.1 308.4,222.7 307.7,223.8 
				307.4,224.7 307.9,228.3 308.5,231.1 308.5,232.2 307.8,234.4 307.7,235.5 307.8,236.4 308.8,236.8 309.8,236.9 310.5,236.8 
				311.6,236.1 311.6,236.8 311.4,237.2 311.1,237.9 309.2,239.4 307.3,241.8 306.2,245.2 305.9,245.6 305.5,245.9 303.5,246.6 
				301.5,246.4 301.1,246.6 299.5,247.5 298.7,249.3 298.5,250.6 298.8,251.7 298.7,252.6 296.9,252.9 296.3,253.5 295.9,254.3 
				295.9,255.2 296.6,256 296.7,256.4 296.7,256.4 297.2,256.1 	"
        />
      </g>
      <g
        id="peru"
        onClick={() =>
          setPais({
            nombre: "peru",
            display: false,
          })
        }
        onmouseover='onPais("peru"); console.log("on peru");'
      >
        <polygon
          id="pe"
          onclick='console.log("set peru"); setPais("peru"); '
          onmouseover='console.log("on peru"); onPais("peru"); '
          class="st2"
          points="
				362.9,407.5 365.3,407.4 366,407.2 366.1,407.1 366.2,406 367.2,404.3 367.8,403.7 367.8,403.7 367.7,403.5 367.3,403.4 367.5,402 
				369.2,399.1 370.7,397.5 372.4,395.2 372.4,394.1 372.3,393.9 371.6,393.2 371,393 370.4,392.2 370,392 369.6,390.8 369.5,389.4 
				369.6,387.5 370.5,385.9 371.1,385.5 371.4,384.8 371.4,384.2 371.2,383.5 369.8,381.8 369.8,381 370.5,379.9 372.4,378.9 
				372.4,378.9 372.4,376.4 371.9,373.8 371.9,372.1 372.3,369.6 372.3,366.1 373,364.4 374.3,362.9 372.3,359.7 367.7,351.6 
				365.7,351.8 365,352.1 365,352.3 363.4,352.6 362.7,352.6 361.5,352.1 361,351.2 361,346.8 361,346.4 361,339.3 360,340.3 
				356.8,342.8 354.3,344.1 352.9,344.3 350.6,344.2 348.6,343.5 348,342.7 347.6,341.2 347.2,340.7 346.7,340.3 344,340.1 
				341.9,339.6 341.7,338.5 342.5,337 342.5,336.3 341.2,335.5 339.3,333.4 338.8,332.4 337.6,330.8 337.3,330.3 337.1,328.6 
				335.4,325.2 335.4,324.5 335.7,323.8 337.3,320.7 337.9,318.2 338.7,317.3 340.2,316.9 341.1,316.8 341.1,312.9 341.5,311.9 
				342.7,309.6 343.9,307.9 346,305.6 347.5,304.3 349.8,302.8 352.5,301.6 359.7,299.6 361.7,298.8 364.4,299 363,297.3 362.6,296.9 
				362.2,296.8 361.5,296.9 360.4,296.6 360.2,296 360.2,294.4 361.4,292.6 363,291 363.8,289.9 364.3,289 364.3,288.6 363,287.1 
				360.8,285.7 358.6,284.9 357.5,284.8 356.4,285.5 354.9,285.8 353.7,285.5 352.9,284.9 352.1,284.8 351,284.8 350.6,284.9 
				350.1,285.4 349.6,285.6 349.4,286 348.6,286.5 347.5,286.6 346.8,286.6 345.6,286.1 343.8,286 343.1,286.2 341.6,286 340.6,285.1 
				340,284.2 340,281.3 339.7,281.2 338.5,281.2 337.8,280.8 337.3,280.2 336.9,278.7 336.8,277.1 336.3,276.4 335.7,276 335,275.8 
				334.3,275.8 333.3,275.4 332.9,274.8 332,274.2 331.1,272.8 330.6,271.1 329.3,270.5 328.6,269.9 327.9,268.8 327.4,268.5 
				326.1,268.3 324.7,267.8 324.5,267.8 324.3,268.1 322.9,268.1 322.9,268.5 323.3,268.7 323.8,269.3 324.6,271.1 324.6,271.6 
				325.9,274.2 325.4,274.8 324.4,274.8 323.4,276.7 321.4,280.2 319.5,282.3 318.4,283.2 317.1,284.5 315.7,286.2 314.3,287.2 
				312,288.1 307,289.7 304.4,290.8 303.6,291.4 303.2,291.7 302.6,292.9 302.5,294.4 301.6,294.9 301,294.9 300.6,294.6 298.8,297.1 
				298.5,298.9 298.4,301.9 298.1,303.1 297.7,303.5 296.4,304 295.4,305 293.6,305.7 292,305.6 291.2,304.2 290.9,303.4 290.4,302.5 
				289.7,302.4 288.1,302.5 287.6,301.7 287.2,301.5 286.2,301.4 285,302.1 284,302.4 283.5,301.6 283.3,299.7 282.9,299 282.9,298.3 
				283.6,297.4 285.1,297 285.5,296.5 285.4,295.8 285.1,295.6 284.8,294.4 284.6,294.4 284.6,293.9 284,294.5 282.5,295.6 
				280.3,297.7 278.3,299.9 278.1,300.6 278,302 278.3,304 278.4,304.4 279.1,305.5 279.3,307.4 280.6,308.6 281.2,309.5 281.2,309.9 
				281,310.8 279.5,312.2 279.8,312.9 282,314.3 283.1,315.3 285,316.7 286.1,317.4 288.1,319.1 289.3,320.8 289.6,320.9 290.5,322 
				291.7,325.6 292.5,327 296.7,331.1 297.6,333.3 297.8,334.8 298.1,335.7 299.9,339 300.7,341 302,344.8 304.6,349 304.9,349.9 
				305.3,353.1 306.1,355.5 306.9,356.1 307.8,356.6 308.6,357.6 309.7,360.4 311.7,363 313,365.2 314.1,367.8 316,369.9 316.8,372.1 
				317,373.8 316.2,374.5 315.7,375.2 316.1,377 316.3,377.6 317.9,379 319,380.6 320,381.4 322.7,384.2 324.1,385.3 325.1,386.3 
				328.6,388.6 333.2,390.9 336,393.2 336.5,393.3 342.2,395.7 345.4,397.5 346,397.7 346.5,397.6 347.2,397.9 350.5,400.3 
				352.1,401.2 353.1,402.5 354.4,403.6 355.8,405 357.3,407 359.1,407.9 360.4,408.7 360.6,408.7 360.8,408.9 362.3,407.6 	"
        />
      </g>
      <g
        id="chile"
        onClick={() =>
          setPais({
            nombre: "chile",
            display: false,
          })
        }
        onmouseover='onPais("chile"); console.log("on chile");'
      >
        <g id="cl">
          <polygon
            class="st2"
            points="377.2,742.4 375.2,741.2 370.7,740.4 367.2,739.3 365.8,739.2 363.3,739.6 359.6,739.7 359,739.4 
					351.2,739.4 349,738.5 348.2,737.5 347.3,735.6 347.1,734.9 347.3,726.6 347.1,724.5 346.7,722.1 346.7,721.9 346.4,721.9 
					345.8,722.8 343,722.3 342.1,722.8 341.7,723.7 340.7,724.5 339.8,724.1 339.3,722.9 339,720.9 338.8,720.5 338.8,715.9 
					339,714.6 339,712.7 340.1,708.2 340.9,706.5 341.5,706 343.4,702.1 344.9,700.2 345.2,699.6 345.7,697.9 346.1,694.9 
					346.4,694.1 346.7,693.8 346.8,692.9 346.5,691.4 346.1,690.7 346.1,689.7 346,689.7 346.1,687 346.3,686.1 346.8,685 
					348.8,682.6 350.1,680.1 350.3,678.7 351.2,676.6 351.9,674.5 352.1,669.4 351.9,668.3 352,666.5 352.1,665.8 352.2,662 
					352.4,660.5 353.1,659.6 353.6,659.1 353.6,658.1 352.4,656.7 350.4,655.8 349.7,653.7 350.7,653.1 353,653.4 354.5,653.3 
					354.7,652.5 354.6,651.6 353.7,650.6 352,650.1 350.5,650.1 350.4,648.8 351,644.6 350.7,642.7 349.9,641.3 349.8,640.4 
					349.9,636.6 349.8,636.3 349.3,635.8 348.8,634.8 348.8,629.5 349.7,626.4 351.1,624.5 351.6,623.4 351.6,622.9 351.6,621.3 
					351.5,620.9 350.3,619.7 349.8,617.4 350.1,613.1 351.3,607.4 351.2,605.1 351.3,604.3 353.7,595.7 354.2,593.5 354.5,592.6 
					355.2,591.8 356.4,590.7 356.8,589.5 356.8,588 356.2,586.3 355.6,582.2 355.6,577 356.2,571.3 356.8,569.3 357.9,568 
					358.6,567.6 359.3,567.5 359.4,567.4 359.6,566.4 360,565.8 361,565.2 361.2,565 361.2,564.3 360.8,561.8 360.7,557.3 
					362.3,555.9 362.4,555.7 363,552.4 363.3,551.3 364.1,549.6 365.9,546.9 365.9,544.8 365.7,542.4 365.3,539.8 365,539.2 
					363.9,537.9 364,536.1 363.9,534.8 362,530.1 362,526.1 360.7,524.7 360.7,522.6 361,520.4 361.5,518.5 361.9,518.2 362.9,516.3 
					363.4,514.2 364.2,513.3 364.4,512.6 364.4,511.8 365.1,511.3 365.5,509.6 365.5,508.3 365.3,506.8 365.6,503.3 365.9,501.9 
					366.7,500.2 367,499.2 367.6,496.2 368.5,492.4 372.1,488.4 373.1,484.9 373.6,484.4 375.8,482.3 377.2,481.8 377.7,481.4 
					377.8,481.3 377.6,479.4 376.9,477.4 376.6,475.6 376.6,472.1 376,469.9 376,467.5 376.2,466.9 376.9,466.1 376.9,464.9 
					376.6,463.4 376.6,462.3 376.9,461.1 383.8,457.9 386.2,456.9 387.4,450.6 387.4,449.1 386.8,448.4 386.8,448.3 386.6,448.2 
					386.6,448 385.7,448 385,448.3 383.1,448.4 382.1,448.3 380.8,447.7 380.6,447.2 380.1,445.4 380.1,444.9 379.4,441.7 
					379.4,439.1 379,436.2 378.2,434.7 378.2,433.4 377.9,431.6 377.8,431.3 376.8,430.5 376.1,430.1 375.6,429.3 375.7,428.2 
					375.6,427.1 375.3,426.7 374,426.1 374,424.9 374.4,423 374.7,422.6 375.9,422.1 375.9,421.9 375.5,421.1 375.7,419.9 
					376.2,419.2 376,418.2 375,417.2 373.3,415.9 372.5,415 372,413.7 371.4,409.2 371.4,407.9 370.6,406.7 370,406.2 368.6,404.5 
					368.3,404.6 367.9,405 367.2,406.3 367.1,407.5 366.7,408 365.5,408.4 363,408.5 362.8,408.6 361.3,409.8 361.9,411.9 
					362.7,417.3 363,421.1 363.5,422.5 363.8,423.9 363.5,431.4 364.3,435.2 364.4,435.6 364.3,436.7 363.9,438.2 363.8,439.9 
					363.2,441.7 362.9,447.7 362.5,448.7 361.9,448.7 361.2,449.6 360.7,451.5 360.7,452.3 361.6,453.5 361.5,455.1 361.1,456.9 
					361.1,463.7 361.4,465.7 361.4,467.9 361.3,469.1 360.3,469.8 359.8,471 359.4,472.2 359.5,474.1 359.9,476.2 359.9,477.5 
					359.7,478.7 359.4,481.4 358.8,483.2 358.2,483.9 357.8,484.6 357.8,487.5 356.7,489.4 355.8,494.8 355.1,497.6 353.6,499.7 
					353.1,500.6 353.1,502 353.4,502.6 354.4,505.4 354.5,507.3 354.7,508.1 354,510.8 352.3,511.8 352,515.3 352.2,519.9 
					352.9,524.9 353,528.6 353.7,531.5 353.7,535.1 353.1,536.5 352.2,538.2 352.2,543.3 351.2,544.5 350.2,548.6 350,548.8 
					349.9,550.6 348.4,554.9 348.2,556 345.9,560 345.3,561.7 344.8,563.9 344,564.9 343.4,568 342.5,570.7 342,571.1 341.4,570.5 
					340.6,571 340.8,575.7 339.6,576.3 338.5,575.7 337.4,577 337.4,579.7 337.2,582 338,584 338.3,585.6 338,589.2 337.8,589.2 
					337.7,589.5 338.8,591.9 339.4,594.6 339.6,598.8 339.2,600.4 338.5,601.2 338.2,602 336.9,602.7 336.7,609.3 336,611.1 
					335.6,614.4 335.5,616.5 335.7,618.2 336.1,619.2 336.8,619.4 337.2,620.3 337,621.2 339,622 340.8,621.5 341.4,620.9 
					341.6,619.9 342.5,619.3 343.8,619.4 344.7,620.6 345.6,621 346.3,621 347.6,620.5 347.6,620.7 346.8,621.5 345.9,621.8 
					344.5,622.7 344.1,624.1 345.3,624.6 346.5,624.6 346.8,627.6 346.8,629.2 346.1,627.2 345,627 344.4,628.3 345.1,629.7 
					345.9,630.5 344.9,630.3 343.8,631.8 344.2,634.8 343.4,637.4 342.6,638.1 342.3,638.7 342.1,639.6 342.2,640.6 343.1,641.3 
					342.8,642.2 343.2,642.9 342.4,643.8 341.9,644.8 341.6,645.9 340.7,646.6 340.5,647 340.3,647 340.3,647.3 340.8,647.9 
					341.8,648.3 343.4,649.9 344.2,650.1 344.7,651.1 344.7,653.7 344.5,654.6 343,656.1 341.8,656.4 340.3,656.4 339.6,656.8 
					339.6,657.7 339.4,659.2 340.7,659.4 342.6,660.6 343.6,660.6 343.7,661.6 342.8,661.7 342.1,661.4 341.4,660.9 340.2,660 
					339.6,660.2 338.9,660.9 338.4,661.7 338.2,663.1 339.9,662.3 341,662.1 341,662.8 339.9,663.2 339.2,663.9 339.2,664.5 
					340.5,664.9 338.7,665.3 338.4,665.4 338.1,666.1 337.7,669.6 338.7,667.7 339,666.9 339.5,666.1 340.2,665.7 340.1,666.7 
					339.4,668.3 339.1,669.4 339.1,670 338.2,670.4 337.2,671.4 336.7,672 336.5,673 335.8,673.3 335.8,672.4 336.1,670.9 336,670 
					335.2,670.9 334.6,672.3 333.6,672.1 332.9,671 333.3,670.2 334.1,669.1 334,668.1 333.3,668.8 333.3,668 333.5,666.9 
					332.8,666.3 331.9,665.8 330.9,666.6 330.3,666.1 329.1,665.8 326.6,666.3 326.6,667.5 327,668.1 327.5,668.5 328.3,668.2 
					328.7,668.5 328.7,669.5 327.5,670.2 326.3,670.4 325.2,672 324.1,673 323,673.6 322.4,676.5 322.7,677.8 324.1,678.1 
					324.5,676.8 324.1,675.7 323.5,675.2 324.3,674.5 325.5,673.9 326.4,674.5 327.2,675.5 329.2,676.5 330,676.3 330.8,675.4 
					331.9,675.3 332.8,676 333.9,677 334.3,678 334.7,678.4 333.3,679.7 334.4,680.7 333.2,681 331.7,681.9 330.9,683.2 331.4,683.7 
					333.2,684.7 334.4,685.1 333.3,685.9 333.3,686.6 334,687.4 335.8,687.4 336.1,686.5 336.5,687.4 336.9,689.2 337.9,691.2 
					339.6,692.3 338.7,692.4 336.5,690.7 333.5,690.1 333,690.5 333.2,692.6 332.7,691.2 331.9,689.8 330.8,689.6 330.8,692.5 
					331.4,693.5 331.7,694.9 332.2,695.4 335.1,694.6 335.7,694 336,695.7 336.4,696.8 335,695.7 332.8,695.8 332.4,697.4 
					333.3,698.4 332.8,698.4 332.2,699.1 332.2,703.5 332.4,705.5 332.7,706.6 333.4,707 334.1,705.6 334.4,704.4 335.7,703.2 
					336.2,703 335.1,707 335.4,707.9 336.2,708.3 336.2,708.7 335.7,708.7 334.8,708.4 332.7,709.8 332.7,710.7 332.3,711.2 
					332.2,713.5 330.3,715.5 330.9,716.9 331.9,717.9 332.8,718.5 334.6,718.8 335.4,719.7 335.7,720.2 334,719.7 333.6,720.6 
					334.3,722 335.1,722.8 335.8,722.8 336.2,721.8 336.8,722.2 336.9,723.8 335.5,724.3 333.9,724.3 333.5,725.3 334,727 
					337.4,730.8 338.4,733.4 338.1,736.9 338.7,738.5 339.3,738.9 340.1,739 340.2,737.3 340.1,735.3 339.2,733.2 340.1,735.2 
					340.3,736.5 340.8,737.6 341.2,738.2 342,737.6 342.5,736.3 341.6,734.4 342,734.2 343.1,735.2 344.2,735.8 345,735.5 
					345.1,734.5 340.7,731.2 341.4,731.3 343,732.2 345.1,734.1 346.2,735.5 346.3,736.7 346.2,737.6 345.1,738 346.5,739.8 
					346.7,741.5 346.2,742.9 344.5,743.8 344.2,744.4 344.2,745.3 346.2,744.8 346.5,745.6 346.5,746.5 347.4,745.7 349.2,745 
					352.4,745.1 353.8,745.5 354.6,747.1 355.5,748.2 357,749.2 356.8,750.2 356.1,751.8 355.2,752.8 353.5,753.8 351.7,754.1 
					350.9,754.9 350.3,755.9 349.1,756.5 349.3,755.6 349,754.6 347.8,755.2 347.7,756.6 348.4,758 349.9,759.4 352.7,760.8 
					354,760.9 356,761.7 356.8,761.6 357.2,761.2 357.6,760.3 357.9,759.1 358.1,754.3 359.1,750.9 359.1,747.7 360.3,747.3 
					361.3,746.7 362.2,746.9 363.3,746.5 364,745.9 366.3,744.5 368.7,744 369.2,743.1 369.6,742 371.5,741.1 372.7,741.2 
					374.5,742.3 376.1,742.3 377.7,742.7 378.2,742.5 378.2,742.1 378.1,742.6 		"
          />
          <polygon
            class="st2"
            points="338.7,626.1 338.6,625.3 337.6,622.9 337,622.7 336,622.8 335.8,621.2 335.5,621.5 334.6,622.6 
					334,622.9 333.8,623.8 333.7,625.3 333.2,627 333.2,631.8 332.2,635.2 331.5,636.6 332.3,638.4 335,639.2 336.2,639.3 
					336.9,638.4 336.5,636.7 337.8,636.8 337.7,635.5 337,634.1 336.9,633.3 338.1,633.1 337.8,632.2 337,632 336.4,631 336.5,630.6 
					336.9,629.9 337.4,630 338.1,628.5 339.2,628.3 339.2,627.5 		"
          />
          <polygon
            class="st2"
            points="339.6,654.4 340.2,655.3 341.3,655.6 342.4,655.3 343.8,654.3 343.8,652.8 344.1,651.8 344.1,651.3 
					343.7,650.6 342.3,650.1 342.3,649.6 339.9,650.9 338.8,651.9 339.1,653.1 		"
          />
          <polygon
            class="st2"
            points="334.9,652.9 334.8,652.6 333.7,652.1 332.2,652.2 332.4,652.9 333,653.7 		"
          />
          <polygon
            class="st2"
            points="332,656.4 332.8,656.6 334.7,655.9 334.8,655.7 334.6,655.4 333.3,655.3 332.1,655.9 		"
          />
          <polygon
            class="st2"
            points="336.5,658.1 336,657 333.1,657.2 332.8,657.5 333.3,657.5 334.6,657.8 335.6,658.7 336.5,659.2 		"
          />
          <polygon
            class="st2"
            points="334.6,659.2 333.4,658.7 334.1,659.6 335.7,660.4 		"
          />
          <polygon
            class="st2"
            points="336.5,666.3 336.3,665.1 335.9,664.5 335.6,664.8 335.5,665.4 335.7,666.5 		"
          />
          <polygon
            class="st2"
            points="332.2,687.2 332.5,687 332.2,685.9 330.9,684.7 330.4,685.2 329.7,685.6 329.7,686.6 331,687.2 		"
          />
          <polygon
            class="st2"
            points="324.8,686.9 324.5,687.6 325.2,688.1 326.3,687.9 327,686.9 327,686.3 326.1,686.3 		"
          />
          <polygon
            class="st2"
            points="325.8,690.7 325.8,690.9 327.3,693.7 328.1,693.6 328.4,693.2 328.5,692.1 328.2,691.6 		"
          />
          <polygon
            class="st2"
            points="323.3,690.6 323.4,691.7 323.9,693.2 325.2,694.6 325.5,696.1 325.9,696.8 326.3,696.4 326.3,695.7 
					325.6,694.3 324.3,690 		"
          />
          <polygon
            class="st2"
            points="329.1,694.9 327.5,694.5 327.2,694.6 327.3,695.3 327.8,696.1 328.8,696.6 330.6,697.1 330.4,696.6 
					329.6,695.8 		"
          />
          <polygon
            class="st2"
            points="323.2,696.8 323.1,697.8 323.5,698.4 324.1,697.9 324.6,697 324.3,695.6 323.9,695.1 323,695.6 		"
          />
          <polygon
            class="st2"
            points="331.1,712.4 331.2,710.8 331.1,706 330.9,702.2 330.6,700.7 330.1,699.4 329.3,698.4 328.4,697.6 
					327.6,698.2 326.9,699.1 326.7,699.9 327,701.1 326.7,701.3 325.8,701.9 325.5,702.8 326.3,703.6 327.1,703.8 327,705.4 
					325.9,704.7 325.2,704.5 325,705 326.1,706.6 328.2,707.6 328.3,708.1 327.9,709 327.9,712.5 328.9,713.9 330.3,713.7 		"
          />
          <polygon
            class="st2"
            points="325.1,711.5 324.3,710.3 323.8,709 322.9,709 322.6,709.1 322.8,709.8 322.8,710.8 323.6,711.6 
					324.7,711.7 		"
          />
          <polygon
            class="st2"
            points="327.3,717.5 327.9,716 327.7,715.2 326.5,715.5 325.4,716.2 324.1,715.6 323.8,716.4 323.9,717 
					324.3,718.3 325.3,717.7 325.8,718.5 327.1,719.2 		"
          />
          <polygon
            class="st2"
            points="331.7,720 330.4,719 329.7,717.9 329.1,718.1 329.1,718.5 330.1,720.6 330.1,721.4 331.1,721.7 
					333.1,723.4 333.3,723.4 332.6,721 		"
          />
          <polygon
            class="st2"
            points="330.8,722.7 328.2,721.6 328.1,721.3 328.1,724.2 329.7,727.4 330.9,727.8 330.7,726.5 331.4,725.1 
							"
          />
          <polygon
            class="st2"
            points="329.1,728.1 327.9,729.6 327.7,730.8 327.9,730.7 328.8,731 330.1,730.3 329.7,727.8 		"
          />
          <polygon
            class="st2"
            points="327.9,734.1 327.7,735.3 327.3,735.9 327,737.5 327,739.6 327.8,739.7 327.9,739.7 328.5,736.5 
					328.4,734.6 		"
          />
          <polygon
            class="st2"
            points="333.6,732.5 333.3,732.9 333.3,734.3 333.7,735.3 334.8,736.3 335.6,736.4 334.1,733.9 		"
          />
          <polygon
            class="st2"
            points="337.1,732.9 336.8,732.1 336,731.9 335.4,730.7 335.4,731.6 335.8,732.9 337.2,734.6 		"
          />
          <polygon
            class="st2"
            points="337.9,746.7 337.7,747.6 338.6,747.4 338.8,747.6 338.7,748.6 339.1,749.3 340.5,749.8 340.5,750.4 
					340.2,751.3 342.3,751.4 343,750.8 343.2,750.1 343.9,751.7 344.7,752.3 344.6,753.2 343.9,753.6 342.5,753 341.4,753.2 
					341.7,753.6 345.2,756.8 346.4,757.2 346,756.4 346,755.2 346.5,754.7 346.3,753.9 347.1,753.3 347.8,753.8 348.1,753.3 
					348.1,752.5 348.7,752.3 349.8,752.6 350.8,752 354,749.3 354,748.1 353.5,747.1 351.8,747.2 350,746.9 349,747 346.6,749.6 
					345.2,748.4 345,747.6 344.2,746.7 343,746.1 343.9,743.9 345.3,742.1 345.1,740.4 344.8,739.1 343.9,738.4 343.3,738.2 
					343.6,739.3 343.9,739.8 343.5,741.6 342.7,742.1 342.4,741.5 342.3,740.2 341.7,739.7 340.8,740 340.2,740.7 339.4,740.6 
					338.7,740 338.4,740.2 338.3,740.6 338.1,740.7 338,742.6 338.1,743.9 337.9,745.3 		"
          />
          <polygon
            class="st2"
            points="335.5,746.4 335.7,746.9 336,747 336.9,746.1 337.2,743.9 336.7,743.6 335,744.1 335,744.9 
					335.3,745.6 335.7,745.6 336,745.3 336,745.8 		"
          />
          <polygon
            class="st2"
            points="337.9,754.2 338.4,755.1 339.9,755.7 341.5,755.7 341.3,755.1 340,754.2 339.4,753.4 339.1,753.3 
					338.7,753.8 338.4,753.8 338.1,752.8 331.3,749.5 329.8,747.8 329.3,747.6 330.2,749.9 330.9,750.9 334,752.8 335.6,753.4 
					336,753.3 335.8,752.8 336,752.2 		"
          />
          <polygon
            class="st2"
            points="330,738.2 330.1,735.4 329.7,734.6 329.4,734.5 329.2,734.7 329.4,735.4 329.4,736.2 328.8,736.9 
					328.8,737.5 328.8,739.1 329.1,739.4 329.7,739.1 		"
          />
          <polygon
            class="st2"
            points="346.7,759.5 346.7,759 346.3,758.5 345.4,758.3 344.7,758.9 343.6,756.8 343.3,757.5 342.5,756.5 
					341.9,756.4 341.7,756.5 341.7,757.5 341.1,757.4 340.5,756.8 339.1,756.6 338.9,755.9 338.2,755.6 337.6,755.8 337,756.2 
					337,756.6 338.2,758.3 338.8,760.6 340.2,761 340,761.9 340,763.3 340.5,763.9 342.1,764 343.8,764.5 342.5,765 343,765.3 
					343.8,765.3 344.9,765.7 345.4,765.4 345.2,764.4 345.4,764 345.8,764.5 346.8,764.7 347.2,764.2 347.4,761.9 348.4,761.8 
					348.5,761 348.2,760.5 		"
          />
          <polygon
            class="st2"
            points="355.2,767.1 356.1,768.4 357,768.4 357.8,767.5 357.6,766.5 357.1,765.9 356.5,765.8 356.1,766.3 
					355.4,764.1 354.8,764.2 354.5,763.6 353.5,763.6 353,764.1 352.7,764.9 352.3,764.5 352.3,763.8 352.5,763.5 352.2,762.8 
					351.7,762.7 351.1,761.9 351,761.9 350.6,761.9 350.1,762.7 349.3,763.1 348.7,763.1 349.3,765.1 349.3,766 350.1,766.2 
					350.9,766.8 351.2,767.4 352.3,767.9 352.5,767.6 352.2,767.3 352.7,766.7 		"
          />
          <polygon
            class="st2"
            points="359.4,762.1 359.1,762.4 359,763.3 359.1,764.4 359.8,765.4 361.3,767 362,767.3 363.1,767.4 
					362.6,766.6 361.7,766.5 361.1,766.1 360.7,764.8 360.6,763.5 361.1,763.8 362.2,764.8 362.6,764.9 362.6,764.4 362.2,762.8 
					361.9,760.8 361.8,758.6 361.3,758.3 360.3,760.2 360.7,760.8 360.8,761.7 360.5,762 		"
          />
          <polygon
            class="st2"
            points="358.4,777.1 357.9,777.5 357.7,778.1 357.8,778.5 359.1,779.2 359.5,779.1 359.8,778.8 359.8,778.2 
					359.3,776.8 		"
          />
          <polygon
            class="st2"
            points="362.1,779.1 361.1,778.6 360.8,778.8 360.8,779.3 361.4,780.1 362.1,780.6 362.7,780.7 363,779.6 
					362.7,778.9 		"
          />
          <polygon
            class="st2"
            points="369.4,777.8 372.1,777.2 372.1,777 371.8,776.4 369.3,775.8 367.6,776 367.4,775.8 366.8,776.1 
					366.8,777.2 367,777.9 367.3,778.1 		"
          />
          <polygon
            class="st2"
            points="381.1,784.1 380.6,783.8 380.4,782.9 379.5,782.4 377.2,781.8 377.1,781.5 377.6,781.2 379.9,781.2 
					379.7,780.9 379.6,780.3 379,779.9 375.7,779.6 374.1,778.4 374.5,778.2 376.5,779.1 377.5,779.1 379.1,778.7 378.8,777.8 
					377.7,776.7 377,776.6 374.7,777.5 372.2,777.6 368.4,778.8 366.3,779.1 366,779.4 365.9,780 366,780.7 366.8,780.9 368.7,780.7 
					369.8,780.1 370.6,779.9 370.7,780.1 370.5,780.5 368.7,781.6 368.6,781.9 370.2,783 370.3,783.6 370.6,784.3 373.4,784.5 
					371.5,782.4 371.1,781.4 371.6,780.6 373.4,780.7 374,781.1 374.3,781.8 375,782.1 375.2,783.2 376,784 378.2,783.3 379.2,784.1 
					379.9,784.3 380,785.3 380.6,786.1 381.3,786.7 381.7,786.7 381.7,786.3 381.3,785.3 		"
          />
          <polygon
            class="st2"
            points="376.2,773.2 376,746.4 376,746.3 375.3,744.9 374,745.8 372.5,746.2 371.2,746 370.1,743.8 
					368.4,744.5 367.5,747.3 366,747.7 365.6,748.2 364.5,747.3 362.3,747.5 363.8,747.9 364,748.9 364.7,749.9 364.3,751.1 
					363.4,751.2 362.7,750.5 362.1,750.9 362.1,752.5 361.9,754.1 362.3,755.1 364.3,756.4 367.3,755.1 369,754.9 370.1,755.1 
					370.7,756.2 370.3,758 366.6,759 366.2,759.7 364.9,759.7 364.5,760.8 365.1,765.1 365.6,765.8 370.1,767.5 370.5,768.6 
					369.2,768.6 369,769.3 367.9,767.7 366.6,767.5 366.4,769 364.7,767.1 363.8,767.9 364,768.6 365.3,769 364.5,769.3 364,769.9 
					363.2,768.8 360.3,767.1 358.2,765.1 358.2,766.4 359.7,767.5 360.1,768.6 359.7,768.8 359.1,768.6 358.4,769.3 357.1,769.3 
					357.1,770.1 354.9,770.5 355.6,771.2 355.6,772.3 355.4,772.7 356.7,772.7 357.1,772.3 359.2,772.7 358.4,773.4 358.2,773.8 
					359.2,773.8 359.2,774.2 358.6,774.6 359.9,774.9 360.8,774.4 363.6,775.5 365.6,775.5 365.8,775.1 369,775.1 371.2,775.5 
					373.1,777 374,776.4 375.3,776.4 374.9,775.5 376.5,775.6 376.5,773.9 		"
          />
        </g>
      </g>
      <g
        id="ecuador"
        onClick={() =>
          setPais({
            nombre: "ecuador",
            display: false,
          })
        }
        onmouseover='onPais("ecuador"); console.log("on ecuador");'
      >
        <polygon
          id="ecu"
          onclick='console.log("set ecuador"); setPais("ecuador"); '
          onmouseover='console.log("on ecuador"); onPais("ecuador"); '
          class="st2"
          points="
				286,295 286.4,295.3 286.5,296.9 285.6,297.9 284.2,298.3 283.9,298.6 283.9,298.7 284.3,299.4 284.5,301.2 284.7,301.1 286,300.3 
				287.5,300.5 288.3,301 288.6,301.5 289.7,301.4 291,301.5 291.9,303 292.2,303.9 292.6,304.6 293.4,304.7 294.8,304.1 295.9,303.1 
				297.1,302.6 297.1,302.6 297.4,301.7 297.5,298.8 297.9,296.7 299.8,294.1 299.8,293.5 300.8,293.5 301.3,293.9 301.5,293.8 
				301.6,292.6 302.4,291.1 303,290.6 304,289.9 306.6,288.7 311.7,287.2 313.9,286.3 315,285.4 316.3,283.8 317.7,282.4 318.9,281.6 
				320.6,279.6 322.5,276.2 323.7,273.8 324.5,273.8 323.6,271.9 323.5,271.3 322.9,269.8 322.6,269.5 321.8,269.1 322,267.1 
				322.5,267.1 321.1,266.1 320.1,265.6 319.3,265 318.5,264.6 318.2,264.6 317.7,265.4 317.2,265.7 315.6,265.7 312.7,265.5 
				312.1,265.5 310.7,265.7 308.7,265.6 308.3,264.7 307.9,263.3 307.6,262.8 306,261.5 301.9,259.4 297.4,257.6 296.7,257.2 
				296.5,256.9 295.9,257.5 295.1,257.8 293.8,257.9 292.9,258.5 291.4,259.9 290.9,260.3 290.6,260.3 288,260.3 287.2,261.2 
				287.1,263.1 287.5,265 287.5,265.7 287,266.8 285.2,269.1 284.9,270.2 284.8,272.2 283.6,273.5 281.9,274.3 281.5,274.9 
				281.6,278.6 281.3,279.8 281.3,281 281.6,284.5 282.9,285.6 284.2,286.1 284.9,287 285.5,287.1 286.6,286.1 287.7,284.4 
				288.6,283.6 288.8,285.5 289.3,285.8 289.6,286.7 289.1,290.1 288.8,290.8 287.8,291.9 287,292.4 285.6,292.9 285.1,293.4 
				285.1,293.4 285.7,293.4 	"
        />
      </g>
    </svg>
  );
};

export default Desempeño;
