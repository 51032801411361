import "./secciones.scss";
import UncontrolledLottie from "../components/LottieAnimation";
import { useHistory } from "react-router-dom";
// import { ImgInView } from "components/ImgInView";
import CursorAnimation from "../components/cursor/CursorAnimation";

const img = (history) => {
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CAPITULO 05"
        src="images/selecciondDeCapitulos/05/cortadas/CAP05_1.jpg"
        history={history}
        to="/transformacion"
        width="45%"
        transform="translate(-5vw, 10vh)"
      />
    </>
  );
};

const ImgInView = ({ ...rest }) => {
  let history = useHistory();
  return (
    <>
      <CursorAnimation
        text="EXPLORAR CAPITULO 05"
        history={history}
        to="/transformacion"
        {...rest}
      />
    </>
  );
};

const Seccion05 = () => {
  let history = useHistory();
  return (
    <div className="one-screen position-relative section">
      <div
        className="position-absolute top-50 start-0"
        style={{ transform: "translate(10vw, -15vh)" }}
      >
        <div className="section-title">
          TRANSFORMACIÓN
          <br />
          DIGITAL
        </div>
        <div className="section-title title-6 number">05</div>
      </div>
      <div className="position-absolute top-0 start-50">{img(history)}</div>
      <div
        className="position-absolute bottom-0 start-50"
        style={{ transform: "translate(-18vw, 0vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/05/cortadas/CAP05_2.jpg"
          alt=""
          width="45%"
        />
      </div>
      <div
        className="position-absolute bottom-0 start-50"
        style={{ transform: "translate(22vw, 0vh)" }}
      >
        <ImgInView
          src="images/selecciondDeCapitulos/05/cortadas/CAP05_3.jpg"
          alt=""
          width="45%"
        />
        <UncontrolledLottie />
      </div>
    </div>
  );
};
export default Seccion05;
