/* eslint-disable jsx-a11y/anchor-has-content */
import TEXT from "text/carta";
import "./carta.scss";
import React from "react";
import Space from "components/Space";
import { motion } from "framer-motion";
// import gsap, { ScrollTrigger } from "gsap/all";
import Final from "components/Final";
import { isMobile } from "react-device-detect";
import { MobileDownload, MobileNext } from "components/MobileEnd";

const CartaMobile = ({ changeChap }) => (
  <chapter className="is-mobile carta bg-light-gray  ">
    <section
      className="text-white fw-bold header"
      style={{
        background: "green",
        backgroundImage: "url(images/carta/bg1.jpg)",
        backgroundSize: "cover",
        backgroundPositionX: "60%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="bg-hell">
        Eduardo Navarro Beltrán
        <br />
        Presidente
      </div>
    </section>
    <section className="container bg-light-gray px-3">
      <div style={{ color: "#53575A", paddingTop: "60px" }} className="fw-bold">
        {TEXT.left.h}
      </div>
      <p className="text-orange text-justify fw-bold py-4">{TEXT.left.p}</p>

      {TEXT.right.slice(0, 6).map((item) => (
        <p>{item}</p>
      ))}
      <div className="d-flex justify-content-center my-4 py-4">
        <img src="images/carta/pre1.jpg" alt="" width="90%" />
      </div>
      {TEXT.right.slice(6, 13).map((item) => (
        <p>{item}</p>
      ))}
      <div className="d-flex justify-content-center my-4 py-4">
        <img src="images/carta/pre2.jpg" alt="" width="90%" />
      </div>
      {TEXT.right.slice(13, -1).map((item) => (
        <div className="mt-3">{item}</div>
      ))}
    </section>
    <section className="bg-light-gray">
      <MobileDownload href="https://abastible.socialweb.cl/2020/pdf/Cap_00_Carta_del_Presidente.pdf" />
      <div style={{ height: "2px", background: "#ff5100" }}></div>
      <MobileNext href="/secciones#cap01" changeChap={changeChap} />
    </section>
  </chapter>
);

const Carta = ({ changeChap }) =>
  isMobile ? (
    <CartaMobile changeChap={changeChap} />
  ) : (
    <CartaDesk changeChap={changeChap} />
  );

const CartaDesk = ({ changeChap }) => {
  return (
    <>
      <motion.div
        initial={{
          opacity: 0.5,
          // scale: 1.1
        }}
        animate={{
          opacity: 1,
          transitionDuration: "1s",
          // scale: 1
        }}
      >
        <a
          href="https://abastible.socialweb.cl/2020/pdf/Cap_00_Carta_del_Presidente.pdf"
          target="_blank"
          class="descargar-capitulo"
          rel="noreferrer"
        />
        <section>
          <div className="first president ">
            <div className="president-name">
              Eduardo Navarro Beltrán <br />
              Presidente
            </div>
          </div>
        </section>
      </motion.div>
      <section className="pb-5 bg-light-gray">
        <motion.div
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1, transitionDuration: "0.5s" }}
        >
          <div className="container bg-light-gray">
            <Space h="100px" />
            <div className="row justify-content-around">
              <div id="carta" className="col-6 fw-bold">
                <div id="carta-titulo" style={{ color: "#53575A" }}>
                  {TEXT.left.h}
                </div>
                <p id="carta-bajada" className="text-orange text-justify">
                  {TEXT.left.p}
                </p>
                <img
                  src="images/carta/pre1.jpg"
                  alt=""
                  width="100%"
                  class="sticky sticky-first"
                />
                <img
                  src="images/carta/pre2.jpg"
                  alt=""
                  width="100%"
                  class="sticky sticky-second"
                />
              </div>
              <div className="col-5 text-dark-gray fs-6 ms-4">
                {TEXT.right.map((item, index) => (
                  <p className={index !== 9 ? "" : "scroll-marker"}>{item}</p>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
        <Space h="100px" />
      </section>
      <Final
        className="btn-proximo-capitulo"
        changeChap={changeChap}
        to="/secciones#cap01"
      />
    </>
  );
};

export default Carta;
