import { Reveal, Tween } from 'react-gsap';

const FadeInLeft = ({ children }) => (
  <Tween
    from={{ opacity: 0, transform: 'translateY(50px)' }}
    to={{ opacity: 1, transform: 'translateY(0px)' }}
    ease='back.out(1.4)'
  >
    {children}
  </Tween>
);

const TextInDown = ({ children, className }) => (
  // <div className={className}>
  <Reveal repeat rootMargin='50px'>
    <FadeInLeft>{children}</FadeInLeft>
  </Reveal>
  // </div>
);

export default TextInDown;
