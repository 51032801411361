const DIRECTORIO = [
  {
    name: "Eduardo Narraro Beltrán",
    url: "images/gobierno/NAVARRO.jpg",
    order: 0,
    position: "Presidente",
  },
  {
    name: "José Odone Odone",
    url: "images/gobierno/ODONE.jpg",
    order: 0,
    position: "Vicepresidente",
  },
  {
    name: "Ximena Alzérreca Luna",
    url: "images/gobierno/ALZERRECA.jpg",
    order: 0,
    position: "Directora  ",
  },
  {
    name: "Franco Bozzalla Trabucco",
    url: "images/gobierno/BOZALLA.jpg",
    order: 0,
    position: "Director",
  },
  {
    name: "Sergio del Campo Fayet",
    url: "images/gobierno/DEL CAMPO.jpg",
    order: 0,
    position: "Director",
  },
  {
    name: "Bernardita Figueroa Calmels",
    url: "images/gobierno/FIGUEROA.jpg",
    order: 0,
    position: "Directora",
  },
  {
    name: "Máximo Israel López",
    url: "images/gobierno/LOPEZ.jpg",
    order: 0,
    position: "Director",
  },
];

const TEXTOS = {
  1: {
    h: "Potenciando la integridad en los negocios",
    p: (
      <span>
        Los principios de transparencia y buen gobierno corporativo empujan
        constantemente a Abastible a ir más allá en el resguardo de la ética y
        la integridad.{" "}
        <span className="underline-orange">
          Se produce el traspaso del rol de encargado de Prevención de Delitos y
          Fraude hacia la Gerencia Legal y Asuntos Corporativos y se crea el
          Área de Compliance
        </span>
        , cuyo objetivo es gestionar el cumplimiento de la normativa aplicable a
        la compañía.
      </span>
    ),
  },
};

export { DIRECTORIO, TEXTOS };
